import csCZ from './cs-CZ.json';
import de from './de.json';
import elGR from './el-GR.json';
import enGB from './en-GB.json';
import enUS from './en-US.json';
import es from './es.json';
import frCA from './fr-CA.json';
import fr from './fr.json';
import it from './it.json';
import jaJP from './ja-JP.json';
import koKO from './ko-KO.json';
import nl from './nl.json';
import noNO from './no-NO.json';
import plPL from './pl-PL.json';
import pt from './pt.json';
import slSI from './sl-SI.json';
import svSE from './sv-SE.json';
import zhCN from './zh-CN.json';
export default {
  'cs-CZ': {
    'lib-app-ui': csCZ
  },
  de: {
    'lib-app-ui': de
  },
  'el-GR': {
    'lib-app-ui': elGR
  },
  'en-GB': {
    'lib-app-ui': enGB
  },
  'en-US': {
    'lib-app-ui': enUS
  },
  es: {
    'lib-app-ui': es
  },
  'fr-CA': {
    'lib-app-ui': frCA
  },
  fr: {
    'lib-app-ui': fr
  },
  it: {
    'lib-app-ui': it
  },
  'ja-JP': {
    'lib-app-ui': jaJP
  },
  'ko-KO': {
    'lib-app-ui': koKO
  },
  nl: {
    'lib-app-ui': nl
  },
  'no-NO': {
    'lib-app-ui': noNO
  },
  'pl-PL': {
    'lib-app-ui': plPL
  },
  pt: {
    'lib-app-ui': pt
  },
  'sl-SI': {
    'lib-app-ui': slSI
  },
  'sv-SE': {
    'lib-app-ui': svSE
  },
  'zh-CN': {
    'lib-app-ui': zhCN
  }
};