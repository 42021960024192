var activityErrorHandler = function activityErrorHandler(activityError) {
  var _activityError$respon, _activityError$respon2, _activityError$respon3;

  if (activityError !== null && activityError !== void 0 && (_activityError$respon = activityError.response) !== null && _activityError$respon !== void 0 && _activityError$respon.statusCode) {
    switch (activityError.response.statusCode) {
      case 401:
        if ((_activityError$respon2 = activityError.response) !== null && _activityError$respon2 !== void 0 && (_activityError$respon3 = _activityError$respon2.body) !== null && _activityError$respon3 !== void 0 && _activityError$respon3.location) {
          window.location.href = activityError.response.body.location;
        } else {
          window.location.reload();
        }

        break;

      case 403:
        window.location.reload();
        break;

      default:
    }
  }
};

export default activityErrorHandler;