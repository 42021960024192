import React from 'react';
import { createOptions, defaultOptionMatcher } from '../utils/EnumFilterUtils';
import TypeaheadFilter from './TypeaheadFilter';
import { GeneralFilterProps } from '../../types/EnhancedSearch';

export default function EnumFilter(props: GeneralFilterProps) {
  /* Filters the options based on provided values or optionMatcher */
  const filterOptions = ({ values, search }) => {
    const options = createOptions(props.field.enumOptions);
    const optionMatcher = props.field.optionMatcher || defaultOptionMatcher;

    if (values) {
      return options.filter(option => values.includes(option.value));
    }

    return options.filter(option =>
      optionMatcher(option, search, defaultOptionMatcher)
    );
  };

  /* Cuts the current options of there are too many */
  const optionsGetter = ({ maxOptions, ...args }: any) => {
    const options = filterOptions(args);

    if (maxOptions && options.length > maxOptions) {
      options.splice(maxOptions);
    }

    return Promise.resolve(options);
  };

  return <TypeaheadFilter optionsGetter={optionsGetter} {...props} />;
}
