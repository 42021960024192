function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { HTTP_METHODS, useFetch } from './useFetchV2';
export var useFeatureFetchConfig = {
  featureUrl: '/api/settings/enabled-feature'
};

var useFeatureFetch = function useFeatureFetch(featureName) {
  var fetchOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var options = _objectSpread(_objectSpread({}, fetchOptions), {}, {
    defaultMethod: HTTP_METHODS.GET,
    lazy: true
  });

  var _useFetch = useFetch("".concat(useFeatureFetchConfig.featureUrl, "?featureName=").concat(featureName), options),
      data = _useFetch.data,
      loading = _useFetch.loading,
      error = _useFetch.error,
      doFetch = _useFetch.doFetch;

  var _useState = useState(undefined),
      _useState2 = _slicedToArray(_useState, 2),
      isEnabled = _useState2[0],
      setIsEnabled = _useState2[1];

  useEffect(function () {
    if (featureName) {
      doFetch();
    } else {
      setIsEnabled(true); // return true if no feature given
    }
  }, [featureName, doFetch]);
  useEffect(function () {
    if (data) {
      setIsEnabled(data === null || data === void 0 ? void 0 : data.isEnabled);
    }
  }, [data]);
  return useMemo(function () {
    return {
      loading: loading,
      isEnabled: isEnabled,
      error: error
    };
  }, [loading, isEnabled, error]);
};

useFeatureFetch.propTypes = {
  featureName: PropTypes.string.isRequired
};
export default useFeatureFetch;