import { CUSTOM_OPERATORS } from '../constants';

/* Checks and includes the provided default operators */
export const findDefaultStringFilterOperator = field => {
  const operators = field.supportedOperators;

  /* Use these operators as the default, in this order of precedence. */
  const defaultOperators = [
    'CONTAINS',
    'STARTS_WITH',
    'EQUAL',
    'FULL_TEXT_SEARCH',
  ];

  return defaultOperators.find(it => operators.includes(it));
};

/* Checks if COMMA_SEPARATER_LIST is applied */
export const applyOperator = filter => {
  if (filter.operator === 'EQUAL' && filter.values?.length > 1) {
    return CUSTOM_OPERATORS.COMMA_SEPARATED_LIST;
  }

  return filter.operator;
};

export const applyValue = filter => {
  if (filter.operator === 'EQUAL' && filter.values?.length > 1) {
    return filter.values.join(', ');
  }

  return filter.values?.[0] || '';
}
