import { getI18n, useTranslation, withTranslation } from 'react-i18next';
var LIB_APP_UI_NS = 'lib-app-ui';

var useLibNSTranslation = function useLibNSTranslation() {
  return useTranslation(LIB_APP_UI_NS);
};

var withLibNSTranslation = function withLibNSTranslation() {
  return withTranslation(LIB_APP_UI_NS);
};
/* Applies the lib-app-ui namespace and returns the translation */

/* This is intended for util functions (instead of custom hooks) */


var libNSTranslate = function libNSTranslate(key, options) {
  return getI18n().t("".concat(LIB_APP_UI_NS, ":").concat(key), options);
};

export { LIB_APP_UI_NS, libNSTranslate, useLibNSTranslation, withLibNSTranslation };