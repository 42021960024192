const compareOptions = (a, b) => {
  /* If there is an `order` specified for the options, use it. */
  if (a.order || b.order) {
    return (a.order || 0) - (b.order || 0);
  }

  /* Otherwise, just sort by the label. */
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

/*-----> Export Container <----- */
const TypeaheadFilterUtils = {
  compareOptions,
};

export default TypeaheadFilterUtils;
