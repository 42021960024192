import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Alert, UserSettingsContext } from 'taulia-ui';
import { Trans, useTranslation } from 'react-i18next';

function LoginPageError({ email, errorCode }) {
  const { t } = useTranslation();
  const { activationLinkUrl } = useContext(UserSettingsContext);
  const supportLink = (
    <a
      href="http://support.taulia.com"
      target="_blank"
      rel="noopener noreferrer"
    />
  );
  const recoveryLink = (
    <a href="/reset-password" target="_blank" rel="noopener noreferrer" />
  );
  const activateLink = (
    <a href={activationLinkUrl} target="_blank" rel="noopener noreferrer" />
  );

  const errorMap = {
    INVALID_CREDENTIALS: {
      translation: 'loginPage.error.invalidCredentials.first',
      components: [recoveryLink, <br />, <strong />],
      values: {
        recoveryLink: t('loginPage.error.invalidCredentials.recoveryLink'),
      },
    },
    ACCOUNT_DEACTIVATED: {
      translation: 'loginPage.error.accountDisabled.message',
    },
    ACCOUNT_NOT_ACCESSIBLE: {
      translation: 'loginPage.error.accountNotAccessible.message',
      components: [recoveryLink, supportLink],
      values: {
        recoveryLink: t('loginPage.error.accountNotAccessible.recoveryLink'),
      },
    },
    USER_INVITED_NOT_ENROLLED: {
      translation: 'loginPage.error.userInvitedNotEnrolled.message',
      components: [activateLink],
      values: {
        activateLink: t('loginPage.error.userInvitedNotEnrolled.activateLink'),
      },
    },
    SO_USER_NOT_INVITED: {
      translation: 'loginPage.error.ssoUserNotInvited',
    },
    SSO_DEFAULT_ERROR: {
      translation: 'loginPage.error.ssoDefault',
    },
    LOGIN_NOT_ALLOWED: {
      translation: 'loginPage.error.loginNotAllowed',
      components: [<strong />],
      values: { email: email.value },
    },
    TWO_FA_INCORRECT_TOKEN: {
      translation: 'authentication.smsSetup.verificationError',
    },
    MAX_SESSIONS_REACHED: {
      translation: 'loginPage.error.maxSessionsReached',
    },
    UNKNOWN_ERROR: {
      translation: 'loginPage.error.unknownError',
      components: [supportLink],
    },
    LOGIN_NOT_ALLOWED_SSO_USER: {
      translation: 'loginPage.error.ssoEmailLoginAttempt',
    },
  };
  const error = errorMap[errorCode] || errorMap.UNKNOWN_ERROR;
  const { translation, components, values } = error;
  return (
    <Alert id="errorMessage" theme="danger" className="error">
      <Trans i18nKey={translation} components={components} values={values} />
    </Alert>
  );
}

LoginPageError.propTypes = {
  email: PropTypes.shape(),
  errorCode: PropTypes.string,
};

LoginPageError.defaultProps = {
  email: { error: null, value: '' },
  errorCode: '',
};

export default LoginPageError;
