import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Branding, Button, Link, Radio } from 'taulia-ui';
import { Trans, useTranslation } from 'react-i18next';
import { AuthTypeContext } from '../../contexts/authTypeProvider';
import { useQuery } from '../../hooks';
import GLOBALS from '../../globals';

function ChooseMethod() {
  const { authType, setAuthType } = useContext(AuthTypeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  const selectedStyles = {
    borderColor: Branding.fetchColor('primary').hex(),
  };

  const showChangeWarning = query.get('edit');
  const isMandatory = query.get('mandatory');

  return (
    <div className="choose-two-factor-auth">
      <h2 className="auth-header">{t('authentication.header')}</h2>
      <h4>{t('authentication.chooseMethod.header')}</h4>
      {showChangeWarning && (
        <Alert theme="warn">
          {t('authentication.chooseMethod.changeAlert')}
        </Alert>
      )}
      {isMandatory && (
        <Alert theme="warn">
          <Trans
            i18nKey="authentication.chooseMethod.mandatoryText"
            components={[
              <Link
                href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </Alert>
      )}
      <p>{t('authentication.chooseMethod.infoText')}</p>
      <p>{t('authentication.chooseMethod.infoTextSecond')}</p>
      <section className="auth-card-container">
        <article
          style={
            authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.APP ? selectedStyles : {}
          }
          className="auth-card"
        >
          <Radio
            aria-label="APP"
            value={GLOBALS.TWO_FACTOR_AUTH_TYPE.APP}
            checked={authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.APP}
            onChange={event => setAuthType(event.target.value)}
          >
            <p className="title">
              {t('authentication.chooseMethod.appMethodTitle')}
            </p>
            <p>
              <Trans
                i18nKey="authentication.chooseMethod.appMethodDesc"
                components={[
                  <Link
                    href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
              />
            </p>
          </Radio>
        </article>
        <article
          style={
            authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS ? selectedStyles : {}
          }
          className="auth-card"
        >
          <Radio
            aria-label="SMS"
            value={GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS}
            checked={authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS}
            onChange={event => setAuthType(event.target.value)}
          >
            <p className="title">
              {t('authentication.chooseMethod.smsMethodTitle')}
            </p>
            <p>{t('authentication.chooseMethod.smsMethodDesc')}</p>
            <p>
              <Trans
                i18nKey="authentication.chooseMethod.smsMethodDescSecond"
                components={[
                  <Link
                    href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
              />
            </p>
          </Radio>
        </article>
      </section>
      <div className="button-navigation-container">
        {!isMandatory && (
          <Button
            theme="text"
            onClick={() => navigate(-1)}
            id="cancel-twofa-setup"
          >
            {t('authentication.cancel')}
          </Button>
        )}
        <Button
          disabled={!authType}
          onClick={() => navigate('/authentication/recovery-codes')}
          id="continue-twofa-setup"
          theme="primary"
        >
          {t('standard.continue')}
        </Button>
      </div>
    </div>
  );
}

export default ChooseMethod;
