export var colors = {
  /* Variables */
  text: '#32363a',
  textL: '#585b5e',
  textXL: '#8f9294',
  textD: '#32363a',
  background: '#edeff0',
  backgroundL: '#edeff0',
  backgroundXL: '#fafafa',
  backgroundD: '#8f9294',
  backgroundXD: '#8f9294',
  'light-grey': '#fafafa',
  'medium-grey': '#8f9294',
  white: '#ffffff',
  'grey-border': '#d9d9d9',

  /* Warn (yellow) */
  'warn-background': '#ffffec',
  'warn-border': '#f0edda',
  'warn-text': '#7e6745',

  /* Danger (red) */
  'danger-background': '#fff7f8',
  'danger-border': '#ebccd1',
  'danger-text': '#dd484a',

  /* Success (green) */
  'success-background': '#f1ffea',
  'success-border': '#d7e8c2',
  'success-text': '#3e7540',

  /* Info (blue) */
  'info-background': '#ecf8ff',
  'info-border': '#bee8f0',
  'info-text': '#205d7a',

  /* Style guide colors */
  shellbar: '#354a5f',
  'taulia-aqua': '#6bd8c0',
  'taulia-blue': '#0a6ed1',
  'taulia-blue-hover': '#125eac',
  'taulia-dark-grey': '#58595b',
  'taulia-grey': '#d9d9d9',
  'taulia-green': '#35aa4d',
  'taulia-green-hover': '#107e3e',
  'taulia-orange': '#e67443',
  'taulia-red': '#dd484a',
  'taulia-yellow': '#f2c156'
};