export const exportToCSV = (csvData: any, fileName: string) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

  /* This supports IE ... */
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    //  Add to the page, click it, and remove from the dom
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};
