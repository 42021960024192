import { JSONStringify } from '../../utils/general';

export const compareWithInitialData = (initialData, submitData) => {
  const initialStringifiedData = JSONStringify(initialData);
  const submitStringifiedData = JSONStringify(submitData);

  /**
   * Check if JSON.stringify passed successfully.
   * If it failed, in most cases its due to an 'unexpected' character.
   */
  if (initialStringifiedData && submitStringifiedData) {
    return initialStringifiedData === submitStringifiedData;
  }

  return null;
};
