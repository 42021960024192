import React, { useEffect, useState } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import SelectInput from './SelectInput';
import { Button } from '../../Button';
import { Text } from '../../Text';
import { Checkbox } from '../../Checkbox';
import utils from '../utils/TypeaheadFilterUtils';
import { TypeaheadFilterState } from '../../types/EnhancedSearch';

export default function TypeaheadFilter({
  field,
  filter,
  maxOptions = 100,
  onFilterChange,
  optionsGetter,
  tooltipText,
}) {
  const { t } = useLibNSTranslation();

  const [state, setState] = useState<TypeaheadFilterState>({
    search: '',
    selectedOptions: [],
    options: [],
    hasMore: false,
  });

  /* -----> Utils <----- */
  const loadSearchOptions = (search, selectedOptions) => {
    const selectedValues = selectedOptions?.map(it => it.value);

    /* Load options that have the given search text. */
    optionsGetter({ search, maxOptions }).then(searchOptions => {
      /* Determine if there are potentially more options than we could show. */
      const hasMore = searchOptions?.length >= maxOptions;

      /* Remove the options that are already selected (those will go at the top). */
      const filteredSearchOptions =
        searchOptions?.filter(
          option => !selectedValues.includes(option.value)
        ) || [];

      /* Include both the selected options and the search options. */
      const options = [...selectedOptions, ...filteredSearchOptions];

      setState(prevState => ({ ...prevState, options, hasMore }));
    });
  };

  const loadInitialOptions = () => {
    const selectedValues = filter.values || [];

    /* Load options that have the given values (if there are any). */
    const loader = selectedValues.length
      ? optionsGetter({ values: selectedValues })
      : Promise.resolve([]);

    loader.then(selectedOptions => {
      /* Now that we have the selected options, load the search options. */
      setState(prevState => ({ ...prevState, selectedOptions }));
      loadSearchOptions(state.search, selectedOptions);
    });
  };

  const clearOptions = () => {
    onFilterChange({
      ...filter,
      operator: 'EQUAL',
      values: [],
    });
  };

  /* -----> Handlers <----- */
  const onCheckboxChange = event => {
    const { value, checked } = event.target;
    const { selectedOptions, options } = state;

    let selectedOptionsCopy = [...selectedOptions];

    if (checked) {
      selectedOptionsCopy.push(options.find(option => option.value === value)!);
    } else {
      selectedOptionsCopy = selectedOptionsCopy.filter(
        option => option.value !== value
      );
    }

    selectedOptionsCopy?.sort(utils.compareOptions);

    setState(prevState => ({
      ...prevState,
      selectedOptions: selectedOptionsCopy,
    }));

    const selectedValues = selectedOptionsCopy.map(it => it.value);

    onFilterChange({
      ...filter,
      operator: 'EQUAL',
      values: selectedValues,
    });
  };

  const onTextSearchChange = event => {
    const search = event.target.value;

    /* The search text changed -> load the new options. */
    setState(prevState => ({ ...prevState, search }));
    loadSearchOptions(search, state.selectedOptions);
  };

  const onClose = () => {
    setState(prevState => ({ ...prevState, search: '' }));
  };

  const onShow = () => {
    loadSearchOptions(state.search, state.selectedOptions);
  };

  /* -----> Effects <----- */
  useEffect(() => {
    loadInitialOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.values]);

  return (
    <div className="filterSelect">
      <SelectInput
        className="typeaheadFilter"
        fieldName={field.name}
        label={field.label}
        onClose={onClose}
        onShow={onShow}
        selectedContent={state.selectedOptions.map(option => option.label)}
        tooltipText={tooltipText}
        width={350}
        content={
          <div className="filter-content">
            <div className="search">
              <Text
                onChange={onTextSearchChange}
                placeholder={t('enhancedSearch.filters.search')}
                type="text"
                value={state.search}
              />
            </div>
            <Button
              onClick={clearOptions}
              style={{ margin: '10px 0 0' }}
              theme="text"
            >
              {t('enhancedSearch.filters.clear')}
            </Button>
            <div className="typeaheadOptions">
              {state.options.map(option => (
                <div key={option.value}>
                  <Checkbox
                    checked={state.selectedOptions.some(
                      so => so.value === option.value
                    )}
                    className="option"
                    data-testid={option.value}
                    onChange={onCheckboxChange}
                    value={option.value}
                  >
                    {option.label}
                  </Checkbox>
                </div>
              ))}
              {state.hasMore && (
                <div className="more">
                  &#40;
                  {t('enhancedSearch.filters.searchForMore')}
                  &#41;
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
