import React from 'react';
import { Link } from 'taulia-ui';

const linkMap = {
  authy: {
    label: 'Authy',
    url: 'https://authy.com/',
  },
  keeper: {
    label: 'Keeper',
    url: 'https://keepersecurity.com/',
  },
  lastPass: {
    label: 'LastPass',
    url: 'https://lastpass.com/',
  },
  lastPassAuth: {
    label: 'LastPass Authenticator',
    url: 'https://lastpass.com/auth/',
  },
  onePass: {
    label: '1Password',
    url: 'https://1password.com/',
  },
};

// Returns the necessary props to pass to the i18n Trans component
export default type => {
  let links = [];
  if (type === 'authApps') {
    links = ['lastPassAuth', 'onePass', 'authy'];
  } else if (type === 'passwordManagers') {
    links = ['lastPass', 'onePass', 'keeper'];
  }
  const props = { values: {}, components: [] };
  links.forEach((link, idx) => {
    const { label, url } = linkMap[link];
    props.values[`link${idx}`] = label;
    props.components.push(
      <Link href={url} target="_blank" rel="noopener noreferrer" />
    );
  });
  return props;
};
