function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { isPlainObject } from 'lodash';
import { cx } from '../utils';
import BooleanCell from './CellTypes/BooleanCell';
import DateCell from './CellTypes/DateCell';
import EnumCell from './CellTypes/EnumCell';
import MoneyCell from './CellTypes/MoneyCell';
import NumberCell from './CellTypes/NumberCell';
import ObjectCell from './CellTypes/ObjectCell';
import TextCell from './CellTypes/TextCell';
import ExpandColumn from './components/ExpandColumn';
import SelectColumn from './components/SelectColumn';
export function createDefaultCellForType(type) {
  switch (type) {
    case 'TEXT':
      return TextCell;

    /* NOTE: Returning DateCell for the 'DATE_TIME' type is intentional
    due to the interests of backend teams to store the date and time
    in our databases, but in our UI we intend to display just the date.
    */

    case 'DATE_TIME':
      return DateCell;

    case 'LOCAL_DATE':
      return DateCell;

    case 'BOOLEAN':
      return BooleanCell;

    case 'ENUM':
      return EnumCell;

    case 'MONEY':
      return MoneyCell;

    case 'NUMBER':
      return NumberCell;

    case 'REFERENCE':
    case 'COLLECTION':
      return ObjectCell;

    default:
      return TextCell;
  }
}
export var isEmpty = function isEmpty(obj) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};
export var setDimensions = function setDimensions(setState) {
  // This function is not actually used, but removing it causes bugs
  var widthOffset = window.innerWidth > 1350 ? 260 : 70;
  var width = window.innerWidth - widthOffset;
  setState(function (prevState) {
    return _objectSpread(_objectSpread({}, prevState), {}, {
      width: width
    });
  });
};
export var sortChange = function sortChange(column, props) {
  var sorts = props.sorts,
      onSortChange = props.onSortChange; // Find the existing sort, if there is one, and toggle it.

  var sort = sorts.find(function (s) {
    return s.field === column.key;
  });
  var sortType = sort && sort.type === 'ASC' ? 'DESC' : 'ASC';
  onSortChange([{
    field: column.key,
    type: sortType
  }]);
};
export var isColumnVisible = function isColumnVisible(column) {
  return typeof column.visible === 'undefined' ? true : column.visible;
};
export var calculateWidthOfColumns = function calculateWidthOfColumns(columns) {
  var width = 0;
  columns.forEach(function (column) {
    width += column.width;
  });
  return width;
};
export var hasContent = function hasContent(value) {
  if (Array.isArray(value) || typeof value === 'string' || isPlainObject(value)) {
    return !isEmpty(value);
  }

  return value !== null;
};
/* ---> Column Adjustment <--- */

export var normalizeColumn = function normalizeColumn(column, lvProps, state) {
  /* eslint-disable no-param-reassign */
  var defaultColumnWidth = lvProps.defaultColumnWidth;
  var resizeColumn = state.resizeColumn,
      resizeColumnInitialWidth = state.resizeColumnInitialWidth,
      resizeChange = state.resizeChange;

  var style = _objectSpread({}, column.style || {});

  if (!column.width || typeof column.width !== 'number') {
    column.width = defaultColumnWidth;
  }

  if (resizeColumn && resizeColumn.key === column.key) {
    var resizeValue = resizeColumnInitialWidth + resizeChange;
    column.width = resizeValue > 50 ? resizeValue : 50;
  }

  style = {
    width: "".concat(column.width, "px")
  };

  if (column.align) {
    style.textAlign = column.align;
  }

  column.resizable = column.fixedOrder === undefined;
  return _objectSpread(_objectSpread({}, column), {}, {
    style: style
  });
};
export var normalizeColumns = function normalizeColumns(lvProps, state, handlers) {
  var propsColumns = lvProps.columns,
      data = lvProps.data,
      expandAttribute = lvProps.expandAttribute,
      expandingEnabled = lvProps.expandingEnabled,
      selectionEnabled = lvProps.selectionEnabled,
      saveColumnWidths = lvProps.saveColumnWidths;
  var stateColumns = state.columns,
      selection = state.selection,
      expanded = state.expanded; // Use the columns passed from props on initial render, but the updated ones from state on later renders.
  // This ensures that extra columns can be toggled via the column config

  var columns = stateColumns || propsColumns;
  var visibleColumns = columns.filter(function (column) {
    return isColumnVisible(column);
  });
  var normalized = visibleColumns.map(function (column) {
    var widthKey = handlers.widthKey;

    if (saveColumnWidths && !column.width) {
      var _JSON$parse;

      var savedColumn = (_JSON$parse = JSON.parse(localStorage.getItem(widthKey))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.find(function (it) {
        return it.key === column.key;
      });

      if (savedColumn) {
        column.width = savedColumn.width;
      }
    }

    return normalizeColumn(column, lvProps, state);
  });
  var isExpandableData = data.filter(function (item) {
    return item[expandAttribute] && item[expandAttribute].length > 0;
  }).length > 0;

  if (expandingEnabled && isExpandableData) {
    normalized.unshift(ExpandColumn({
      handlers: handlers,
      lvProps: lvProps,
      expanded: expanded
    }));
  }

  if (selectionEnabled) {
    normalized.unshift(SelectColumn({
      handlers: handlers,
      lvProps: lvProps,
      selection: selection
    }));
  }

  normalized.sort(function (a, b) {
    if (typeof a.fixedOrder !== 'number' && typeof b.fixedOrder === 'number') return 1;
    if (typeof a.fixedOrder === 'number' && typeof b.fixedOrder === 'number') return a.fixedOrder - b.fixedOrder;
    if (typeof a.fixedOrder === 'number' && typeof b.fixedOrder !== 'number') return -1;
    return 0;
  });
  var xPos = 0;
  normalized.forEach(function (it, idx, arr) {
    if (it.fixedOrder !== undefined) {
      it.style = _objectSpread(_objectSpread({}, it.style), {}, {
        left: xPos
      });
      it.className = cx(it.className, 'fixed');

      if (idx + 1 < arr.length && arr[idx + 1].fixedOrder === undefined) {
        it.className = cx(it.className, 'fixed-last');
      }

      xPos += it.width;
    }
  });
  var actionsColumnIdx = normalized.findIndex(function (item) {
    return item.key === 'actions';
  });

  if (actionsColumnIdx !== -1) {
    var actionCol = normalized[actionsColumnIdx];
    actionCol.style = _objectSpread({
      right: 0,
      width: 35
    }, actionCol.style);
    actionCol.className = cx(actionCol.className, 'fixed');
  }

  return normalized;
};
export var applyStoredColumnWidths = function applyStoredColumnWidths(columns, storageKey) {
  var storedWidths = JSON.parse(localStorage.getItem(storageKey));

  if (storedWidths) {
    columns === null || columns === void 0 ? void 0 : columns.forEach(function (clmn) {
      var stored = storedWidths.find(function (it) {
        return it.key === clmn.key;
      });

      if (stored) {
        clmn.width = stored.width;
      }
    });
  }
};