function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useLibNSTranslation } from '../../utils/i18nUtils';
import { currencyIsoData, oldCurrencyIsoData } from '../../utils';
/**
 * Currency string would return targeted data, while null will return a full list of all currencies.
 *
 * @typedef {string|null} Currency
 */

/**
 * Currency object
 *
 * @typedef {object} CurrencyObject
 * @property {string} currency - Currency abbreviation
 * @property {string} name - Translated full name of the currency
 * @property {number} number - Currency ISO number
 * @property {number} minorUnits - Currency minor units. Represents the number of smallest digits of any currency.
 * @property {string} value - Optional property that can take the value of any of the previous properties.
 */

/**
 * A single currency object or a full list of all currency objects.
 *
 * @typedef {CurrencyObject|CurrencyObject[]} CurrencyResult
 */

/**
 * Returns detailed information about a specific currency. Full name of the currency is also translated.
 *
 * @param {Currency} currency Currency string would return targeted data, while null will return a full list of all currencies.
 * @param {boolean} includeOldCurrencies Include currencies that are outdated, but could still be used in the system.
 * @param {Object} options Include the properties the return object should contain
 * @param {boolean} [options.currency]
 * @param {boolean} [options.minorUnits]
 * @param {boolean} [options.name]
 * @param {boolean} [options.number]
 * @param {string|null} [options.value]
 * @returns {CurrencyResult} A single currency object or a full list of all currency objects.
 */

function useTranslatedCurrency(currency) {
  var includeOldCurrencies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    currency: true,
    minorUnits: false,
    name: true,
    number: false,
    value: null
  };

  var _useLibNSTranslation = useLibNSTranslation(),
      t = _useLibNSTranslation.t;

  var currencyList = includeOldCurrencies ? _objectSpread(_objectSpread({}, currencyIsoData), oldCurrencyIsoData) : currencyIsoData;
  /* To minimize the amount of data returned, show only the applied options */

  function applyOptions(selectedCurrency) {
    var result = {};
    /* Applying enabled fields */

    Object.keys(options).filter(function (key) {
      return options[key] && key !== 'value' && key !== 'name';
    }).forEach(function (option) {
      result[option] = selectedCurrency[option];
    });
    /* The value can be matched to any field (useful for Select types) */

    if (options.value) {
      result.value = selectedCurrency[options.value];
    }
    /* Translated currency name */


    if (options.name) {
      result.name = t("currencyNames.".concat(selectedCurrency.currency));
    }

    return result;
  }
  /* If specific currency is provided return only the matched currency data */


  if (currency) {
    var selected = currencyList[currency];
    var translatedCurrency = applyOptions(selected);
    return translatedCurrency;
  }
  /* If no currency is provided, return a list with all currencies */


  var currencyArray = Object.values(currencyList).map(function (curr) {
    return applyOptions(curr);
  }).sort(function (a, b) {
    return a.currency.localeCompare(b.currency);
  });
  return currencyArray;
}

export default useTranslatedCurrency;