import React, { useState } from 'react';
import { useESContext } from '../hooks';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import {
  createEmptyFilter,
  getTooltipText,
  sortSearchFields,
} from '../utils/FilterContainerUtils';
import StringFilter from '../FilterTypes/StringFilter';
import BooleanFilter from '../FilterTypes/BooleanFilter';
import EnumFilter from '../FilterTypes/EnumFilter';
import NumberFilter from '../FilterTypes/NumberFilter';
import ObjectFilter from '../FilterTypes/ObjectFilter';
import { DateRangeFilter } from '..';
import { Button } from '../../Button';
import { Text } from '../../Text';
import { Checkbox } from '../../Checkbox';
import SelectInput from '../FilterTypes/SelectInput';
import {
  FilterContainer,
  FilterContainerProps,
  MappedConfigurationField,
} from '../../types/EnhancedSearch';

export default function FilterContainerV2({
  esaUri,
  excludedFields,
  onQueryChange,
  tooltips,
}: FilterContainerProps) {
  const { t } = useLibNSTranslation();
  const context = useESContext();
  const { query, configuration, setQuery } = context;

  /* -----> State & Constants <----- */
  const fields = configuration?.searchableFields || [];
  const filters = query.filters
    ? query.filters.filter(
        (filter: { field: string }) => !excludedFields.includes(filter.field)
      )
    : [];

  const [state, setState] = useState<FilterContainer>({
    search: '',
    searchFields: sortSearchFields(fields, filters),
  });

  /* -----> Handlers <----- */
  /* A filter has changed -> reset the pagination and call the prop handler */
  const onFiltersChange = filters => {
    const pagination = { ...query.pagination, first: 0 };
    setQuery({ ...query, pagination, filters });
    onQueryChange({ ...query, pagination, filters });
  };

  /* Triggered when a filter is applied/removed */
  const onSelectedFieldsChange = event => {
    const fieldName = event.target.value;
    const newFilters = event.target.checked
      ? filters.concat(createEmptyFilter(fieldName))
      : filters.filter(filter => filter.field !== fieldName);

    onFiltersChange(newFilters);
  };

  /* Triggered when a filter has been used */
  const onFilterChange = changedFilter => {
    const newFilters = filters.map(filter =>
      filter.field === changedFilter.field ? changedFilter : filter
    );

    onFiltersChange(newFilters);
  };

  /* Triggered when typing in the search field for Manage Filters */
  const onSearchChange = event => {
    setState({ search: event.target.value, searchFields: state.searchFields });
  };

  /* -----> View <----- */
  /* Maps applied Filters */
  const SelectedFilters = filters
    ? filters.map(filter => {
        const field: MappedConfigurationField | { [key: string]: any } =
          fields.find(it => it.name === filter.field) || {};
        const tooltipText = getTooltipText(tooltips, field?.name);

        const props = {
          esaUri,
          field,
          filter,
          key: field?.name,
          onFilterChange,
          tooltipText,
        };

        switch (field.type) {
          case 'TEXT':
            return <StringFilter {...props} />;
          case 'BOOLEAN':
            return <BooleanFilter {...props} />;
          case 'ENUM':
            return <EnumFilter {...props} />;
          case 'NUMBER':
          case 'MONEY':
            return <NumberFilter {...props} />;
          case 'REFERENCE':
          case 'COLLECTION':
            return <ObjectFilter {...props} />;
          case 'DATE_TIME':
            return <DateRangeFilter {...props} />;
          case 'LOCAL_DATE':
            return <DateRangeFilter {...props} localDate />;
          default:
            return field.name;
        }
      })
    : null;

  /* Manage Filters content (menu displayed when clicking ManageFiltersButton) */
  const ManageFilters = (
    <div className="tooltipSelectDropDown">
      <Text
        placeholder={t('enhancedSearch.filterContainer.searchForAField')}
        value={state.search}
        onChange={onSearchChange}
      />
      <div className="tooltipDropdownOptions">
        {state.searchFields
          .filter((field: MappedConfigurationField) =>
            field.label?.toLowerCase().includes(state.search.toLowerCase())
          ) // Filter by search string
          .map(field => (
            <Checkbox
              key={field.name}
              value={field.name}
              checked={
                filters
                  ? filters.some(filter => filter.field === field.name)
                  : false
              }
              onChange={onSelectedFieldsChange}
            >
              {field.label}
            </Checkbox>
          ))}
      </div>
    </div>
  );

  const ManageFiltersButton = (
    <Button style={{ float: 'right' }} theme="text">
      {t('enhancedSearch.filterContainer.manageSearchFields')}
    </Button>
  );

  return (
    <div className="filterContainer">
      <div className="manageSearchFields">
        <SelectInput
          arrowStyle="arrowRight"
          content={ManageFilters}
          contentClassName="content-manageSearchFields"
          onClose={() => {
            setState({
              search: '',
              searchFields: sortSearchFields(fields, filters),
            });
          }}
          target={ManageFiltersButton}
        />
      </div>
      <div className="selectedFilters">{SelectedFilters}</div>
    </div>
  );
}
