import { useCallback } from 'react';
import { getFromCache, setInCache } from '../utils/cacheUtils';

var useCachedRequest = function useCachedRequest(request, key, maxAge, options) {
  return useCallback(function (url) {
    var urlKey = "".concat(url, ":").concat(key);
    var cachedData = getFromCache(urlKey, options);

    if (cachedData) {
      return Promise.resolve({
        body: cachedData
      });
    }

    return new Promise(function (resolve, reject) {
      request('GET', url).then(function (res) {
        setInCache(urlKey, maxAge, res.body, options);
        resolve(res);
      }).catch(function (error) {
        reject(error);
      });
    });
  }, [key, maxAge, options, request]);
};

export default useCachedRequest;