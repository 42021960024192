/* -----> Constants <----- */
export var KEY_SEPARATORS = ['Enter', 'Space', 'Comma'];
/* -----> Utils <----- */

export function doRenderInput(currentPills, maxPills) {
  if (typeof maxPills === 'number') {
    return currentPills < maxPills;
  }

  return true;
}
export function replaceSeparator(text, separator) {
  if (separator === 'Space') {
    return text.replace(' ', '');
  }

  return text.replace(',', '');
}
export function applyInitialPillData(data) {
  if (Array.isArray(data)) return data;

  if (typeof data === 'string') {
    try {
      var parsedData = JSON.parse(data);
      if (Array.isArray(parsedData)) return parsedData;
      return [data];
    } catch (_unused) {
      return [data];
    }
  }

  return [];
}