import { useLibNSTranslation } from '../utils/i18nUtils';
import {
  compareWithInitialData,
  isEmail,
  isFutureDate,
  isInsideRange,
  isInteger,
  isMobile,
  isTextOnly,
  maxLength,
  maxLengthExc,
  minLength,
  minLengthExc,
} from '../Validation/constants';

/**
 * Main container of all predefined validations.
 * Returns an object, containing all types (string, number, array, etc.) of validations with their translations.
 */
const usePredefinedValidations = () => {
  const { t } = useLibNSTranslation();

  const stringValidations = {
    maxLength: {
      validator: (value: string, length: number) => maxLength(value, length),
      errorMessage: (_value: string, checkValue: number) =>
        t('input.maxLength', { checkValue }), // checkValue is maxLength
    },
    maxLengthExc: {
      validator: (value: string, length: number) => maxLengthExc(value, length),
      errorMessage: (_value: string, checkValue: number) =>
        t('input.maxLength', { checkValue }), // checkValue is maxLength
    },
    minLength: {
      validator: (value: string, length: number) => minLength(value, length),
      errorMessage: (_value: string, checkValue: number) =>
        t('input.minLength', { checkValue }), // checkValue is minLength
    },
    minLengthExc: {
      validator: (value: string, length: number) => minLengthExc(value, length),
      errorMessage: (_value: string, checkValue: number) =>
        t('input.minLength', { checkValue }), // checkValue is minLength
    },
    type: {
      validator: (value: string, inputType: string) => {
        switch (inputType) {
          case 'textOnly':
            return !isTextOnly(value);
          case 'integer':
            return !isInteger(value);
          case 'email':
            return !isEmail(value);
          case 'mobile':
            return !isMobile(value);
          default:
            return false;
        }
      },
      errorMessage: (_value: string, inputType: string) =>
        t(`input.${inputType}`),
    },
  };

  const multiDateValidations = {
    noFutureDates: {
      validator: (value: { startDate: Date; endDate: Date }[]) => {
        const hasFutureDate = value.some(
          it => isFutureDate(it.startDate) || isFutureDate(it.endDate)
        );

        return hasFutureDate;
      },
      errorMessage: () => t('multiDateRangePicker.validations.noFutureDates'),
    },
    betweenDates: {
      validator: (
        value: { startDate: Date; endDate: Date }[],
        validation: { start: Date; end: Date }
      ) => {
        const { start: vStart, end: vEnd } = validation;

        const allDatesAreInsideRange = value.every(({ startDate, endDate }) => {
          const startIsInsideRange = isInsideRange(startDate, vStart, vEnd);
          const endIsInsideRange = isInsideRange(endDate, vStart, vEnd);

          return startIsInsideRange && endIsInsideRange;
        });

        return !allDatesAreInsideRange;
      },
      errorMessage: (_value, validation: { start: Date; end: Date }) =>
        t('multiDateRangePicker.validations.betweenDates', {
          start: validation.start,
          end: validation.end,
        }),
    },
  };

  const formValidations = {
    submitSameData: {
      validator: ({ submitData, initialData }, isEnabled) =>
        !isEnabled && compareWithInitialData(initialData, submitData),
      errorMessage: () => t('form.validations.sameData'),
    },
  };

  return { stringValidations, formValidations, multiDateValidations };
};

export default usePredefinedValidations;
