import { ActionsProps } from '../../types/EnhancedSearch';
import { libNSTranslate as t } from '../../utils/i18nUtils';

/* Default Export to CSV functionality */
export const getDefaultExportToCSVAction = (
  action: (data: any) => void
): ActionsProps[] => {
  return [
    {
      label: t('enhancedSearch.actions.exportLabel') as string,
      onClick: action,
      allowEmptySelection: true,
      themeOverride: 'light',
    },
  ];
};
