import { useLibNSTranslation } from '../../utils/i18nUtils';
import { Primitive } from '../../types';

type SelectOption = {
  label: string;
  value: Primitive;
};

/**
 * Returns a translated Position Groups Object
 *
 * Object shape: `{ tPositionGroupLabel: { label: TLabel, value }[] }`
 */
export default function useTPositionGroups(): {
  [groupLabel: string]: SelectOption[];
} {
  const { t } = useLibNSTranslation();

  const key = `positionOptions`;
  const getLabel = (group: string) => `${key}.${group}.label`;

  return {
    [`${t(getLabel('accounting'))}`]: [
      { label: t(`${key}.accounting.accountant`), value: 'accountant' },
      {
        label: t(`${key}.accounting.accountingManager`),
        value: 'accountingManager',
      },
      {
        label: t(`${key}.accounting.accountingSpecialist`),
        value: 'accountingSpecialist',
      },
      {
        label: t(`${key}.accounting.assistantController`),
        value: 'assistantController',
      },
      { label: t(`${key}.accounting.bookkeeper`), value: 'bookkeeper' },
      { label: t(`${key}.accounting.controller`), value: 'controller' },
    ],
    [`${t(getLabel('accountsPayable'))}`]: [
      {
        label: t(`${key}.accountsPayable.accountsPayableManager`),
        value: 'accountsPayableManager',
      },
      {
        label: t(`${key}.accountsPayable.accountsPayableSpecialist`),
        value: 'accountsPayableSpecialist',
      },
    ],
    [`${t(getLabel('accountsReceivable'))}`]: [
      {
        label: t(`${key}.accountsReceivable.accountsReceivableManager`),
        value: 'accountsReceivableManager',
      },
      {
        label: t(`${key}.accountsReceivable.accountsReceivableSpecialist`),
        value: 'accountsReceivableSpecialist',
      },
      {
        label: t(`${key}.accountsReceivable.billingSpecialist`),
        value: 'billingSpecialist',
      },
      {
        label: t(`${key}.accountsReceivable.collectionsSpecialist`),
        value: 'collectionsSpecialist',
      },
      {
        label: t(`${key}.accountsReceivable.creditAnalyst`),
        value: 'creditAnalyst',
      },
      {
        label: t(`${key}.accountsReceivable.creditManager`),
        value: 'creditManager',
      },
    ],
    [`${t(getLabel('executive'))}`]: [
      {
        label: t(`${key}.executive.chiefExecutiveOfficer`),
        value: 'chiefExecutiveOfficer',
      },
      {
        label: t(`${key}.executive.chiefFinancialOfficer`),
        value: 'chiefFinancialOfficer',
      },
      {
        label: t(`${key}.executive.chiefTechnologyOfficer`),
        value: 'chiefTechnologyOfficer',
      },
      {
        label: t(`${key}.executive.chiefInformationOfficer`),
        value: 'chiefInformationOfficer',
      },
      {
        label: t(`${key}.executive.chiefOperatingOfficer`),
        value: 'chiefOperatingOfficer',
      },
      {
        label: t(`${key}.executive.chiefMarketingOfficer`),
        value: 'chiefMarketingOfficer',
      },
      {
        label: t(`${key}.executive.managingDirector`),
        value: 'managingDirector',
      },
      { label: t(`${key}.executive.owner`), value: 'owner' },
      { label: t(`${key}.executive.partner`), value: 'partner' },
      { label: t(`${key}.executive.president`), value: 'president' },
    ],
    [`${t(getLabel('finance'))}`]: [
      {
        label: t(`${key}.finance.assistantTreasurer`),
        value: 'assistantTreasurer',
      },
      {
        label: t(`${key}.finance.financeManager`),
        value: 'financeManager',
      },
      {
        label: t(`${key}.finance.financialAnalyst`),
        value: 'financialAnalyst',
      },
      {
        label: t(`${key}.finance.financialController`),
        value: 'financialController',
      },
      { label: t(`${key}.finance.treasurer`), value: 'treasurer' },
    ],
    [`${t(getLabel('operations'))}`]: [
      {
        label: t(`${key}.operations.accountManager`),
        value: 'accountManager',
      },
      {
        label: t(`${key}.operations.administrativeAssistant`),
        value: 'administrativeAssistant',
      },
      {
        label: t(`${key}.operations.businessDevelopmentManager`),
        value: 'businessDevelopmentManager',
      },
      {
        label: t(`${key}.operations.customerService`),
        value: 'customerService',
      },
      {
        label: t(`${key}.operations.officeManager`),
        value: 'officeManager',
      },
      {
        label: t(`${key}.operations.operationsManager`),
        value: 'operationsManager',
      },
      {
        label: t(`${key}.operations.projectManager`),
        value: 'projectManager',
      },
      { label: t(`${key}.operations.sales`), value: 'sales' },
    ],
    [`${t(getLabel('procurement'))}`]: [
      {
        label: t(`${key}.procurement.procurementManager`),
        value: 'procurementManager',
      },
      {
        label: t(`${key}.procurement.headOfPurchasing`),
        value: 'headOfPurchasing',
      },
    ],
    [`${t(`${key}.other`)}`]: [{ label: t(`${key}.other`), value: 'other' }],
  };
}
