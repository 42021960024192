import React from 'react';

export default function CustomValidation({
  errorMessage,
  condition,
}: {
  errorMessage: string | boolean;
  condition: boolean;
}) {
  return condition ? <p className="validation-error">{errorMessage}</p> : null;
}
