function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useContext, useEffect, useState } from 'react';
import { NavContext } from '../Contexts';
export var useNav = function useNav(navName) {
  var _useContext = useContext(NavContext),
      navItems = _useContext.navItems;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      selectedNav = _useState2[0],
      setSelectedNav = _useState2[1];

  useEffect(function () {
    if (navItems && navName) {
      var children = navItems.children;
      setSelectedNav(children === null || children === void 0 ? void 0 : children.find(function (child) {
        return child.name === navName;
      }));
    }
  }, [navItems, navName]);
  return selectedNav;
};
export var useAccessCheck = function useAccessCheck(path) {
  var excludedPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      hasAccess = _useState4[0],
      setHasAccess = _useState4[1];

  var _useContext2 = useContext(NavContext),
      accessiblePaths = _useContext2.accessiblePaths;

  var checkPaths = function checkPaths(arrayOfPaths) {
    return Boolean(arrayOfPaths.find(function (p) {
      return path.startsWith(p);
    }));
  };

  useEffect(function () {
    if (!path) return;

    if (excludedPaths !== null && excludedPaths !== void 0 && excludedPaths.length && checkPaths(excludedPaths)) {
      setHasAccess(true);
    } else if (accessiblePaths && path) {
      setHasAccess(checkPaths(accessiblePaths));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [path, accessiblePaths]);
  return hasAccess;
};