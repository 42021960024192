import { cloneDeep, isEqual } from 'lodash';
export function getItemLabel(item, t) {
  if (!item) {
    return '';
  } // handle differences in way apps convert menu items
  // TODO: normalize ui app nav converters


  var translationKey = "navigation.".concat((item === null || item === void 0 ? void 0 : item.localizationKey) || (item === null || item === void 0 ? void 0 : item.label));
  var defaultText = (item === null || item === void 0 ? void 0 : item.defaultDisplayName) || (item === null || item === void 0 ? void 0 : item.text);

  if (translationKey === 'navigation.undefined') {
    return defaultText;
  }

  var labelTranslation = t(translationKey);

  if (labelTranslation === translationKey && defaultText) {
    return defaultText;
  }

  return labelTranslation;
}

var setActive = function setActive(menuItems, path) {
  var _menuItems$children;

  var levelsDeep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var foundActive = false;

  var createPath = function createPath() {
    var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return p.split('/').slice(0, levelsDeep + 1).join('/');
  };

  var pathname = createPath(path);
  menuItems === null || menuItems === void 0 ? void 0 : (_menuItems$children = menuItems.children) === null || _menuItems$children === void 0 ? void 0 : _menuItems$children.forEach(function (menuItem) {
    var _menuItem$children, _menuItem$children2, _menuItem$children3;

    var menuPath = menuItem.path; // if the menu item does not specify a path, use the first child's path as per convention

    if (!menuPath && menuItem !== null && menuItem !== void 0 && (_menuItem$children = menuItem.children) !== null && _menuItem$children !== void 0 && _menuItem$children[0]) {
      var firstChild = menuItem.children[0];

      if (firstChild !== null && firstChild !== void 0 && firstChild.url) {
        // eslint-disable-next-line no-param-reassign
        menuItem.url = firstChild.url;
        menuPath = firstChild.url;
      } else if (firstChild !== null && firstChild !== void 0 && firstChild.path) {
        // eslint-disable-next-line no-param-reassign
        menuItem.path = firstChild.path;
        menuPath = firstChild.path;
      }
    }

    if (menuItem !== null && menuItem !== void 0 && (_menuItem$children2 = menuItem.children) !== null && _menuItem$children2 !== void 0 && _menuItem$children2.length) {
      setActive(menuItem, path);
    }

    if (createPath(menuPath) === pathname || menuItem !== null && menuItem !== void 0 && (_menuItem$children3 = menuItem.children) !== null && _menuItem$children3 !== void 0 && _menuItem$children3.some(function (child) {
      return child.isActive;
    })) {
      // eslint-disable-next-line no-param-reassign
      menuItem.isActive = true;
      foundActive = true;
    } else {
      // eslint-disable-next-line no-param-reassign
      menuItem.isActive = false;
    }
  });

  if (!foundActive && levelsDeep > 1) {
    setActive(menuItems, path, 1);
  }
};

export var activeNavReducer = function activeNavReducer(state, action) {
  var navItems = action.navItems,
      path = action.path;

  if (!navItems) {
    return {};
  }

  var navCopy = cloneDeep(navItems);
  setActive(navCopy, path);

  if (isEqual(state, navCopy)) {
    return state;
  }

  return navCopy;
};
export var pageTitleReducer = function pageTitleReducer(state, action) {
  var menus = action.menus,
      t = action.t;
  var activeMenu;

  var findActive = function findActive(menu) {
    var _menu$children;

    var foundActive = menu === null || menu === void 0 ? void 0 : (_menu$children = menu.children) === null || _menu$children === void 0 ? void 0 : _menu$children.find(function (child) {
      return child.isActive;
    });

    if (foundActive) {
      var _activeMenu, _activeMenu$children;

      activeMenu = foundActive;

      if ((_activeMenu = activeMenu) !== null && _activeMenu !== void 0 && (_activeMenu$children = _activeMenu.children) !== null && _activeMenu$children !== void 0 && _activeMenu$children.length) {
        findActive(activeMenu);
      }
    }
  };

  menus.forEach(function (menu) {
    if (!activeMenu) {
      findActive(menu);
    }
  });

  if (activeMenu) {
    return getItemLabel(activeMenu, t);
  }

  return '';
};