import React from 'react';
import { Button } from '../Button';
import { cx } from '../utils';
import { OptionProps } from '../types';

function Option({
  activeIndex = -1,
  checked = false,
  data,
  disabledText = '',
  index = -1,
  onClick,
  search = '',
  showCheckbox = false,
}: OptionProps) {
  const { disabled, fixedOrder, label, value } = data;

  const handleClick = event => {
    event.stopPropagation();
    onClick(data);
  };

  const renderLabel = () => {
    if (search === '') {
      return (
        <>
          <span className="label">{label}</span>
          {disabled ? (
            <span className="disabled-text">{disabledText}</span>
          ) : null}
        </>
      );
    }
    const idx = label.toLowerCase().indexOf(search.toLowerCase());
    return (
      <>
        <span className="label">
          {label.substring(0, idx)}
          <strong>{label.substring(idx, idx + search.length)}</strong>
          {label.substring(idx + search.length)}
        </span>
        {disabled ? (
          <span className="disabled-text">{disabledText}</span>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Button
        aria-selected={index === activeIndex}
        className={cx('option available', {
          disabled,
          focused: index === activeIndex,
        })}
        data-testid={`option-${value}`}
        disabled={disabled}
        onClick={handleClick}
        role="option"
        theme="none"
      >
        {showCheckbox ? (
          <>
            <input
              type="checkbox"
              name={`option-${value}`}
              id={`option-${value}`}
              data-testid={`option-checkbox-${value}`}
              disabled={disabled}
              checked={checked}
              onChange={() => {}}
            />
            {renderLabel()}
          </>
        ) : (
          renderLabel()
        )}
      </Button>
      {fixedOrder === 99 && <hr className="fixed-order-last" />}
    </>
  );
}

export default Option;
