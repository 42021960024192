import { Branding, useFetch } from 'taulia-ui';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Color from 'color';

const setBranding = (branding = null) => {
  if (branding) {
    const { primaryColor } = branding;

    const colorDarkened = Color(primaryColor)
      .darken(0.5)
      .hex();

    const primaryButtonStyles = `
      button.tau-button.theme-primary:not(:disabled) {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
      }
      
      button.tau-button.theme-primary:hover:not(:disabled), button.tau-button.theme-primary:focus {
      background-color: ${colorDarkened};
      }
      
      button.tau-button.theme-primary:enabled:active {
      background-color: ${primaryColor};
      box-shadow: ${colorDarkened} 0 0 3px inset;
      }
      
      button.tau-button.theme-primary.spinning {
      background-color: ${primaryColor};
      }
      
      button.tau-button.theme-text {
      color: ${primaryColor};
      }
      `;

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    head.appendChild(style);
    style.appendChild(document.createTextNode(primaryButtonStyles));
    Branding.setValues(branding);
  }
};

export const useBranding = request => {
  const [loaded, setLoaded] = useState(false);
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { doFetch: doBrandingFetch } = useFetch('/api/branding', {
    request,
    lazy: true,
  });

  useEffect(() => {
    if (params.has('style')) {
      doBrandingFetch({
        query: { alias: params.get('style') },
        onSuccess: data => {
          if (data) {
            setBranding(data);
            setLoaded(true);
          }
        },
        onError: () => {
          // if there are issues loading a branding, we should continue loading the app with Taulia branding so users are able to login
          setLoaded(true);
        },
      });
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return loaded;
};
