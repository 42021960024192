import { compact, flatten, isArray, isString, map } from 'lodash';
export default function cx() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var arr = map(args, function (arg) {
    if (isString(arg) || isArray(arg)) {
      return arg;
    }

    return map(arg, function (condition, name) {
      return condition ? name : null;
    });
  });
  return compact(flatten(arr)).join(' ');
}