import { getI18n } from 'react-i18next';
import { LIB_APP_UI_NS } from '../../utils/i18nUtils';

export const getDateRangeValues = (filter, isLocalDate) => {
  const dates = [...(filter.values ? filter.values : [])];

  if (dates.length === 1) {
    filter.operator === 'GREATER_THAN_EQUAL'
      ? dates.push(null)
      : dates.unshift(null);
  }

  return dates.map((date, index) =>
    convertDateToLocalTimezone(date, index === 1, isLocalDate)
  );
};

const convertDateToLocalTimezone = (dateString, isEndDate, isLocalDate) => {
  if (dateString && !isLocalDate) {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let endDateOffset = 0;
    if (isEndDate) {
      endDateOffset = (60 * 23 + 59) * 60000;
    }
    return new Date(date.getTime() - endDateOffset + userTimezoneOffset);
  }
  return dateString;
};

export const convertDateToUtc = (date, isEndDate) => {
  if (date) {
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let endDateOffset = 0;
    if (isEndDate) {
      endDateOffset = (60 * 23 + 59) * 60000;
    }
    return new Date(date.getTime() + endDateOffset - userTimezoneOffset);
  }
  return undefined;
};

/* Returns localized data based on start/end date */
export const createSelectInputLabelOperator = dates => {
  const i18next = getI18n();
  const [start, end] = dates;
  const locale = i18next.language;

  const formattedStart = new Date(start).toLocaleDateString(locale);
  const formattedEnd = new Date(end).toLocaleDateString(locale);

  if (!start) {
    return {
      operator: 'LESS_THAN_EQUAL',
      inputLabel: `${i18next.t(
        `${LIB_APP_UI_NS}:enhancedSearch.date.before`
      )} ${formattedEnd}`,
    };
  }
  if (!end) {
    return {
      operator: 'GREATER_THAN_EQUAL',
      inputLabel: `${i18next.t(
        `${LIB_APP_UI_NS}:enhancedSearch.date.after`
      )} ${formattedStart}`,
    };
  }
  return {
    operator: 'BETWEEN',
    inputLabel: `${formattedStart} - ${formattedEnd}`,
  };
};

/* Generates the initial state value */
export const getInitialLabel = (filter, isLocalDate) => {
  const i18next = getI18n();
  /* If no date-range filter has been applied, the label shows all records */
  if (!filter.values?.length) {
    return i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.allTime`);

    /* If specific date-range filter is applied, the label is adjusted to show the range */
  } else {
    const dates = getDateRangeValues(filter, isLocalDate);
    const { inputLabel } = createSelectInputLabelOperator(dates);

    return inputLabel;
  }
};

/* Crates a Date Range based on the current date */
export const createRange = (range: number) => {
  const now = new Date(Date.now());
  const newDate = new Date(new Date(now).setDate(now.getDate() - range));

  return newDate;
};

/* Dropdown options for the last: 1, 2, 7, 30, 365 days */
export const createDateList = localDate => {
  const i18next = getI18n();
  const now = localDate
    ? new Date(Date.now()).toISOString().split('T')[0]
    : new Date(Date.now());

  return [
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.allTime`),
      dates: [],
    },
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.currentDay`),
      dates: [createRange(1), now],
    },
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.previousDay`),
      dates: [createRange(2), createRange(1)],
    },
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.last7Days`),
      dates: [createRange(7), now],
    },
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.last30Days`),
      dates: [createRange(30), now],
    },
    {
      label: i18next.t(`${LIB_APP_UI_NS}:enhancedSearch.date.last1Year`),
      dates: [createRange(365), now],
    },
  ];
};
