import React from 'react';
import { Branding } from '../Branding';
import { colors } from '../theme';
import { cx } from '../utils';
import { AlertProps } from '../types';
import { Button } from '../Button';
import { XIcon } from '../Icons';

function Alert({
  children = null,
  className = '',
  xButton = false,
  icon = null,
  inline = false,
  onRequestClose = () => null,
  style = {},
  theme = 'primary',
  ...otherProps
}: AlertProps) {
  const getStyles = () => {
    if (theme === 'primary') {
      const primary = Branding.fetchColor('primary').hex();
      return {
        ...style,
        backgroundColor: primary,
        borderColor: primary,
        color: `${colors.white}`,
        fill: `${colors.white}`,
        stroke: `${colors.white}`,
      };
    }
    return style;
  };

  return (
    <div
      className={cx('tau-alert', `theme-${theme}`, className, { inline })}
      style={getStyles()}
      {...otherProps}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="content">{children}</div>
      {xButton && (
        <div className="tau-alert-icon">
          <Button
            data-testid="alert-close-btn"
            theme="none"
            onClick={onRequestClose}
          >
            <XIcon />
          </Button>
        </div>
      )}
    </div>
  );
}

export default Alert;
