import React from 'react';
import { hasValue } from '../utils/generic';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { AutomatedValidationProps } from '../../types';

export default function AutomatedValidation({
  children = [],
  errorMessage,
  isTouched,
  required,
  value,
}: AutomatedValidationProps) {
  const { t } = useLibNSTranslation();

  const isEmpty = !hasValue(value);
  const CustomRequiredErrorMessage =
    typeof required === 'object' ? required.errorMessage : null;

  return (
    <div className="validation-error-wrapper">
      {/* >> Required Error << */}
      {required && isEmpty && isTouched ? (
        <p className="validation-error --required">
          {CustomRequiredErrorMessage || t('input.required')}
        </p>
      ) : null}
      {/* >> General Error << */}
      {errorMessage && !isEmpty ? (
        <p className="validation-error">{errorMessage}</p>
      ) : null}
      {children}
    </div>
  );
}
