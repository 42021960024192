import React from 'react';
import { Button } from '../Button';
import { cx } from '../utils';
import { TabsProps } from '../types/Tabs';

function Tabs({ data }: TabsProps) {
  return (
    <div className="tab-container">
      {data.map(tab => {
        const { active, callback, label } = tab;
        return (
          <Button
            className={cx('tab', { active })}
            key={label}
            onClick={callback}
            theme="text"
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
}

export default Tabs;
