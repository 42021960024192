import React, { useState } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { Checkbox } from '../../Checkbox';
import SelectInput from './SelectInput';
import { BooleanFilterProps } from '../../types/EnhancedSearch';

export default function BooleanFilter({
  field,
  filter,
  onFilterChange,
  tooltipText,
}: BooleanFilterProps) {
  const { t } = useLibNSTranslation();

  const [state, setState] = useState({
    trueOptionChecked: filter.values?.includes(true) || false,
    falseOptionChecked: filter.values?.includes(false) || false,
  });
  const { trueOptionChecked, falseOptionChecked } = state;

  const booleanOptions = [
    { value: 'true', label: field.trueLabel, checked: trueOptionChecked },
    { value: 'false', label: field.falseLabel, checked: falseOptionChecked },
  ];

  /* -----> Handlers <----- */
  /* Clicking on checkbox, adjusts the current state and fires the onFilterChange handler */
  const onCheckboxChange = event => {
    const { value, checked } = event.target;
    const optionsChecked = {
      trueOptionChecked: value === 'true' ? checked : trueOptionChecked,
      falseOptionChecked: value === 'false' ? checked : falseOptionChecked,
    };

    setState(optionsChecked);

    const values = [
      ...(optionsChecked.trueOptionChecked ? [true] : []),
      ...(optionsChecked.falseOptionChecked ? [false] : []),
    ];
    const updatedFilter = { ...filter, operator: 'EQUAL', values };

    onFilterChange(updatedFilter);
  };

  return (
    <div className="filterSelect">
      <SelectInput
        className="booleanFilter"
        fieldName={field.name}
        label={field.label}
        tooltipText={tooltipText}
        selectedContent={[
          ...(trueOptionChecked ? [field.trueLabel] : []),
          ...(falseOptionChecked ? [field.falseLabel] : []),
        ]}
        content={
          <div className="filter-content">
            {booleanOptions.map(option => (
              <Checkbox
                checked={option.checked}
                className="option"
                data-testid={option.value}
                key={option.label}
                onChange={onCheckboxChange}
                value={option.value}
              >
                {t(option.label || String(option.value))}
              </Checkbox>
            ))}
          </div>
        }
      />
    </div>
  );
}
