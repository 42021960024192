import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { RadioListProps } from '../types';
import Radio from '../Radio/Radio';
import { CustomValidation, validate } from '../Validation';
import { cx } from '../utils';
import { Label } from '../Label';

const RadioList = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      id,
      label = '',
      onChange,
      options,
      validation = {},
      value = options[0].value,
      vertical = false,
    }: RadioListProps,
    ref
  ) => {
    const [selectedRadio, setSelectedRadio] = useState(value);
    const [isTouched, setIsTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);

    const touchedRef = useRef(false);
    const errorRef = useRef<false | string>(false);

    /* -----> Utils <----- */
    const updateTouched = (touched: boolean) => {
      setIsTouched(touched);
      touchedRef.current = touched;
    };

    const updateError = (error: false | string) => {
      setErrorMessage(error);
      errorRef.current = error;
    };

    const componentHasError = (checkRefs = false) => {
      if (checkRefs) {
        return errorRef.current && touchedRef.current;
      }

      return errorMessage && isTouched;
    };

    /* -----> Handlers <----- */
    const onChangeHandler = (radioValue: string) => {
      setSelectedRadio(radioValue);
      if (onChange) onChange(radioValue);

      updateTouched(true);

      if (validation) {
        const validationError = validate(radioValue, validation, {}); // TODO: Add a list of predefined validations
        updateError(validationError);
        __triggerFormValidation(id);
      }
    };

    /* -----> Form Automation <----- */
    useImperativeHandle(ref, () => ({
      name: id,
      value: selectedRadio,
      clearState: () => {
        setSelectedRadio(value);
        updateTouched(false);
        updateError(false);
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    /* -----> View <----- */
    const Radios = options.map(radio => (
      <Radio
        inline={!vertical}
        {...radio.props}
        key={radio.value}
        id={radio.value}
        checked={selectedRadio === radio.value}
        onChange={() => onChangeHandler(radio.value)}
      >
        <p className={`radio-${radio.value}`}>{radio.label}</p>
      </Radio>
    ));

    return (
      <div className={cx('radio-list-container', id)}>
        <Label content={label} htmlFor={id} />
        <div className="radio-list-wrapper">{Radios}</div>
        <div className="validation-error-wrapper">
          <CustomValidation
            errorMessage={errorMessage}
            condition={Boolean(errorMessage)}
          />
        </div>
      </div>
    );
  }
);

RadioList.defaultProps = {
  __hasRef: true,
};

export default RadioList;
