import { useEffect, useState } from 'react';
import { MappedConfiguration } from '../../types/EnhancedSearch';
import { mapConfigurationFields } from '../utils/EnhancedSearchUtils';
import useESContext from './useESContext';

export default function useAdjustedFields({ excludedFields, fieldOverrides }) {
  const { configuration, setConfiguration } = useESContext();
  const [overrides, setOverrides] = useState(fieldOverrides);
  const [excluded, setExcluded] = useState(excludedFields);

  const updateConfiguration = () => {
    const newConfiguration: MappedConfiguration = mapConfigurationFields(
      { excludedFields, fieldOverrides },
      configuration
    );

    setConfiguration(newConfiguration);
  };

  useEffect(() => {
    if (configuration) {
      if (JSON.stringify(fieldOverrides) !== JSON.stringify(overrides)) {
        setOverrides(fieldOverrides);
        updateConfiguration();
      }

      if (JSON.stringify(excludedFields) !== JSON.stringify(excluded)) {
        setExcluded(excludedFields);
        updateConfiguration();
      }
    }
  }, [excludedFields, fieldOverrides]);
}
