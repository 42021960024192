import React, { useContext, useEffect, useState } from 'react';
import store from 'store';
import validate from 'validate.js';
import {
  Button,
  Checkbox,
  Clickable,
  Col,
  EyeHide,
  EyeShow,
  Field,
  FieldErrorMap,
  FieldLabel,
  Grid,
  Link,
  Text,
  UserSettingsContext,
  useSendContext,
} from 'taulia-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LoginPageError from './LoginPageError';
import { AuthTypeContext } from '../../contexts/authTypeProvider';
import { removeWhitespace } from '../../utils';
import { useQuery } from '../../hooks';
import Captcha from '../../components/Captcha';

const RENEW_CONFIG_INTERVAL = 3; // hours

function LoginPage() {
  const { t } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const { setRedirectUrl } = useContext(AuthTypeContext);
  const { authRequestId } = useParams();
  const { activationLinkUrl, redirectUser, rsaEncrypt, refetchConfig } =
    useContext(UserSettingsContext);

  const [errorCode, setErrorCode] = useState(query.get('errorCode'));
  const [password, setPassword] = useState({ value: '', error: null });
  const [remember, setRemember] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [failedLogins, setFailedLogins] = useState(
    JSON.parse(localStorage.getItem('failed-logins'))
  );
  const [configTimestamp, setConfigTimestamp] = useState(null);

  const [email, setEmail] = useState({
    value: store.enabled ? store.get('rememberEmail') : '',
    error: null,
  });

  const {
    data: loginData,
    error: loginError,
    loading: loginLoading,
    doSend: doLogin,
  } = useSendContext('POST', '/api/login');

  function loginUser(renewedRsaEncrypt = false) {
    doLogin({
      username: email.value,
      passwordEncrypted: renewedRsaEncrypt
        ? renewedRsaEncrypt.encrypt(password.value)
        : rsaEncrypt.encrypt(password.value),
      authRequestId,
    });
  }

  const login = () => {
    if ((Date.now() - configTimestamp) / 3600000 > RENEW_CONFIG_INTERVAL) {
      refetchConfig(loginUser);
      setConfigTimestamp(new Date());
    } else {
      loginUser();
    }
  };

  const resetFailedLogins = () => {
    localStorage.removeItem('failed-logins');
    setFailedLogins({ count: 0 });
  };

  const isCaptchaRequired = () => failedLogins?.count >= 3;

  const captchaRequirementExpired = () => {
    const timestamp = JSON.parse(localStorage.getItem('failed-logins'))
      ?.timestamp;

    if (timestamp) {
      const elapsedTimeInHours = Math.floor(
        (new Date() - new Date(timestamp)) / (1000 * 60 * 60)
      );

      if (elapsedTimeInHours >= 48) {
        resetFailedLogins();

        return true;
      }

      return false;
    }

    return false;
  };

  const rememberEmail = emailAddress => {
    if (store.enabled) {
      store.set('rememberEmail', emailAddress);
    }
  };

  const is2FAReminderExpired = () => {
    if (store.enabled) {
      if (!store.get('twoFAPromo')) {
        // If nothing in local storage, navigate to promo page
        return true;
      }
      const today = new Date();
      const expiryDate = new Date(store.get('twoFAPromo').expiry);
      return today > expiryDate;
    }
    return false;
  };

  useEffect(() => {
    window.parent.postMessage(
      { type: 'loginPageLoading', location: window.location.href },
      '*'
    );
    setConfigTimestamp(new Date());
  }, []);

  useEffect(() => {
    if (loginData) {
      if (loginData.success && loginData.redirectUrl) {
        resetFailedLogins();

        if (remember) {
          rememberEmail(email.value);
        }
        if (
          loginData.show2FAPromo &&
          is2FAReminderExpired() &&
          !loginData.remoteAuthenticatedOnly
        ) {
          setRedirectUrl(loginData.redirectUrl);
          navigate('/login/two-factor/promotion');
        } else {
          redirectUser(loginData.redirectUrl);
        }
      } else {
        const failedLoginsObj = {
          count:
            Number(
              JSON.parse(localStorage.getItem('failed-logins'))?.count || 0
            ) + 1,
          timestamp: new Date(),
        };

        localStorage.setItem('failed-logins', JSON.stringify(failedLoginsObj));
        setFailedLogins(failedLoginsObj);
        setErrorCode(loginData.reason || 'UNKNOWN_ERROR');
      }
    } else if (loginError) {
      setErrorCode(loginError?.response?.data?.message || 'UNKNOWN_ERROR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData, loginError]);

  const onSubmit = event => {
    event.preventDefault();

    const errors = validate(
      { email: email.value, password: password.value },
      {
        email: {
          email: { message: '^email' },
          presence: { message: '^presence' },
        },
        password: {
          presence: { message: '^presence' },
        },
      }
    );

    if (errors) {
      setEmail({
        value: email.value,
        error: errors.email || null,
      });
      setPassword({
        value: password.value,
        error: errors.password || null,
      });
    } else {
      login();
    }
  };

  const setCaptcha = () => {
    setCaptchaStatus(true);
  };

  const onCaptchaExpired = () => {
    setCaptchaStatus(false);
  };

  return (
    <>
      <div className="login-container">
        <h2 className="auth-header">{t('loginPage.title')}</h2>
        <form id="loginForm" onSubmit={onSubmit} method="post">
          {errorCode && (
            <LoginPageError
              activationLinkUrl={activationLinkUrl}
              email={email}
              errorCode={errorCode}
            />
          )}
          <Field validationState={email.error ? 'error' : null}>
            <FieldLabel htmlFor="login">{t('loginPage.email')}</FieldLabel>
            <Text
              id="login"
              name="email"
              validationState={email.error ? 'error' : null}
              value={email.value}
              onChange={event =>
                setEmail({
                  value: removeWhitespace(event.target.value),
                  error: null,
                })
              }
              autoCapitalize="off"
            />
            <FieldErrorMap>{t('loginPage.error.invalidEmail')}</FieldErrorMap>
          </Field>
          <Field validationState={password.error ? 'error' : null}>
            <FieldLabel htmlFor="password">
              {t('loginPage.password')}
            </FieldLabel>
            <div className="password-wrapper">
              <Text
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                validationState={password.error ? 'error' : null}
                value={password.value}
                onChange={event =>
                  setPassword({
                    value: event.target.value,
                    error: null,
                  })
                }
                autoCapitalize="off"
              />
              <Clickable
                aria-label="toggle-password-icon"
                className="toggle-password-icon"
                data-testid="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeShow height={16} /> : <EyeHide />}
              </Clickable>
            </div>
            <FieldErrorMap>
              {t('loginPage.error.missingPassword')}
            </FieldErrorMap>
          </Field>
          <Grid className="remember-username-container">
            <Col sm={60} md={60} lg={60}>
              <Field>
                <Checkbox
                  id="rememberUsername"
                  aria-label="remember-username-checkbox"
                  name="remember"
                  checked={remember}
                  onChange={event => setRemember(event.target.checked)}
                >
                  {t('loginPage.remember')}
                </Checkbox>
              </Field>
            </Col>
            <Col sm={40} md={40} lg={40}>
              <Button
                id="forgotPassword"
                theme="text"
                onClick={() => navigate('/reset-password')}
                style={{ float: 'right' }}
              >
                {t('loginPage.forgotPassword')}
              </Button>
            </Col>
          </Grid>
          {isCaptchaRequired() && !captchaRequirementExpired() && (
            <Captcha onVerify={setCaptcha} onExpire={onCaptchaExpired} />
          )}
          <Button
            id="loginSubmitButton"
            type="submit"
            theme="primary"
            disabled={
              email.error !== null ||
              password.error !== null ||
              (isCaptchaRequired() && !captchaStatus)
            }
            spinning={
              loginLoading ||
              (loginData && loginData.redirectUrl && loginData.success)
            }
          >
            {t('loginPage.loginAction')}
          </Button>
        </form>
      </div>
      <div className="footer">
        <Link
          href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-I-cannot-log-into-the-platform-1449537111830/"
          id="helpLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer.help')}
        </Link>
      </div>
    </>
  );
}

export default LoginPage;
