function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useContext, useEffect, useRef, useMemo, useReducer } from 'react';
import { RequestContext } from '../Contexts';

var doFetchReducer = function doFetchReducer(state, query) {
  var prevIncr = state.incr;
  return {
    incr: prevIncr + 1,
    query: query
  };
};

var fetchRespReducer = function fetchRespReducer(state, _ref) {
  var data = _ref.data,
      error = _ref.error,
      loading = _ref.loading,
      fetch = _ref.fetch;
  var doFetch = state.doFetch,
      prevData = state.data;
  var resp = {
    data: prevData,
    fetch: fetch,
    doFetch: doFetch
  };

  if (loading) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      loading: loading,
      error: null
    });
  }

  if (error) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      data: null,
      loading: false,
      error: error
    });
  }

  if (data) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      data: data,
      loading: false,
      error: null
    });
  }

  return state;
};
/**
 * A FetchObject is a stateful object that represent the state of the fetch as well as methods for executing fetches.
 *
 * @typedef {Object} FetchObject
 * @property {Object} data - The response data from the request, without the `body` property. It is null if the request is not complete
 * @property {boolean} loading - True if the request is in flight, false otherwise
 * @property {Error} error - Error object returned by request if there is a failure
 * @property {function} doFetch - Function to initiate another fetch. An object representing query parameters can be passed as the argument
 */

/**
 * The useFetch hook is a method to fetch state from a server.
 *
 * The {@link FetchObject} that is returned is stored in state by the hook, and therefore it is not required for it or
 * its properties to be stored in state by the parent component. Any updates to the object and its properties will trigger a re-render.
 *
 * Fetches can be initiated eagerly or lazily. Eager fetches execute as soon as the hook is initiated. Lazy fetches will
 * only execute when {@link FetchObject.doFetch} is called.
 *
 * @example
 * // Eager Fetching
 * const { loading, data, error, doFetch } = useFetch('/api/get-endpoint', request);
 *
 * return (
 *  <>
 *  { loading && <Spinner /> }
 *  { data && <div>it loaded!</div> }
 *  { error && <GenericError /> }
 *  </>
 *  );
 *
 * // Lazy Fetching
 * const lazy = true;
 * const { loading, data, error, doFetch } = useFetch('/api/get-endpoint', request, lazy);
 *
 * useEffect(() => {
 *   doFetch({ queryParam: 'Cinco' });
 * }, [])
 *
 * @deprecated use useFetchV2 (exported as useFetch)
 * @param {string} url Path for http request
 * @param {function} request The <code>request</code> function being used by the app
 * @param {boolean} [lazy=false] Assign true if the fetch should not be performed eagerly
 * @returns {FetchObject} fetchObject
 */


var useFetch = function useFetch(url, request) {
  var lazy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var _useReducer = useReducer(doFetchReducer, {
    incr: 0,
    fetchQuery: null
  }),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      fetch = _useReducer2[0],
      doFetch = _useReducer2[1];

  var _useReducer3 = useReducer(fetchRespReducer, {
    loading: !lazy,
    data: null,
    error: null,
    fetch: fetch,
    doFetch: doFetch
  }),
      _useReducer4 = _slicedToArray(_useReducer3, 2),
      fetchResp = _useReducer4[0],
      setFetchResp = _useReducer4[1];

  var start = lazy ? 1 : 0;
  useEffect(function () {
    var incr = fetch.incr,
        query = fetch.query;

    if (incr < start || lazy && incr === fetchResp.fetch.incr) {
      return;
    }

    setFetchResp({
      loading: true,
      fetch: fetch
    });
    request('GET', url).query(query).then(function (res) {
      setFetchResp({
        data: res.body,
        fetch: fetch
      });
    }).catch(function (respError) {
      setFetchResp({
        error: respError,
        fetch: fetch
      });
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetch, url]);
  return fetchResp;
};

var sendReducer = function sendReducer(state, payload) {
  var prevIncr = state.incr;
  return {
    incr: prevIncr + 1,
    payload: payload
  };
};

var sendRespReducer = function sendRespReducer(state, _ref2) {
  var loading = _ref2.loading,
      data = _ref2.data,
      success = _ref2.success,
      error = _ref2.error,
      send = _ref2.send;
  var doSend = state.doSend,
      prevData = state.data;
  var resp = {
    data: prevData,
    send: send,
    doSend: doSend
  };

  if (loading) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      loading: true,
      success: false,
      complete: false,
      error: null
    });
  }

  if (error) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      loading: false,
      error: error,
      data: null,
      success: false,
      complete: true
    });
  }

  if (success) {
    return _objectSpread(_objectSpread({}, resp), {}, {
      loading: false,
      error: null,
      success: success,
      data: data,
      complete: true
    });
  }

  return state;
};
/**
 * A SendObject is a stateful object that represent the state of the send as well as methods for executing sends.
 *
 * @typedef {Object} SendObject
 * @property {Object} data - The response data from the request, without the `body` property. It is null if the request is in flight
 * @property {boolean} loading - True if the request is in flight, false otherwise
 * @property {Error} error - Error object returned by request if there is a failure
 * @property {boolean} complete - True if the send was completed, regardless of if it was successful or not
 * @property {boolean} success - True if the send was successfully completed, useful for requests with no response data
 * @property {function} doSend - Function to initiate another send. An object representing POST/PUT parameters can be passed as the argument.
 */

/**
 * The useSend hook is a method for sending POST/PUT updates to a server.
 *
 * The {@link SendObject} that is returned is stored in state by the hook, and therefore it is not required for it or
 * its properties to be stored in state by the parent component. Any updates to the object and its properties will trigger a re-render.
 *
 * Sends are always executed lazily, using the {@link SendObject.doSend} method
 *
 * @example
 * const { loading, data, error, complete, success, doSend } = useSendContext('POST', '/api/post-endpoint');
 * useEffect(() => doSend({postParam: 'Cinco'}), [])
 *
 * @deprecated use useFetchV2 (exported as useFetch) with options
 * @param {string} method - PUT or POST
 * @param {string} url - url path for the http request
 * @param {function} request - The <code>request</code> function used by the app
 * @returns {SendObject} sendObject
 */


var useSend = function useSend(method, url, request) {
  var _useReducer5 = useReducer(sendReducer, {
    incr: 0,
    payload: null
  }),
      _useReducer6 = _slicedToArray(_useReducer5, 2),
      send = _useReducer6[0],
      doSend = _useReducer6[1];

  var _useReducer7 = useReducer(sendRespReducer, {
    loading: false,
    data: null,
    complete: false,
    success: false,
    error: null,
    send: send,
    doSend: doSend
  }),
      _useReducer8 = _slicedToArray(_useReducer7, 2),
      sendResp = _useReducer8[0],
      setSendResp = _useReducer8[1];

  useEffect(function () {
    var incr = send.incr,
        payload = send.payload;

    if (incr > 0) {
      setSendResp({
        loading: true,
        send: send
      });
      request(method, url).send(payload).then(function (res) {
        setSendResp({
          data: res.body,
          success: true,
          send: send
        });
      }).catch(function (respError) {
        setSendResp({
          error: respError,
          send: send
        });
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [send]);
  return sendResp;
};
/**
 * This is a wrapper for {@link useFetch} that gets the request from context. It can be used anywhere the
 * <code>RequestContext</code> is available without having to pass in a <code>request</code> function.
 *
 * @deprecated use useFetchV2 (exported as useFetch) with options
 * @param {string} url
 * @param {boolean} lazy
 * @returns {FetchObject} fetchObject
 */


var useFetchContext = function useFetchContext(url) {
  var lazy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var request = useContext(RequestContext);
  return useFetch(url, request, lazy);
};
/**
 * This is a wrapper for {@link useSend} that gets the request from context. It can be used anywhere the
 * <code>RequestContext</code> is available without having to pass in a <code>request</code> function.
 *
 * @deprecated use useFetchV2 (exported as useFetch) with options
 * @param {string} method - PUT or POST
 * @param {string} url - url path for the http request
 * @returns {SendObject} sendObject
 */


var useSendContext = function useSendContext(method, url) {
  var request = useContext(RequestContext);
  return useSend(method, url, request);
};
/**
 * This is a wrapper for {@link useSend} that limits requests by a specified amount of time. This is useful for preventing
 * a user from potentially initating too many requests.
 *
 * @deprecated use useFetchV2 (exported as useFetch) with options
 * @param {string} method - PUT or POST
 * @param {string} url - url path for the http request
 * @param {function} request - the <code>request</code> used by the app
 * @param {number} debounceTime - The time in milliseconds to limit additional sends by
 * @returns {SendObject} sendObject
 */


var useDebounceSend = function useDebounceSend(method, url, request, debounceTime) {
  var lastSend = useRef(0);
  var sendObj = useSend(method, url, request);
  var sendFunc = sendObj.doSend;
  return useMemo(function () {
    return _objectSpread(_objectSpread({}, sendObj), {}, {
      doSend: function doSend(payload) {
        if (Date.now() - lastSend.current > debounceTime) {
          lastSend.current = Date.now();
          sendFunc(payload);
        }
      }
    });
  },
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  [sendObj, debounceTime]);
};

export { useFetch, useFetchContext, useSend, useSendContext, useDebounceSend };