function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import { getCookie } from 'tiny-cookie';
export default (function () {
  var cookieVal = {};

  try {
    cookieVal = JSON.parse(getCookie('profile'));

    if (!cookieVal || _typeof(cookieVal) !== 'object') {
      cookieVal = {};
    }
  } catch (e) {
    cookieVal = {};
  }

  return cookieVal;
});