function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFetch } from './useFetch';
import { RequestContext, UserSettingsContext } from '../Contexts';
import { usePrevious } from './usePrevious';
import { setInCache, getFromCache } from '../utils/cacheUtils';
/**
 * A CachedFetchObject is essentially a {@link FetchObject} with an additional doPurge function
 *
 * @property {Object} data - The response data from the request, without the `body` property. It is null if the request is not complete
 * @property {boolean} loading - True if the request is in flight, false otherwise
 * @property {Error} error - Error object returned by request if there is a failure
 * @property {function} doFetch - Function to initiate another fetch or get the last response from cache. An object representing query parameters can be passed as the argument
 * @property {function} doPurge - Function to remove cached value from LocalStorage. Useful when a resource has been updated and the new value from the server needs to be fetched.
 *
 * @typedef {Object} CachedFetchObject
 */

/**
 * @typedef {Object} UseCachedFetchOptions
 *
 * @property {String} sessionToken - A session token to use when determining if a cached value is expired. This provides a mechanism to limit cached values to the duration of a user's session.
 * @property {boolean} lazy - Set to true if the fetch should not be made upon initialization, and only by calling doFetch()
 * @property {String} appVersion - An app version id that can be used to expire cached data
 */

/**
 * useCachedFetch adds a client caching layer to {@link useFetch} via LocalStorage.
 *
 * @deprecated use useFetchV2 (exported as useFetch) with cacheOptions
 * @param url {String} The url of the endpoint to fetch data from
 * @param key {String} An additional unique key to use for local storage
 * @param maxAge {Number} How long in minutes the cached data will be valid for. If it is expired it will be replaced with new fetched data.
 * @param request {Function} The Request function to be used.
 * @param options {UseCachedFetchOptions} {@Link UseCachedFetchOptions}
 * @returns {CachedFetchObject} cachedFetchObject
 */

export var useCachedFetch = function useCachedFetch(url, key, maxAge, request) {
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  var _useFetch = useFetch(url, request, true),
      data = _useFetch.data,
      loading = _useFetch.loading,
      error = _useFetch.error,
      doFetch = _useFetch.doFetch;

  var prevData = usePrevious(data);
  var lazy = options.lazy;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      cachedData = _useState2[0],
      setCachedData = _useState2[1];

  var urlKey = "".concat(url, ":").concat(key);
  var doCachedFetch = useCallback(function () {
    var dataFromCache = getFromCache(urlKey, options);

    if (dataFromCache) {
      setCachedData(dataFromCache);
    } else {
      doFetch();
    }
  }, [urlKey, doFetch, options]);
  var doPurge = useCallback(function () {
    if (localStorage) {
      localStorage.removeItem(urlKey);
    }
  }, [urlKey]);
  useEffect(function () {
    if (!lazy && url) {
      doCachedFetch();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [url]);
  useEffect(function () {
    if (!data || data === prevData) {
      return;
    }

    setInCache(urlKey, maxAge, data, options);
    setCachedData(data);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data, urlKey]);
  return useMemo(function () {
    return {
      data: cachedData,
      loading: loading,
      error: error,
      doFetch: doCachedFetch,
      doPurge: doPurge
    };
  }, [cachedData, loading, error, doCachedFetch, doPurge]);
};
/**
 * @typedef UseCachedFetchContextOptions
 *
 * @property {boolean} shouldExpireWithSession - Set to true if cached data should expire with session.
 * @property {boolean} lazy - Set to true if fetch should not be made on initialization, or false if fetch should only be made with doFetch(). Defaults to true.
 * @property {Number} maxAge - How long, in minutes, the cached data will be valid for. If it is expired it will be replaced with new fetched data. Defaults to 5.
 */

/**
 * useCachedFetchContext is a wrapper for {@link useCachedFetch} where the Request object and unique key (userId) are
 * automatically provided.
 *
 * @deprecated use useFetchV2 (exported as useFetch) with options
 * @param url {String} The url of the endpoint to fetch data from
 * @param options {UseCachedFetchContextOptions} {@link UseCachedFetchContextOptions}
 * @returns {CachedFetchObject} cachedFetchObject
 */

export var useCachedFetchContext = function useCachedFetchContext(url) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var request = useContext(RequestContext);

  var _useContext = useContext(UserSettingsContext),
      userId = _useContext.userId,
      csrfConfig = _useContext.csrfConfig,
      appVersion = _useContext.appVersion;

  var _options$lazy = options.lazy,
      lazy = _options$lazy === void 0 ? false : _options$lazy,
      _options$shouldExpire = options.shouldExpireWithSession,
      shouldExpireWithSession = _options$shouldExpire === void 0 ? true : _options$shouldExpire,
      _options$maxAge = options.maxAge,
      maxAge = _options$maxAge === void 0 ? 5 : _options$maxAge;
  var cachedFetchOptions = {
    sessionToken: shouldExpireWithSession ? csrfConfig === null || csrfConfig === void 0 ? void 0 : csrfConfig.csrfToken : null,
    appVersion: appVersion,
    lazy: lazy
  };
  return useCachedFetch(url, userId, maxAge, request, cachedFetchOptions);
};