import React, { useContext } from 'react';
import TypeaheadFilter from './TypeaheadFilter';
import { RequestContext } from '../../Contexts';
import { GeneralFilterProps } from '../../types/EnhancedSearch';

export default function ObjectFilter({
  esaUri,
  field,
  ...otherProps
}: GeneralFilterProps) {
  const request = useContext(RequestContext);

  /* Creates Labels for the Options object */
  const createOptionLabel = (label, currentField) => {
    const enumOptions = currentField?.fields?.enumOptions;

    const matchOptionLabel = lbl =>
      enumOptions && enumOptions.filter(item => item.value === lbl);

    if (enumOptions && matchOptionLabel(label).length === 1) {
      return matchOptionLabel(label)[0].label;
    }

    return null;
  };

  /* Construct Options object */
  const optionsGetter = ({ values, search, maxOptions }) =>
    request('GET', `${esaUri}/field/${field.name}/references`)
      .query({ id: values, query: search, limit: maxOptions })
      .then(response =>
        response.body
          ?.filter(reference => reference.label)
          ?.map(({ id, label }) => ({
            value: id,
            label: createOptionLabel(label, field) || label,
          }))
      );

  return (
    <TypeaheadFilter
      optionsGetter={optionsGetter}
      field={field}
      {...otherProps}
    />
  );
}
