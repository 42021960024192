import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Field,
  FieldErrorMap,
  Spinner,
  Text,
  toast,
  UserSettingsContext,
  useSendContext,
  GooglePlayBadge,
  AppStoreBadge,
} from 'taulia-ui';
import { useTranslation, Trans } from 'react-i18next';
import QRCode from 'qrcode.react';
import {
  authCodeIsValid,
  authLinks,
  removeHyphensAndWhitespace,
} from '../../utils';
import CONSTANTS from '../../globals';

function AppSetup() {
  const { logger } = useContext(UserSettingsContext);
  const [authCode, setAuthCode] = useState('');
  const [authCodeIsDirty, setAuthCodeIsDirty] = useState(false);
  const [authCodeIsTouched, setAuthCodeIsTouched] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: confirmData,
    error: confirmError,
    loading: confirmLoading,
    doSend: doConfirmData,
  } = useSendContext('POST', '/api/two-factor-setup/confirm');

  const {
    data: authCodeData,
    error: authCodeError,
    loading: authCodeLoading,
    doSend: doSendAuthCode,
  } = useSendContext('PUT', '/api/two-factor-setup/config');

  const getConfirmData = () => {
    doConfirmData({ authCode: removeHyphensAndWhitespace(authCode) });
  };

  useEffect(() => {
    doSendAuthCode({ type: CONSTANTS.TWO_FACTOR_AUTH_TYPE.APP });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (confirmData) {
      logger.log('2FA Auth Code successfully confirmed/validated');
      window.location.href = confirmData.redirectUrl;
    } else if (confirmError) {
      toast.error(t('authentication.smsSetup.verificationError'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmData, confirmError]);

  const getAuthCodeValidationState = () =>
    authCodeIsDirty && authCodeIsTouched && !authCodeIsValid(authCode)
      ? 'error'
      : null;

  if (authCodeLoading) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  if (authCodeError) {
    return (
      <>
        <Alert theme="danger">{authCodeError.message}</Alert>
        <Button
          onClick={() =>
            doSendAuthCode({
              type: CONSTANTS.TWO_FACTOR_AUTH_TYPE.APP,
            })
          }
        >
          Retry
        </Button>
      </>
    );
  }

  return (
    <div className="app-setup">
      <h2 className="auth-header">{t('authentication.header')}</h2>
      <h4>{t('authentication.appSetup.header')}</h4>
      <p>{t('authentication.appSetup.infoText')}</p>
      <form
        onSubmit={event => {
          event.preventDefault();
          getConfirmData();
        }}
      >
        <ol className="auth-list">
          <li>
            {t('authentication.appSetup.stepOne')}
            <a
              className="img-link google-play"
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <GooglePlayBadge />
            </a>
            <a
              className="img-link app-store"
              target="_blank"
              rel="noopener noreferrer"
              href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
            >
              <AppStoreBadge />
            </a>
            <span className="subtext">
              <Trans
                i18nKey="authentication.appSetup.stepOneSubText"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...authLinks('authApps')}
              />
            </span>
          </li>
          <li>
            {t('authentication.appSetup.stepTwo')}
            <span className="manual-code">{authCodeData?.otpSecret}</span>
            {authCodeData?.qrCodeUrl && (
              <QRCode value={authCodeData?.qrCodeUrl} />
            )}
          </li>
          <li>
            {t('authentication.appSetup.stepThree')}
            <Field validationState={getAuthCodeValidationState()}>
              <Text
                aria-label="auth-code-input"
                value={authCode}
                inputMode="numeric"
                pattern="[0-9]*"
                validationState={getAuthCodeValidationState()}
                onBlur={() => setAuthCodeIsTouched(true)}
                onChange={e => setAuthCode(e.target.value)}
                onFocus={() => setAuthCodeIsDirty(true)}
              />
              <FieldErrorMap code={!authCodeIsValid ? 'invalid' : null}>
                {t('authentication.invalidAuthCode')}
              </FieldErrorMap>
            </Field>
          </li>
        </ol>
        <div className="button-navigation-container">
          <Button
            id="back-button"
            theme="text"
            onMouseDown={() => navigate(-1)}
            onTouchStart={() => navigate(-1)}
          >
            {t('standard.back')}
          </Button>
          <Button
            id="enable-twofa"
            theme="primary"
            type="submit"
            disabled={!authCodeIsValid(authCode)}
            spinning={
              confirmLoading || !!(confirmData && confirmData.redirectUrl)
            }
          >
            {t('authentication.enable')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AppSetup;
