import React, { useState } from 'react';
import { Button } from '../../Button';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { useESContext } from '../hooks';

export default function ESError() {
  const [showButton, setShowButton] = useState(true);
  const { t } = useLibNSTranslation();
  const context = useESContext();

  return (
    <div>
      <p>
        {t('enhancedSearch.error')}
        &nbsp;&#58;&#40;
      </p>
      {showButton ? (
        <Button
          onClick={() => {
            setShowButton(false);
          }}
        >
          &#40;
          {t('enhancedSearch.clickForDetails')}
          &#41;
        </Button>
      ) : (
        <p
          className="error"
          style={{ whiteSpace: 'pre-line', padding: '20px' }}
        >{`${context.mainState.error?.message}\n\n${context.mainState.error?.stack}`}</p>
      )}
    </div>
  );
}
