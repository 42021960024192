function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* -----> Utils <----- */
import { ARDefaultOptions } from '../../Hooks/useAutomatedRefs';
/* Checks if the provided element has a specific method and triggers it */

function triggerRefFunction(element, attributeName) {
  var _element$ref, _element$ref$current;

  var method = element === null || element === void 0 ? void 0 : (_element$ref = element.ref) === null || _element$ref === void 0 ? void 0 : (_element$ref$current = _element$ref.current) === null || _element$ref$current === void 0 ? void 0 : _element$ref$current[attributeName];
  if (method && typeof method === 'function') return method();
  return null;
}
/* -----> Ref Triggers <----- */

/* Functions that attempt to access and trigger methods exported in components refs. */


function triggerOnSubmitValidation(element) {
  triggerRefFunction(element, 'onSubmitValidation');
}

function triggerClearState(element) {
  triggerRefFunction(element, 'clearState');
}

export function elementHasValidationError(element) {
  var _element$props, _element$props2;

  /* Check if the element actually has any validations in place */
  if (element !== null && element !== void 0 && (_element$props = element.props) !== null && _element$props !== void 0 && _element$props.required || element !== null && element !== void 0 && (_element$props2 = element.props) !== null && _element$props2 !== void 0 && _element$props2.validation) {
    return triggerRefFunction(element, 'hasValidationError');
  }

  return false;
}
/* -----> Functionalities <----- */

/* Create an object with each inputs value */

export var compileFormData = function compileFormData(automatedElements) {
  var formData = automatedElements.reduce(function (result, element) {
    var _element$props3;

    if (!((_element$props3 = element.props) !== null && _element$props3 !== void 0 && _element$props3[ARDefaultOptions.disableDataGatheringKey])) {
      var _element$ref$current2 = element.ref.current,
          name = _element$ref$current2.name,
          value = _element$ref$current2.value;
      return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, name, value));
    }

    return result;
  }, {});
  return formData;
};
/* Apply 'touched' state to the elements */

export var triggerOnTouchErrors = function triggerOnTouchErrors(automatedElements) {
  automatedElements.forEach(function (element) {
    triggerOnSubmitValidation(element);
  });
};
/* Trigger stateClear for each input */

export var triggerStateClear = function triggerStateClear(elements) {
  elements.forEach(function (element) {
    triggerClearState(element);
  });
};
/* Trigger componentHasError state check */

export var componentsHaveValidationError = function componentsHaveValidationError(elements) {
  return elements.some(function (child) {
    return elementHasValidationError(child);
  });
};