import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cx } from '../utils';
import { useLibNSTranslation } from '../utils/i18nUtils';
import { AutomatedValidation, validate } from '../Validation';
import { Browse } from '../Icons';
import { FileUploadProps } from '../types';
import { Label } from '../Label';

const FileUpload = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      supportedFileTypes = [],
      additionalText = '',
      disabled = false,
      file = null,
      id = '',
      label = '',
      onChange = () => {},
      required = false,
      uploadText = '',
      validation = false,
    }: FileUploadProps,
    ref
  ) => {
    const { t, i18n } = useLibNSTranslation();

    const [fileName, setFileName] = useState<any>(file?.name);
    const [isTouched, setIsTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const touchedRef = useRef(false);
    const errorRef = useRef<false | string>(false);

    /* -----> Utils <----- */
    function updateTouched(touched: boolean) {
      setIsTouched(touched);
      touchedRef.current = touched;
    }

    function updateError(error: false | string) {
      setErrorMessage(error);
      errorRef.current = error;
    }

    const componentHasError = (checkRefs = false) => {
      if (checkRefs) {
        return (
          (required &&
            touchedRef.current &&
            !inputRef.current?.files?.length) ||
          (errorRef.current && inputRef.current?.files?.length)
        );
      }

      return (
        (required && isTouched && !inputRef.current?.files?.length) ||
        (errorMessage && inputRef.current?.files?.length)
      );
    };

    /* -----> Handlers <----- */
    const onUpload = e => {
      const newFile = e.target?.files[0];
      setFileName(newFile?.name);

      const validationError = validate(e.target?.files, validation, {}); // TODO: Add list of predefined validations
      updateError(validationError);
      updateTouched(true);
      __triggerFormValidation(id);

      onChange(newFile);
    };

    const onBlurHandler = () => {
      updateTouched(true);
      __triggerFormValidation(id);
    };

    /* -----> Effects <----- */

    useImperativeHandle(ref, () => ({
      name: id,
      value: inputRef.current?.files,
      clearState: () => {
        updateTouched(false);
        setFileName(null);
        // @ts-ignore Setting the value to "" doesn't work for all browsers to reset the input
        inputRef.current.value = null;
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    useEffect(() => {
      if (file) {
        setFileName(file?.name);
      }
    }, [file]);

    /* -----> View <----- */
    /* Format the list file according to locale */
    const SupportedTypeText = t('fileUpload.supportedTypes', {
      fileTypes: Intl?.ListFormat
        ? new Intl.ListFormat(i18n.language, {
            style: 'long',
            type: 'conjunction',
          }).format(supportedFileTypes)
        : supportedFileTypes.join(', '),
    });

    return (
      <div className="file-upload-container">
        <Label content={label} htmlFor={id} required={required} />
        <div className="tau-file-upload">
          <input
            accept={supportedFileTypes.join(',')}
            data-testid={`file-upload-${id}`}
            disabled={disabled}
            id={id || 'file-upload'}
            type="file"
            onChange={onUpload}
            ref={inputRef}
            onBlur={onBlurHandler}
          />
          <label
            htmlFor={id || 'file-upload'}
            className={cx(
              'input',
              { disabled },
              { hasError: componentHasError() }
            )}
          >
            {fileName}
            <div className="label">
              <span className="text">
                <span className="uploadText">
                <Browse className='browse-icon' fill='#1374EF' />
                  {uploadText || t('fileUpload.browse')}
                </span>
              </span>
            </div>
          </label>
        </div>
        <AutomatedValidation
          errorMessage={errorMessage}
          isTouched={isTouched}
          required={required}
          value={inputRef.current?.files}
        >
          {supportedFileTypes.length ? (
            <p className="supported-types">{`${SupportedTypeText} ${additionalText}`}</p>
          ) : null}
        </AutomatedValidation>
      </div>
    );
  }
);

FileUpload.defaultProps = {
  __hasRef: true,
};

export default FileUpload;
