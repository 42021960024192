function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/* eslint-disable react-hooks/rules-of-hooks, no-underscore-dangle */

/* eslint no-use-before-define: ["error", { "functions": false }] */
import { cloneElement, useRef } from 'react';
export var ARDefaultOptions = {
  conditionalElementKey: 'show-element-when',
  disableDataGatheringKey: 'disable-data-gathering',
  triggerValidationKey: '__triggerFormValidation'
};
/* -----> Helpers <----- */

/* Checks the current element if its conditionally rendered and should be rendered. */

var shouldRenderElement = function shouldRenderElement(isCND, condition) {
  return isCND ? condition : true;
};
/* Checks if the current element should be rendered based on its and its parents conditions. */


var renderCheck = function renderCheck(currentElement, parentElement) {
  var isCND = currentElement.isCND,
      condition = currentElement.condition;
  var parentIsCND = parentElement.parentIsCND,
      parentCondition = parentElement.parentCondition;
  var renderCurrent = shouldRenderElement(isCND, condition);
  var renderParent = shouldRenderElement(parentIsCND, parentCondition);
  return renderCurrent && renderParent;
};
/**
 * Checks if the element supports Ref() automation (__hasRef),
 * applies it (if it doesn't already have one) and a callback to trigger Form validation.
 *
 * @param {React.ReactNode} element - React Element object
 * @param {string} key - A key prop that will be added to distinguish the Element
 * @param {function} triggerValidation - A callback to notify the Form to perform a validation
 * @returns {React.ReactNode} - React Element with applied useRef()
 */


var useReactRef = function useReactRef(element, key, triggerValidation) {
  var automatedProps = {
    key: key
  };

  if (element.props.__hasRef) {
    if (element.props.required || element.props.validation) {
      automatedProps[ARDefaultOptions.triggerValidationKey] = triggerValidation;
    }

    if (element.ref === null) automatedProps.ref = useRef(null);
  }

  return automatedProps;
};
/* -----> useAutomatedRefs <----- */

/**
 * Configuration options for the hooks logic
 *
 * @typedef { Object } AutomatedRefsOptions
 * @property { string } conditionalElementKey - The prop name which will be used as condition parameter
 */

/**
 * React elements with applied ref() and filtered elements with custom ref() values.
 *
 * @typedef { Object } AutomatedElements
 * @property { object.<React.ReactNode | React.ReactNode[]>  } elementsWithRefs - The passed React Element/s with refs.
 * @property { Array.<React.ReactNode[] | []> } automatedComponentsList - List with components that have custom ref return values.
 * @property { string.<React.ReactNode> } automatedComponentsObject - An object with each component from {@link AutomatedElements automatedComponentsList} assigned to its "id" as key. Used for quick access to each component with custom ref.
 */

/**
 * Automatically assigns a Ref to any elements that have the semantic "_hasRef" prop and don't have a Ref assigned to them already.
 * Used to access internal automation methods and properties.
 *
 * @param { React.ReactNode | React.ReactNode[] } reactElements Elements passed as "children" to a React Component or the return value of React.createElement.
 * @param { AutomatedRefsOptions } options Configuration options for the hooks logic
 * @returns { AutomatedElements } the elements passed with applied ref() and a list of filtered elements with custom ref() values.
 */


var useAutomatedRefs = function useAutomatedRefs(reactElements, triggerValidation) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ARDefaultOptions;
  var cndKey = options.conditionalElementKey;
  /* Direct access to automated components (no need to traverse the tree again) */

  var automatedComponentsList = [];

  function automateElement(_ref, _ref2) {
    var _element$props;

    var element = _ref.element,
        _ref$key = _ref.key,
        key = _ref$key === void 0 ? 'tau-child-key' : _ref$key;
    var parentIsCND = _ref2.parentIsCND,
        parentCondition = _ref2.parentCondition;
    var props = useReactRef(element, key, triggerValidation);
    var renderCondition = element.props[cndKey];
    var isCND = renderCondition !== undefined;
    /* Complex element - apply the same logic to children */

    if (element !== null && element !== void 0 && (_element$props = element.props) !== null && _element$props !== void 0 && _element$props.children) {
      props.children = autoApplyRefs(element.props.children, {
        parentIsCND: isCND,
        parentCondition: renderCondition
      });
    }

    var elementShouldBeRendered = renderCheck({
      isCND: isCND,
      condition: renderCondition
    }, {
      parentIsCND: parentIsCND,
      parentCondition: parentCondition
    });
    /* Check if the element is conditionally rendered and passes its condition check */

    if (elementShouldBeRendered) {
      var elementWithRef = cloneElement(element, props);
      /* If it's an automated component, keep track of it */

      if (props.ref) {
        automatedComponentsList.push(elementWithRef);
      }

      return elementWithRef;
    }

    return null;
  }

  function autoApplyRefs(elements) {
    var renderConditions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      parentIsCND: false,
      parentCondition: false
    };

    /* Single or multiple React Elements */
    if (_typeof(elements) === 'object') {
      /* Multiple React Elements */
      if (Array.isArray(elements)) {
        return elements.reduce(function (elementArray, currentElement, idx) {
          /* null check */
          if (currentElement) {
            var _automatedElement = automateElement({
              element: currentElement,
              key: idx
            }, renderConditions);

            if (_automatedElement) {
              return [].concat(_toConsumableArray(elementArray), [_automatedElement]);
            }
          }

          return _toConsumableArray(elementArray);
        }, []);
      }
      /* Single React Element */


      var automatedElement = automateElement({
        element: elements
      }, renderConditions);
      return automatedElement;
    }
    /* Low level content (usually Text Content) */


    return elements;
  }

  var elementsWithRefs = autoApplyRefs(reactElements);
  var automatedComponentsObject = automatedComponentsList.reduce(function (result, el) {
    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, el.props.id, el));
  }, {});
  return {
    elementsWithRefs: elementsWithRefs,
    automatedComponentsList: automatedComponentsList,
    automatedComponentsObject: automatedComponentsObject
  };
};

export default useAutomatedRefs;