function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint dot-notation: off, no-underscore-dangle: off */
import { every, omit } from 'lodash';
/* private helper functions start */

var _getTreeNodePathArray = function _getTreeNodePathArray(node) {
  var nodePathArray = node.path.split('[').slice(1).map(function (stringNumber) {
    return Number(stringNumber.split(']')[0]);
  });
  return nodePathArray;
};

var _hasChildren = function _hasChildren(node) {
  return typeof node.children !== 'undefined' && node.children.length > 0;
};

var _updateNestedTree = function _updateNestedTree(treeArray, key, value) {
  var updateNestedTreeArray = function updateNestedTreeArray(array) {
    array.forEach(function (node, index) {
      var _objectSpread2;

      var updatedNode = key === 'checked' && value === true ? _objectSpread(_objectSpread({}, node), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, key, value), _defineProperty(_objectSpread2, "partial", false), _objectSpread2)) : _objectSpread(_objectSpread({}, node), {}, _defineProperty({}, key, value));
      array.splice(index, 1, updatedNode);

      if (_hasChildren(node)) {
        updateNestedTreeArray(node.children);
      }
    });
  };

  updateNestedTreeArray(treeArray);
  return treeArray;
};
/* private helper functions end */
// utility method to assign object paths.
// this path can be used with lodash.get or similar
// to retrieve a deeply nested object


export var assignObjectPaths = function assignObjectPaths(obj, stack) {
  var isArray = Array.isArray(obj);
  Object.keys(obj).forEach(function (k) {
    var node = obj[k];
    var key = isArray ? "[".concat(k, "]") : k;

    if (_typeof(node) === 'object') {
      node.path = stack ? "".concat(stack, ".").concat(key) : key;
      assignObjectPaths(node, node.path);
    }
  });
};
export var updateNestedTreeParents = function updateNestedTreeParents(treeArray, key, value) {
  var updateNestedTreeArray = function updateNestedTreeArray(array) {
    array.forEach(function (node, index) {
      if (_hasChildren(node)) {
        var updatedNode = _objectSpread(_objectSpread({}, node), {}, _defineProperty({}, key, value));

        array.splice(index, 1, updatedNode);
        updateNestedTreeArray(node.children);
      }
    });
  };

  updateNestedTreeArray(treeArray);
  return treeArray;
};
export var updateTreeDataKeyValues = function updateTreeDataKeyValues(treeArray, keyValueUpdates) {
  var updateTreeDataArray = function updateTreeDataArray(array) {
    array.forEach(function (node, index) {
      var updatedNode = _objectSpread(_objectSpread({}, node), keyValueUpdates);

      array.splice(index, 1, updatedNode);

      if (_hasChildren(node)) {
        updateTreeDataArray(node.children);
      }
    });
  };

  updateTreeDataArray(treeArray);
  return treeArray;
};
export var determineInitiallySelectedNodes = function determineInitiallySelectedNodes(data) {
  var initiallySelectedNodes = [];

  var findSelectedNodes = function findSelectedNodes(array) {
    array.forEach(function (node) {
      if (node.checked === true) {
        initiallySelectedNodes.push(node);
      } else if (_hasChildren(node) && every(node.children, function (childNode) {
        return childNode.checked;
      })) {
        initiallySelectedNodes.push(node);
      } else if (_hasChildren(node)) {
        findSelectedNodes(node.children);
      }
    });
  };

  findSelectedNodes(data);
  return initiallySelectedNodes;
};
export var filterData = function filterData(data, isExpanded) {
  var displayDataFormat = (data === null || data === void 0 ? void 0 : data.length) === 1 && data[0].children ? data[0].children : data;

  if (isExpanded) {
    updateNestedTreeParents(displayDataFormat, 'expanded', true);
  }

  return displayDataFormat;
};
export var updateOnNodeToggles = function updateOnNodeToggles(currentNode, data) {
  var dataCopy = data.slice();
  var currentDepth = 0;

  var currentNodePathArray = _getTreeNodePathArray(currentNode);

  var updateTreeData = function updateTreeData(treeArray) {
    var nodePathIndex = currentNodePathArray[currentDepth];

    if (currentDepth === currentNode['_depth']) {
      var currentNodeCopy = omit(currentNode, ['children']);

      var updatedNode = _objectSpread(_objectSpread({}, treeArray[nodePathIndex]), currentNodeCopy);

      treeArray.splice(nodePathIndex, 1, updatedNode);
      return treeArray;
    }

    currentDepth += 1;
    updateTreeData(treeArray[nodePathIndex].children);
    return treeArray;
  };

  updateTreeData(dataCopy);
  return dataCopy;
};
export var updateTreeNode = function updateTreeNode(currentNode, data) {
  var currentDepth = 0;

  var currentNodePathArray = _getTreeNodePathArray(currentNode);

  var dataCopy = data.slice();

  var updateTreeData = function updateTreeData(treeArray) {
    var nodePathIndex = currentNodePathArray[currentDepth];

    if (currentDepth === currentNode['_depth']) {
      var currentNodeCopy = omit(currentNode, ['children']);
      var updatedChildren = [];

      if (_hasChildren(treeArray[nodePathIndex])) {
        updatedChildren = _updateNestedTree(treeArray[nodePathIndex].children, 'checked', currentNode.checked);
      }

      var updatedNode = updatedChildren.length > 0 ? _objectSpread(_objectSpread(_objectSpread({}, treeArray[nodePathIndex]), currentNodeCopy), {}, {
        children: updatedChildren
      }) : _objectSpread(_objectSpread({}, treeArray[nodePathIndex]), currentNodeCopy);
      treeArray.splice(nodePathIndex, 1, updatedNode);
      return treeArray;
    }

    var nodeAncestor = treeArray[nodePathIndex];
    var isCurrentNodeParent = nodeAncestor['_id'] === currentNode['_parent']; // ensures currentNode ancestor becomes partial when currentNode when unchecked

    if (!currentNode.checked && !isCurrentNodeParent) {
      var _updatedNode = _objectSpread(_objectSpread({}, nodeAncestor), {}, {
        partial: true,
        checked: false
      });

      treeArray.splice(nodePathIndex, 1, _updatedNode);
      currentDepth += 1;
      updateTreeData(nodeAncestor.children);
    }

    if (isCurrentNodeParent) {
      var flattenChildIsCheckedArray = nodeAncestor.children.map(function (child) {
        if (child.value === currentNode.value && child.label === currentNode.label) {
          return currentNode.checked;
        }

        return child.checked || false;
      });
      var isPartial = !every(flattenChildIsCheckedArray, function (isChildNodeChecked) {
        return isChildNodeChecked;
      }); // i.e not every child is checked

      var isEmpty = every(flattenChildIsCheckedArray, function (isChildNodeChecked) {
        return !isChildNodeChecked;
      }); // i.e. every child is not checked

      var _updatedNode2 = _objectSpread(_objectSpread({}, nodeAncestor), {}, {
        partial: isPartial,
        checked: !isEmpty && !isPartial
      });

      treeArray.splice(nodePathIndex, 1, _updatedNode2);
      currentDepth += 1;
      updateTreeData(nodeAncestor.children);
    }

    if (currentDepth !== currentNode['_depth']) {
      currentDepth += 1;
      updateTreeData(nodeAncestor === null || nodeAncestor === void 0 ? void 0 : nodeAncestor.children);
    }

    return treeArray;
  };

  updateTreeData(dataCopy);
  return dataCopy;
};
export var updateHiddenTagClassNames = function updateHiddenTagClassNames(data, selectedNodes) {
  var tagLimit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var showTagLimit = arguments.length > 3 ? arguments[3] : undefined;
  var dataCopy = data.slice();
  var flattenNodeValues = selectedNodes.map(function (node) {
    return node.value;
  });

  var updateNestedData = function updateNestedData(dataArray) {
    dataArray.forEach(function (node, index) {
      var isIncluded = flattenNodeValues.includes(node.value);
      var shownNodesArray = flattenNodeValues.slice(0, tagLimit);

      var updatedNode = _objectSpread(_objectSpread({}, node), {}, {
        tagClassName: isIncluded && !shownNodesArray.includes(node.value) && showTagLimit ? 'hidden-tag' : ''
      });

      dataArray.splice(index, 1, updatedNode);

      if (_hasChildren(node)) {
        updateNestedData(node.children);
      }
    });
  };

  updateNestedData(dataCopy);
  return dataCopy;
};
export var updateTreeDataBySearchTerm = function updateTreeDataBySearchTerm(treeData, searchTerm) {
  var dataCopy = treeData.slice();

  var updateNestedData = function updateNestedData(dataArray) {
    return dataArray.forEach(function (node, index) {
      var _node$label;

      var matchesSearchTermOrEmptySearch = ((_node$label = node.label) === null || _node$label === void 0 ? void 0 : _node$label.toLowerCase().includes(searchTerm.toLowerCase())) || searchTerm === '';
      var isPlusMoreNode = node.className === 'plus-more-node';

      var updatedNode = _objectSpread(_objectSpread({}, node), {}, {
        className: matchesSearchTermOrEmptySearch && !isPlusMoreNode ? '' : 'hidden-dropdown-li'
      });

      dataArray.splice(index, 1, updatedNode);

      if (_hasChildren(node)) {
        updateNestedData(node.children);
      }
    });
  };

  updateNestedData(dataCopy);
  return dataCopy;
};