import * as React from 'react';
import { Spinner } from '../Spinner';
import { cx } from '../utils';
import { ButtonProps } from '../types';

function Button({
  block = false,
  children = null,
  className = '',
  disabled = false,
  onClick = () => {},
  reference = null,
  size = 'large',
  spinning = false,
  theme = 'light',
  type = 'button',
  ...buttonElementProps
}: ButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e);
    // @ts-ignore
    document.activeElement.blur();
  };

  const hasSize = theme !== 'none' && theme !== 'text';

  const classes = cx(
    className,
    hasSize && `tau-button size-${size}`,
    `theme-${theme}`,
    block && 'block',
    spinning && 'spinning'
  );

  return (
    <button
      className={classes}
      disabled={disabled}
      type={type}
      {...buttonElementProps}
      onClick={handleClick}
      ref={reference}
    >
      {children}
      {spinning && (
        <Spinner
          isButton
          theme={theme === 'light' ? 'dark' : 'light'}
          showBackground={false}
        />
      )}
    </button>
  );
}

export default Button;
