import React, { ReactNode, createContext, useState } from 'react';
import {
  Context,
  MainState,
  MappedConfiguration,
  ModalState,
  QCReason,
  QueryObject,
} from '../../types/EnhancedSearch';
import {
  defaultMainState,
  defaultModalState,
  defaultQueryState,
} from '../constants';

export const EnhancedSearchContext = createContext<Context | null>(null);

export const ESContextProvider = ({ children }: { children: ReactNode }) => {
  const [configuration, setConfiguration] =
    useState<MappedConfiguration | null>(null);
  const [modalState, setModalState] = useState<ModalState>(defaultModalState);
  const [mainState, setMainState] = useState<MainState>(defaultMainState);
  const [query, setQuery] = useState<QueryObject>(defaultQueryState);
  const [queryChangeReason, setQueryChangeReason] =
    useState<QCReason>('FILTER_CHANGE');

  /* -----> Utils <----- */
  const handleFetchError = (error: MainState['error']) => {
    setMainState({
      ...mainState,
      pageLoading: false,
      searchLoading: false,
      error,
    });
  };

  const updateQuery = (
    newQuery: QueryObject,
    queryChangeReason: QCReason = 'FILTER_CHANGE'
  ) => {
    setQueryChangeReason(queryChangeReason);
    setQuery(newQuery);
  };

  /* -----> Context Values <----- */
  const contextContent: Context = {
    configuration,
    setConfiguration,
    mainState,
    setMainState,
    modalState,
    setModalState,
    query,
    setQuery: updateQuery,
    queryChangeReason,
    handleFetchError,
  };

  return (
    <EnhancedSearchContext.Provider value={contextContent}>
      {children}
    </EnhancedSearchContext.Provider>
  );
};
