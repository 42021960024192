import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TestContext } from '../Contexts';
import { usePermissionCheck, useRoleCheck, useRoleTypeCheck } from '../Hooks';

function MockAccessControlledToggle(_ref) {
  var children = _ref.children,
      allowedRoles = _ref.allowedRoles,
      allowedRoleTypes = _ref.allowedRoleTypes,
      deniedRoles = _ref.deniedRoles,
      allowedPermissions = _ref.allowedPermissions,
      requiredCompanySettings = _ref.requiredCompanySettings,
      requiredSystemSettings = _ref.requiredSystemSettings,
      requiredFeature = _ref.requiredFeature,
      requiredCondition = _ref.requiredCondition,
      disabledComponent = _ref.disabledComponent;

  var _useContext = useContext(TestContext),
      enabledFeatures = _useContext.enabledFeatures,
      enabledSystemSettings = _useContext.enabledSystemSettings,
      enabledCompanySettings = _useContext.enabledCompanySettings;

  var roleEnabled = useRoleCheck(allowedRoles, deniedRoles);
  var roleTypeEnabled = useRoleTypeCheck(allowedRoleTypes);
  var permissionsEnabled = usePermissionCheck(allowedPermissions);
  var featureEnabled = !requiredFeature || (enabledFeatures === null || enabledFeatures === void 0 ? void 0 : enabledFeatures.includes(requiredFeature));
  var settingsEnabled = requiredSystemSettings.every(function (systemSetting) {
    return enabledSystemSettings === null || enabledSystemSettings === void 0 ? void 0 : enabledSystemSettings.includes(systemSetting);
  }) && requiredCompanySettings.every(function (companySetting) {
    return enabledCompanySettings === null || enabledCompanySettings === void 0 ? void 0 : enabledCompanySettings.includes(companySetting);
  });

  if (roleEnabled && roleTypeEnabled && permissionsEnabled && featureEnabled && settingsEnabled && requiredCondition) {
    return children;
  }

  if (disabledComponent) {
    return disabledComponent;
  }

  return null;
}

MockAccessControlledToggle.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  allowedRoleTypes: PropTypes.arrayOf(PropTypes.string),
  deniedRoles: PropTypes.arrayOf(PropTypes.string),
  allowedPermissions: PropTypes.arrayOf(PropTypes.string),
  requiredCompanySettings: PropTypes.arrayOf(PropTypes.string),
  requiredCondition: PropTypes.bool,
  requiredFeature: PropTypes.string,
  requiredSystemSettings: PropTypes.arrayOf(PropTypes.string),
  disabledComponent: PropTypes.element
};
MockAccessControlledToggle.defaultProps = {
  allowedRoles: [],
  allowedRoleTypes: [],
  deniedRoles: [],
  allowedPermissions: [],
  requiredCompanySettings: [],
  requiredSystemSettings: [],
  requiredCondition: true,
  requiredFeature: null,
  disabledComponent: null
};
export default MockAccessControlledToggle;