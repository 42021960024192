import React, { forwardRef } from 'react';
import { Select } from '../Select/Select';
import { SelectGroupsProps } from '../types';

const SelectGroups = forwardRef(
  ({ options, enableFixedOrder, ...selectOptions }: SelectGroupsProps, ref) => {
    const groupLabelPositions = {};

    /* Sorts the GROUPS alphabetically */
    const sortedGroups = Object.keys(options)
      .sort((a, b) => a.localeCompare(b))
      .reduce((result, group) => ({ ...result, [group]: options[group] }), {});

    /* Creates the OPTIONS */
    const formatted = Object.keys(sortedGroups).reduce((result, groupName) => {
      const groupOptions = options[groupName].map(option => ({
        ...option,
        group: groupName,
      }));

      /* Determines the position of the group label */
      groupLabelPositions[result.length] = groupName;

      /* Alpha sorting by group */
      const alphaSortedGO = groupOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      /* Fixed Order sorting by group */
      if (enableFixedOrder) {
        const fixedOrderGO = alphaSortedGO.filter(op => op.fixedOrder);
        const sortedFixedOrderGO = fixedOrderGO.sort(
          (a, b) => a.fixedOrder - b.fixedOrder
        );
        const nonFixedOrderGo = alphaSortedGO.filter(op => !op.fixedOrder);

        return [...result, ...sortedFixedOrderGO, ...nonFixedOrderGo];
      }

      return [...result, ...alphaSortedGO];
    }, []);

    return (
      <Select
        ref={ref}
        options={formatted}
        multiple={false}
        {...selectOptions}
        enableFixedOrder={false}
      />
    );
  }
);

SelectGroups.defaultProps = {
  __hasRef: true,
};

export default SelectGroups;
