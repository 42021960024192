import React, { isValidElement } from 'react';
import { cx } from '../utils';
import InfoTooltip from '../Tooltip/InfoTooltip';
import { LabelProps } from '../types';
import { ContentObject } from '../types/Label';
import { useLabelObject } from './utils';

export default function Label({
  content,
  htmlFor = '',
  required = false,
  requiredAsterisk = false,
}: LabelProps) {
  if (!content) return null;
  if (isValidElement(content)) return content;

  const { hasTooltip, labelProps, text, tooltipProps, tooltipText } =
    useLabelObject(content as string | ContentObject);

  return (
    <label
      {...(htmlFor ? { htmlFor } : {})}
      className={cx('field-label', {
        [`for-${htmlFor}`]: htmlFor,
        required,
        'required-asterisk': requiredAsterisk,
        'has-tooltip': hasTooltip,
      })}
      {...labelProps}
    >
      {!hasTooltip ? (
        text
      ) : (
        <>
          <span className="label-text">{text}</span>
          <InfoTooltip
            id={`${htmlFor}-text`}
            align="top"
            theme="blank"
            {...tooltipProps}
          >
            {tooltipText}
          </InfoTooltip>
        </>
      )}
    </label>
  );
}
