import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
/**
 * Returns a stable debounced function for a potentially unstable input function, allowing
 * debouncing of prop functions or any other function that could change on any given render.
 *
 * Debouncing delays execution of a function for a given time after the last invocation.
 * For more info on debounce see {@link https://lodash.com/docs/4.17.15#debounce }
 *
 * @param {function} func The function being debounced
 * @param {number} debounceTime The time to debounce
 * @returns {function} A debounced function.
 */

export var useDebounce = function useDebounce(func, debounceTime) {
  var functionRef = useRef(func);
  useEffect(function () {
    functionRef.current = func;
  }, [func]);
  return useMemo(function () {
    return debounce(function () {
      if (typeof functionRef.current === 'function') {
        return functionRef.current.apply(functionRef, arguments);
      }

      return undefined;
    }, debounceTime);
  }, [debounceTime]);
};