import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cx } from '../utils';
import { getInputStyles } from '../Text';
import {
  applySelectedOptions,
  buildOptionsObject,
  sortFixedOptions,
  registerClosedSelectHandlers,
  registerOpenSelectHandlers,
} from './utils';
import { Button, Clickable } from '../Button';
import { Caret } from '../Icons';
import Option from './Option';
import { useLibNSTranslation } from '../utils/i18nUtils';
import { AutomatedValidation, validate } from '../Validation';
import { SelectProps, OptionT } from '../types';
import { Label } from '../Label';

const TAB_KEY_CODE = 9;
const ESC_KEY_CODE = 27;

const SelectV2 = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      applyFullHeight = false,
      className = '',
      disableBulkSelection = false,
      disabled = false,
      disabledOptionsText = '',
      disableSearch = false,
      id,
      initiallyOpen = false,
      label = '',
      multiple = false,
      noSearchResultsLabel = '',
      onChange = null,
      onTextSearchChange = null,
      options = [],
      permaOpen = false,
      placeholder = '',
      required = false,
      searchPlaceholder = '',
      showMoreText = false,
      tagLimit = null,
      validation = false,
      value = '',
    }: SelectProps,
    ref
  ) => {
    const { t } = useLibNSTranslation();

    const [loadedOptions, setLoadedOptions] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState(
      applySelectedOptions(value, options)
    );
    const [selectedObject, setSelectedObject] = useState(
      multiple ? buildOptionsObject(value) : {}
    );
    const [search, setSearch] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);

    const [isOpen, setIsOpen] = useState(initiallyOpen || permaOpen);
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [hasFullHeight, setHasFullHeight] = useState<false | string>(false);
    const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);

    const isEmpty = selectedOptions.length === 0;
    const isExpanded = (isOpen && initiallyOpen) || isOpen;
    const hasTags = multiple && Boolean(tagLimit);

    /* >> Refs << */
    const touchedRef = useRef(false);
    const hasBeenOpenedRef = useRef(false);
    const errorRef = useRef<false | string>(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    /* >> Options << */
    const Options = useMemo(() => {
      let hasFixedOrder = false;

      const filteredOptions = loadedOptions.reduce((result, option) => {
        const { label, value } = option;

        const isOptionSelected = selectedObject[value];
        const matchesSearch = search.length
          ? label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          : true;

        if (!hasFixedOrder && option.fixedOrder) hasFixedOrder = true;
        if (!matchesSearch) return result; // The option does not match the search criteria
        if (multiple && !tagLimit && isOptionSelected) return result; // The option is already selected

        return [...result, option];
      }, []);

      const sortedOptions = hasFixedOrder
        ? sortFixedOptions(filteredOptions)
        : filteredOptions;

      return sortedOptions;
    }, [loadedOptions, search, selectedObject, multiple, tagLimit]);

    /* >> Select Groups << */
    const groupLabels = useMemo(
      () =>
        Options[0]?.group
          ? Options.reduce((groups, option, index) => {
              const groupName = option.group;

              if (groups[groupName] === undefined) {
                return { ...groups, [groupName]: index };
              }
              return groups;
            }, {})
          : {},
      [Options]
    );

    /* -----> Utils <----- */
    function updateTouched(touched) {
      setIsTouched(touched);
      touchedRef.current = touched;
    }

    function updateHasBeenOpened(opened: boolean) {
      setHasBeenOpened(opened);
      hasBeenOpenedRef.current = opened;
    }

    function updateError(error) {
      setErrorMessage(error);
      errorRef.current = error;
    }

    const updateSelectedOptions = (newData: OptionT[]) => {
      const newValues = newData.map(it => it.value);

      setSelectedOptions(newData);
      if (onChange) onChange(multiple ? newValues : newData[0].value);

      const validationError = validate(newValues, validation, {}); // TODO: Add list of predefined validations
      updateError(validationError);
      __triggerFormValidation(id);
    };

    const updateSelectedObject = (updatedOption: string) => {
      setSelectedObject(currentOptions => ({
        ...currentOptions,
        [updatedOption]: !currentOptions[updatedOption],
      }));
    };

    const componentHasError = (checkRefs = false) => {
      if (checkRefs) {
        return (
          (required &&
            isEmpty &&
            touchedRef.current &&
            hasBeenOpenedRef.current) ||
          (errorRef.current && isEmpty)
        );
      }

      return (
        (required && isEmpty && isTouched && hasBeenOpened) ||
        (errorMessage && isEmpty)
      );
    };

    const open = () => {
      if (!disabled && Options?.length) {
        setSearch('');
        setIsOpen(true);
        setIsFocused(true);
        updateHasBeenOpened(true);
        searchRef.current?.focus();
      }
    };

    const close = () => {
      if (!permaOpen) {
        setSearch('');
        setIsOpen(false);
        setActiveIndex(0);
        updateTouched(true);
        if (selectedOptions.length === 0) __triggerFormValidation(id);
      }
    };

    /* -----> Handlers <----- */
    /* Selecting an Option */
    const onOptionClickHandler = (selectedOption: OptionT) => {
      updateTouched(true);
      updateHasBeenOpened(true);
      searchRef.current?.focus();

      if (!multiple) {
        setIsOpen(permaOpen);
        updateSelectedOptions([selectedOption]);
      } else {
        if (Options.length === 1) {
          setSearch('');
          setIsOpen(permaOpen);
        }

        const { value: selectedValue } = selectedOption;
        const newSelectedOptions = selectedObject[selectedValue] // Is the option currently selected?
          ? selectedOptions.reduce(
              (res, it) => (it.value !== selectedValue ? [...res, it] : res),
              []
            )
          : selectedOptions?.concat(selectedOption) || [selectedOption];

        updateSelectedOptions(newSelectedOptions);
        updateSelectedObject(selectedValue);
      }
    };

    /* Deselecting an options from X in the tag/pill */
    const onSelectedItemClick = (item: OptionT, event) => {
      event.stopPropagation();

      if (!multiple) {
        open();
      } else {
        const removedSelected = selectedOptions.reduce(
          (acc, it) => (it.value !== item.value ? [...acc, it] : acc),
          []
        );

        updateTouched(true);
        updateHasBeenOpened(true);
        updateSelectedOptions(removedSelected);
        updateSelectedObject(item.value);
      }
    };

    const onSelectAllHandler = () => {
      const allOptionValues = options.reduce(
        (all, current) => (current.disabled ? all : [...all, current]),
        []
      );

      setActiveIndex(0);
      updateTouched(true);
      setIsOpen(permaOpen);
      updateHasBeenOpened(true);
      updateSelectedOptions(allOptionValues);
      setSelectedObject(
        allOptionValues.reduce(
          (all, option) => ({ ...all, [option.value]: true }),
          {}
        )
      );
    };

    const onDeselectAllHandler = () => {
      setActiveIndex(0);
      updateTouched(true);
      setSelectedObject({});
      updateHasBeenOpened(true);
      updateSelectedOptions([]);
    };

    /* >> Search << */
    const onSearchChange = event => {
      setSearch(event.target.value);
      if (onTextSearchChange) onTextSearchChange(event.target.value);
      setActiveIndex(0);
    };

    const onSearchKeyDown = e => {
      if (isOpen && e.keyCode === TAB_KEY_CODE) {
        e.preventDefault();
        close();
      }
    };

    /* -----> Effects <----- */
    useEffect(() => {
      const onClickHandler = event => {
        if (!selectRef.current?.contains(event.target)) {
          setIsFocused(false);
          close();
        }
      };
      const handleKeydown = event => event.keyCode === ESC_KEY_CODE && close();

      window.addEventListener('mousedown', onClickHandler); // Closes the options when clicking outside
      document.body.addEventListener('keydown', handleKeydown); // Closes the options when pressing Esc

      return () => {
        window.removeEventListener('mousedown', onClickHandler);
        document.body.removeEventListener('keydown', handleKeydown);
      };
    }, []);

    /* Applies the handlers used for keyboard navigation */
    // @ts-ignore-line
    useEffect(() => {
      if (isOpen && applyFullHeight) {
        setHasFullHeight(`${optionsRef.current?.offsetHeight}px`);
      }

      if (isFocused) {
        if (isOpen) {
          return registerOpenSelectHandlers({
            close,
            Options,
            searchRef,
            activeIndex,
            setActiveIndex,
            onOptionClickHandler,
          });
        } else {
          return registerClosedSelectHandlers({
            isOpen,
            listRef,
            searchRef,
            setIsOpen,
            setIsFocused,
          });
        }
      }
    }, [isOpen, isFocused, activeIndex, selectedOptions, Options]);

    /* Focuses options when navigating with the keyboard */
    useEffect(() => {
      if (listRef.current?.childNodes?.length) {
        const focusedOption = listRef.current.querySelector('.option.focused');
        if (focusedOption) (focusedOption as HTMLButtonElement).focus();
      }
    }, [activeIndex]);

    /* Handles dynamic options change & re-evaluates the selected items */
    useEffect(() => {
      const currentOptions = JSON.stringify(loadedOptions);
      const newOptions = JSON.stringify(options);

      if (currentOptions !== newOptions) {
        setLoadedOptions(options);

        const stateValues = selectedOptions.map(it => it.value);
        const currentValues = Array.from(new Set(stateValues.concat(value)));
        setSelectedOptions(applySelectedOptions(currentValues, options));
        if (multiple) setSelectedObject(buildOptionsObject(currentValues));
      }
    }, [options]);

    useImperativeHandle(ref, () => ({
      name: id,
      value: !multiple
        ? selectedOptions[0]?.value
        : selectedOptions?.map(it => it.value),
      clearState: () => {
        updateError(false);
        updateTouched(false);
        setSelectedOptions([]);
        updateHasBeenOpened(false);
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
        updateHasBeenOpened(true);
      },
    }));

    return (
      <div
        className={cx('select-wrapper', 'v2', id, {
          'has-error': componentHasError(),
        })}
      >
        <Label content={label} htmlFor={id} required={required} />
        <div
          style={
            isOpen && applyFullHeight
              ? { marginBottom: hasFullHeight as string }
              : {}
          }
          className={cx('tau-select', 'tau-input', className, {
            open: isExpanded,
            disabled,
            searchable: !disableSearch,
          })}
          ref={selectRef}
        >
          {Options.length > 0 && (
            <Button
              theme="none"
              className={cx('icon', isExpanded ? 'up' : 'down')}
              onClick={isOpen ? close : open}
              tabIndex={-1}
            >
              <Caret />
            </Button>
          )}
          <Clickable
            aria-controls={`${id}-select`}
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            className={cx('tau-select-trigger', {
              'has-value': !isEmpty,
              'has-options': Options.length > 0,
              multiple,
            })}
            data-testid="select-trigger"
            onClick={isOpen ? close : open}
            style={{
              ...getInputStyles({ disabled, focus: isOpen, hover: false }),
              boxShadow: isOpen ? '4px 8px 4px rgba(0, 0, 0, 0.05)' : 'none',
            }}
            onFocus={() => setIsFocused(true)}
            role="combobox"
            tabIndex={0}
          >
            {/* >> Selected Options << */}
            <div className="placeholder">
              {isEmpty ? (
                <span>{placeholder || t('select.default.placeholder')}</span>
              ) : (
                selectedOptions.map((item, index) => {
                  /* Selected items on top of input */
                  if (tagLimit && index > tagLimit) return null;

                  return (
                    <Button
                      className={cx('item', { error: errorMessage })}
                      data-testid={`selected-item-${item.value}`}
                      data-value={item.value}
                      key={item.value}
                      onClick={e => onSelectedItemClick(item, e)}
                      tabIndex={-1}
                      theme="none"
                    >
                      {item.selectedLabel || item.label}
                      {multiple && <span>x</span>}
                    </Button>
                  );
                })
              )}
              {
                /* Show plus more tag if checked values exceed tag limit */
                tagLimit && !isEmpty && selectedOptions.length > tagLimit ? (
                  <span className="plus-more-item">
                    {t('treeSelect.plusMore', {
                      hiddenTagNumber: selectedOptions.length - tagLimit,
                    })}
                  </span>
                ) : null
              }
            </div>
            {/* >> Search << */}
            {!disableSearch ? (
              <div className="search">
                <input
                  data-testid="select-search"
                  onChange={onSearchChange}
                  onClick={e => e.stopPropagation()}
                  onKeyDown={onSearchKeyDown}
                  placeholder={
                    searchPlaceholder || t('select.default.searchPlaceholder')
                  }
                  ref={searchRef}
                  tabIndex={0}
                  type="text"
                  value={search}
                />
              </div>
            ) : null}
          </Clickable>
          <div
            aria-expanded={isOpen}
            data-testid="options-container"
            className={cx('tau-select-options', {
              open: isExpanded,
              padded: disableSearch,
            })}
            ref={optionsRef}
            style={{
              ...getInputStyles({ disabled, focus: isOpen, hover: false }),
            }}
          >
            <div
              className="container"
              role="listbox"
              ref={listRef}
              tabIndex={-1}
            >
              {multiple && !disableBulkSelection ? (
                <div className="deselect-select-all">
                  <Button
                    data-testid="select-all-button"
                    disabled={
                      !isEmpty &&
                      selectedOptions.length ===
                        options.filter(option => !option.disabled).length
                    }
                    onClick={onSelectAllHandler}
                    tabIndex={-1}
                    theme="text"
                  >
                    {t('listView.selectAll')}
                  </Button>
                  <span className="divider" />
                  <Button
                    className="deselect-all"
                    data-testid="deselect-all-button"
                    disabled={isEmpty}
                    onClick={onDeselectAllHandler}
                    theme="text"
                    tabIndex={-1}
                  >
                    {t('listView.deselectAll')}
                  </Button>
                </div>
              ) : null}
              {/* >> Options << */}
              {Options.length === 0 ? (
                <div className="option">
                  {noSearchResultsLabel || t('select.default.noSearchResults')}
                </div>
              ) : (
                Options.map((item, index) => (
                  <Fragment key={item.value}>
                    {/* ?>> Group Label <<? */}
                    {groupLabels[item.group] === index ? (
                      <div
                        className="option-group-name"
                        key={item.group}
                        data-testid="option-group"
                      >
                        {item.group}
                      </div>
                    ) : null}
                    <Option
                      key={item.value}
                      data={item}
                      activeIndex={activeIndex}
                      checked={selectedObject[item.value]}
                      disabledText={item.disabledText || disabledOptionsText}
                      index={index}
                      onClick={val => {
                        if (!disabled && !val?.disabled && val) {
                          onOptionClickHandler(val);
                        }
                      }}
                      search={search}
                      showCheckbox={hasTags}
                    />
                  </Fragment>
                ))
              )}
              {/* >> search for more... << */}
              {showMoreText ? (
                <div className={cx('search-more-text', { pad: hasTags })}>
                  {typeof showMoreText === 'boolean'
                    ? `(${t('enhancedSearch.filters.searchForMore')})`
                    : showMoreText}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <AutomatedValidation
          errorMessage={errorMessage}
          isTouched={isTouched && hasBeenOpened}
          required={required}
          value={selectedOptions}
        />
      </div>
    );
  }
);

SelectV2.defaultProps = {
  __hasRef: true,
};

export default SelectV2;
