import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Arrow,
  Button,
  Field,
  FieldErrorMap,
  Spinner,
  Text,
  toast,
  useSendContext,
  useTimer,
} from 'taulia-ui';
import { Trans, useTranslation } from 'react-i18next';
import validate from 'validate.js';
import { removeWhitespace } from '../../utils';
import Captcha from '../../components/Captcha';

function ResetPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState({ value: '', error: null });
  const [resetComplete, setResetComplete] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState('');
  const navigate = useNavigate();

  const { complete: timerComplete, doStart: doStartTimer } = useTimer(
    2000,
    true
  );

  const {
    loading: initLoading,
    error: initResetError,
    success: initResetSuccess,
    doSend: doInitResetPassword,
  } = useSendContext('POST', '/api/reset-password/init');

  const initResetPassword = () => {
    doInitResetPassword({
      captchaResponse,
      emailAddress: email.value,
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    const errors = validate(
      { email: email.value },
      {
        email: {
          email: { message: '^email' },
          presence: { message: '^presence' },
        },
      }
    );
    if (errors) {
      setEmail({
        value: email.value,
        error: errors.email || null,
      });
    } else {
      initResetPassword();
    }
  };

  const setCaptcha = val => {
    setCaptchaStatus(true);
    setCaptchaResponse(val);
  };

  const onCaptchaExpired = () => {
    setCaptchaStatus(false);
    setCaptchaResponse(null);
  };

  useEffect(() => {
    doStartTimer();
  }, []);

  useEffect(() => {
    if (initResetSuccess) {
      setResetComplete(true);
    }
  }, [initResetSuccess]);

  useEffect(() => {
    if (initResetError) {
      if (initResetError.status === 403) {
        toast.error(t('resetPassword.error.ssoUser'));
      } else {
        toast.error(t('standard.error'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initResetError]);

  const completedState = resetComplete ? 'complete' : 'incomplete';

  return (
    <div className="login-container login-container-position">
      {!timerComplete ? (
        <div className="spinner-align">
          <Spinner />
        </div>
      ) : (
        ''
      )}
      <h2 className="auth-header">
        {t(`resetPassword.${completedState}Header`)}
      </h2>
      <p className="reset-password-info-text">
        <Trans
          i18nKey={`resetPassword.${completedState}InfoText`}
          components={[<strong />]}
          values={{ email: email.value }}
        />
      </p>
      {resetComplete ? (
        <Button
          type="submit"
          theme="primary"
          block
          onClick={() => navigate('/login')}
        >
          {t('loginPage.loginAction')}
        </Button>
      ) : (
        <form data-testid="reset-password-form" onSubmit={onSubmit}>
          <Field validationState={email.error ? 'error' : null}>
            <Text
              autoCapitalize="off"
              id="email"
              name="email"
              onChange={event =>
                setEmail({
                  value: removeWhitespace(event.target.value),
                  error: null,
                })
              }
              placeholder={t('loginPage.email')}
              validationState={email.error ? 'error' : null}
              value={email.value}
            />
            <FieldErrorMap>{t('loginPage.error.invalidEmail')}</FieldErrorMap>
          </Field>
          <Captcha onVerify={setCaptcha} onExpire={onCaptchaExpired} />
          <div className="button-navigation-container">
            <Button
              data-testid="return-login"
              onClick={() => navigate('/login')}
              theme="text"
            >
              <Arrow />
              {t('resetPassword.returnLogin')}
            </Button>
            <Button
              className="reset-password-button"
              data-testid="reset-password"
              disabled={
                !!(!email.value.length || email.error || !captchaStatus)
              }
              theme="primary"
              type="submit"
              spinning={initLoading}
            >
              {t('resetPassword.resetPassword')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ResetPassword;
