function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useEffect, useState } from 'react';
var easeFunctions = {
  linear: function linear(n) {
    return n;
  },
  // https://easings.net/
  easeOutSine: function easeOutSine(n) {
    return Math.sin(n * Math.PI / 2);
  },
  easeOutCubic: function easeOutCubic(n) {
    return 1 - Math.pow(1 - n, 3);
  },
  easeOutQuint: function easeOutQuint(n) {
    return 1 - Math.pow(1 - n, 5);
  }
}; // https://usehooks.com/useAnimation/

var useAnimationTimer = function useAnimationTimer(animate, duration, delay) {
  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      elapsed = _useState2[0],
      setTime = _useState2[1];

  useEffect(function () {
    var animationFrame;
    var timerStop;
    var start;
    var timerDelay;

    var loop = function loop() {
      // eslint-disable-next-line no-use-before-define
      animationFrame = requestAnimationFrame(onFrame);
    };

    var onFrame = function onFrame() {
      setTime(Date.now() - start);
      loop();
    };

    var onStart = function onStart() {
      timerStop = setTimeout(function () {
        cancelAnimationFrame(animationFrame);
        setTime(Date.now() - start);
      }, duration);
      start = Date.now();
      loop();
    };

    if (animate) {
      timerDelay = setTimeout(onStart, delay);
    }

    return function () {
      clearTimeout(timerStop);
      clearTimeout(timerDelay);
      cancelAnimationFrame(animationFrame);
    };
  }, [animate, duration, delay]);
  return elapsed;
};

var useAnimation = function useAnimation() {
  var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var easeFunction = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : easeFunctions.linear;
  var elapsed = useAnimationTimer(animate, duration, delay);
  var n = Math.min(1, elapsed / duration);
  return easeFunction(n);
};

export { useAnimation, easeFunctions };