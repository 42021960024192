export default {
  'cs-CZ': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Při přihlašování k vašemu účtu došlo k potížím. Pokud budou problémy přetrvávat, zkuste to znovu později nebo nás kontaktujte na stránce <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Vítejte na portálu {{ portalCompany }}',
      },
    },
  },
  de: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Beim Anmeldevorgang ist ein Fehler aufgetreten. Sollte das Problem fortbestehen, versuchen Sie es bitte später erneut oder kontaktieren Sie <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Willkommen im  Lieferantenportal',
      },
    },
  },
  'el-GR': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Συναντήσαμε ένα πρόβλημα με τη σύνδεσή σας. Εάν συνεχίσετε να αντιμετωπίζετε προβλήματα, δοκιμάστε ξανά αργότερα ή επικοινωνήστε με τη διεύθυνση <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Καλώς ήρθατε στην πύλη {{ portalCompany }}',
      },
    },
  },
  'en-GB': {
    bundled: {},
  },
  'en-US': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'We encountered an issue with logging you in. If you continue to have problems, please try again later or contact <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Welcome to the {{ portalCompany }} portal',
      },
    },
  },
  es: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Hemos encontrado un problema al tratar de iniciar sesión con tu información. Si continúas teniendo problemas, por favor inténtalo más tarde o ponte en contacto con <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Bienvenido al portal {{ portalCompany }}',
      },
    },
  },
  'fr-CA': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Nous avons rencontré un problème lors de votre connexion. Si le problème persiste, veuillez réessayer plus tard ou contactez <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Bienvenue sur le portail {{ portalCompany }}',
      },
    },
  },
  fr: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Nous avons rencontré un problème lors de la connexion. Si le problème persiste, veuillez réessayer plus tard ou entrer en contact avec <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Bienvenue sur le portail {{ portalCompany }}',
      },
    },
  },
  it: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            "Problema durante l'accesso. Se continui a riscontrare problemi, riprova in seguito oppure contatta <0>support.taulia.com</0>",
        },
      },
      head: {
        title: 'Benvenuto nel portale {{ portalCompany }}',
      },
    },
  },
  'ja-JP': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'ログイン処理中に問題が発生しました。問題が解決しない場合は、後ほど再度お試しいただくか、<0>support.taulia.com</0> までご連絡ください。',
        },
      },
      head: {
        title: '{{ portalCompany }} ポータルへようこそ',
      },
    },
  },
  'ko-KO': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            '로그인 중 문제가 발생했습니다. 문제가 지속될 경우 나중에 다시 시도하시거나 <0>support.taulia.com</0>에서 문의해주십시오.',
        },
      },
      head: {
        title: '{{ portalCompany }} 포털에 환영합니다',
      },
    },
  },
  nl: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Er is een probleem opgetreden bij het aanmelden. Als u problemen blijft ondervinden, probeert u het opnieuw of neemt u contact met ons op via <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Welkom bij het portaal {{ portalCompany }}',
      },
    },
  },
  'no-NO': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Det oppsto et problem ved innlogging. Hvis du fortsatt har problemer, prøv igjen senere eller kontakt <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Velkommen til {{ portalCompany }} portalen',
      },
    },
  },
  'pl-PL': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Podczas logowania wystąpił błąd. Jeśli problem będzie nadal występował, spróbuj ponownie później lub skontaktuj się z pomocą <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Witamy w portalu {{ portalCompany }}',
      },
    },
  },
  pt: {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Encontrámos um problema ao iniciar sessão. Se o problema continuar, tente novamente mais tarde ou contacte <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Bem-vindo ao Portal {{ portalCompany }}',
      },
    },
  },
  'sl-SI': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Med postopkom prijave smo naleteli na težavo. Če se težave ponavljajo, poskusite znova pozneje ali se obrnite na <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Dobrodošli v portalu {{ portalCompany }}',
      },
    },
  },
  'sv-SE': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            'Det uppstod problem med inloggningen. Om problemen fortsätter, försök igen senare eller kontakta <0>support.taulia.com</0>',
        },
      },
      head: {
        title: 'Välkommen till {{ portalCompany }}-portalen',
      },
    },
  },
  'zh-CN': {
    bundled: {
      loginPage: {
        error: {
          unknownError:
            '您在登录时出现了一个问题。若持续出错请稍后重试或联系<0>support.taulia.com</0>',
        },
      },
      head: {
        title: '欢迎进入{{ portalCompany }} 门户',
      },
    },
  },
};
