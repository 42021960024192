import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthTypeContext = React.createContext();

function AuthTypeProvider({ children }) {
  const [authType, setAuthType] = useState();
  const [redirectUrl, setRedirectUrl] = useState('');
  const contextValue = useMemo(
    () => ({ authType, redirectUrl, setAuthType, setRedirectUrl }),
    [authType, redirectUrl, setAuthType, setRedirectUrl]
  );

  return (
    <AuthTypeContext.Provider value={contextValue}>
      {children}
    </AuthTypeContext.Provider>
  );
}

AuthTypeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthTypeProvider;
