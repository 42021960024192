function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useCallback, useContext, useEffect, useReducer, useState, useMemo } from 'react';
import { TestContext } from '../Contexts';

var incrementerReducer = function incrementerReducer(state) {
  return state + 1;
};
/**
 * @deprecated use useFetchMockV2 (exported as useFetchMock)
 */


var useFetchMock = function useFetchMock(url) {
  var arg1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var arg2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var lazy = typeof arg1 !== 'function' ? arg1 : arg2;

  var _useReducer = useReducer(incrementerReducer, 0),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      inc = _useReducer2[0],
      incrementer = _useReducer2[1];

  var _useContext = useContext(TestContext),
      useFetchMocks = _useContext.useFetchMocks;

  var thisMock = useMemo(function () {
    var mock;

    try {
      mock = useFetchMocks[url];
    } catch (e) {
      mock = {};
    }

    return mock;
  }, [useFetchMocks, url]);
  var doFetch = useCallback(function (params) {
    incrementer();

    if (thisMock !== null && thisMock !== void 0 && thisMock.doFetch) {
      thisMock.doFetch(params);
    }
  }, [incrementer, thisMock]);

  var createFetchObject = function createFetchObject() {
    var mock = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _objectSpread(_objectSpread({
      loading: false,
      data: {},
      error: null
    }, mock), {}, {
      doFetch: doFetch
    });
  };

  var _useState = useState(createFetchObject({
    loading: !lazy,
    data: null
  })),
      _useState2 = _slicedToArray(_useState, 2),
      responseObj = _useState2[0],
      setResponseObj = _useState2[1];

  useEffect(function () {
    if (!lazy) {
      incrementer();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [url]);
  useEffect(function () {
    if (inc > 0) {
      setResponseObj(createFetchObject(thisMock));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [inc]);
  return responseObj;
};
/**
 * @deprecated use useFetchMockV2 (exported as useFetchMock)
 */


var useSendMock = function useSendMock(method, url) {
  var _useReducer3 = useReducer(incrementerReducer, 0),
      _useReducer4 = _slicedToArray(_useReducer3, 2),
      inc = _useReducer4[0],
      incrementer = _useReducer4[1];

  var _useContext2 = useContext(TestContext),
      _useContext2$useSendM = _useContext2.useSendMocks,
      useSendMocks = _useContext2$useSendM === void 0 ? {} : _useContext2$useSendM;

  var thisMock = useMemo(function () {
    var mock;

    try {
      mock = useSendMocks[url][method];
    } catch (e) {
      mock = {
        success: true,
        complete: true,
        data: {}
      };
    }

    return mock;
  }, [useSendMocks, url, method]);
  var doSend = useCallback(function (payload) {
    incrementer();

    if (thisMock !== null && thisMock !== void 0 && thisMock.doSend) {
      thisMock.doSend(payload);
    }
  }, [incrementer, thisMock]);

  var createSendObject = function createSendObject() {
    var mock = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _objectSpread(_objectSpread({
      loading: false,
      data: null,
      complete: false,
      success: false,
      error: null
    }, mock), {}, {
      doSend: doSend
    });
  };

  var _useState3 = useState(createSendObject()),
      _useState4 = _slicedToArray(_useState3, 2),
      responseObj = _useState4[0],
      setResponseObj = _useState4[1];

  useEffect(function () {
    if (inc > 0) {
      setResponseObj(createSendObject(thisMock));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */

  }, [inc]);
  return responseObj;
};

export { useFetchMock, useSendMock };