/* 
The keyword 'this' refers to the element that is calling the insertAdjacentElement function.

```
const searchInput = document?.querySelector(
  `div.${uid.current} > div.dropdown > div.dropdown-content > input`
);
searchInput.insertAdjacentElement('afterend', deselectSelectContainer);
```

In the example above, the keyword 'this' refers to the searchInput element.
*/
export function insertAdjacentElement(position, element) {
  var parent = this.parentNode; // Add element to DOM based on the position argument

  switch (position.toLowerCase()) {
    case 'beforeend':
      this.appendChild(element);
      break;

    case 'afterbegin':
      this.insertBefore(element, this.firstChild);
      break;

    case 'beforebegin':
      parent.insertBefore(element, this);
      break;

    case 'afterend':
      parent.insertBefore(element, this.nextElementSibling);
      break;

    default:
      break;
  }
} // Check if the browser supports the insertAdjacentElement method

if (!('insertAdjacentElement' in document.createElementNS('http://www.w3.org/1999/xhtml', '_'))) {
  HTMLElement.prototype.insertAdjacentElement = insertAdjacentElement; // Set the correct prototype for the new method

  Object.setPrototypeOf(HTMLElement.prototype.insertAdjacentElement, HTMLElement.prototype);
}