import React, { useMemo } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { RequiredValidationProps } from '../../types';

export default function RequiredValidation({
  isTouched,
  touchValidation = true,
  value,
  required,
}: RequiredValidationProps) {
  const { t } = useLibNSTranslation();

  const hasValue = useMemo(() => {
    if (value) {
      switch (typeof value) {
        case 'object':
          if (value) {
            /* Array */
            if (Array.isArray(value)) {
              return value.length;
            }
            /* Object */
            return Object.keys(value).length;
          }
          return false;
        case 'boolean':
          return value;
        default:
          /* Everything else */
          return String(value);
      }
    }

    return false;
  }, [value]);

  const CustomErrorMessage = useMemo(
    () => (typeof required === 'object' ? required.errorMessage : null),
    [required]
  );

  return required && !hasValue && (touchValidation ? isTouched : true) ? (
    <p className="validation-error --required">
      {CustomErrorMessage || t('input.required')}
    </p>
  ) : null;
}
