function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var SUPPORTED_LOCALES = ['cs-CZ', 'de', 'el-GR', 'en-US', 'en-GB', 'es', 'fr', 'fr-CA', 'it', 'ja-JP', 'ko-KO', 'nl', 'no-NO', 'pl-PL', 'pt', 'sl-SI', 'sv-SE', 'zh-CN'];
export default (function () {
  var nav = window.navigator;

  if (nav.languages) {
    for (var i = 0; i < nav.languages.length; i += 1) {
      var language = nav.languages[i];

      if (SUPPORTED_LOCALES.indexOf(language) > -1) {
        return language;
      }
    }
  }

  var browserLanguageKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']; // Support for other well known properties in browsers

  for (var _i = 0; _i < browserLanguageKeys.length; _i += 1) {
    var _language = nav[browserLanguageKeys[_i]];

    if (SUPPORTED_LOCALES.indexOf(_language) > -1) {
      return _language;
    }
  } // If still no language, check for main language by looking for language before hyphen


  if (nav.languages) {
    var _loop = function _loop(_i2) {
      var partialLocale = nav.languages[_i2].split('-')[0];

      var language = SUPPORTED_LOCALES.find(function (item) {
        return item.indexOf(partialLocale) === 0;
      });
      if (language) return {
        v: language
      };
    };

    for (var _i2 = 0; _i2 < nav.languages.length; _i2 += 1) {
      var _ret = _loop(_i2);

      if (_typeof(_ret) === "object") return _ret.v;
    }
  }

  return 'en-US';
});