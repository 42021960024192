import { useContext } from 'react';
import { EnhancedSearchContext } from '../context/EnhancedSearchContext';

const useESContext = () => {
  const context = useContext(EnhancedSearchContext);
  if (context === null) {
    throw new Error('useESContext() must be used within <ESContextProvider>!');
  }

  return context;
};

export default useESContext;
