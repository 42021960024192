import { GenericObject, Primitive } from '../../types';

export default function validate(
  value: Primitive | GenericObject | any[],
  userValidations: false | GenericObject,
  predefinedValidations?: GenericObject
): false | string {
  let error: false | string = false;
  const validationsArray = Object.keys(userValidations);

  for (let i = 0; i < validationsArray.length; i += 1) {
    if (error) {
      /* The previous iteration found an error -> break the loop */
      i = validationsArray.length;
    } else {
      const name = validationsArray[i];
      const validation: Primitive | GenericObject = userValidations[name];

      if (predefinedValidations?.[name]) {
        /* Predefined Validation */
        /* The validation logic is inside predefinedValidations, the condition and error message is inside userValidation  */
        const predefinedValidationObject = predefinedValidations[name];
        const predefinedValidator = predefinedValidationObject.validator;

        /* Default Error Message */
        if (
          typeof validation !== 'object' ||
          validation?.errorMessage === undefined
        ) {
          const currentValidation =
            typeof validation !== 'object' ? validation : validation.is;

          if (predefinedValidator(value, currentValidation)) {
            const { errorMessage } = predefinedValidationObject;

            if (typeof errorMessage === 'function') {
              /* Error message based on the current input & validation values */
              if (typeof validation !== 'object') {
                error = errorMessage(value, validation);
              } else {
                error = errorMessage(value, validation.is);
              }
            } else {
              error = errorMessage;
            }
          }
        } else {
          /* Custom Error Message */
          if (validation.is) {
            if (predefinedValidator(value, validation.is))
              if (typeof validation.errorMessage === 'function') {
                /* Error message based on the current input value */
                error = validation.errorMessage(value, validation.is);
              } else {
                error = validation.errorMessage;
              }
          } else {
            /* Incorrect structure or trying to use custom validation with the same name */
            if (validation.validator) {
              console.error(
                `There is already a predefined validation with the same name: ${name}`
              );
            } else {
              console.error(
                'Incorrect validation object structure. Should be { is: $conditionValue, errorMessage: $messageString }'
              );
            }
          }
        }
      } else {
        /* Custom Validation */
        /* Both the validation logic and error message are inside userValidations */
        if (typeof validation === 'object') {
          const { validator, errorMessage } = validation;
          if (validator(value)) {
            if (typeof errorMessage === 'string') {
              error = errorMessage; // Static error message
            } else {
              const dynamicError = errorMessage(value); // Error message with access to the input value
              error = dynamicError;
            }
          }
        } else {
          /* Passing a primitive value, when there is no predefined validation to handle it  */
          console.error(
            `There is no such predefined validation: ${name}. Pass a custom { validator, errorMessage }.`
          );
        }
      }
    }
  }

  return error;
}
