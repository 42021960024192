import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { getInputStyles } from '../Checkbox';
import { cx } from '../utils';
import { RadioProps } from '../types';

const Radio = forwardRef(
  (
    {
      __hasRef,
      checked = false,
      children = null,
      disabled = false,
      id = '',
      inline = false,
      validationState = null,
      ...radioProps
    }: RadioProps,
    ref
  ) => {
    const [focus, setFocus] = useState(false);
    const [hover, setHover] = useState(false);

    useImperativeHandle(ref, () => ({
      name: id,
      value: checked,
    }));

    /* -----> Handlers <----- */

    const onFocusHandler = () => {
      setFocus(true);
    };

    const onBlurHandler = () => {
      setFocus(false);
    };

    const onMouseOverHandler = () => {
      setHover(true);
    };

    const onMouseOutHandler = () => {
      setHover(false);
    };

    /* -----> View <----- */

    const style = useMemo(
      () =>
        getInputStyles({
          checked,
          disabled: disabled || validationState !== null,
          focus,
          hover,
        }),
      [checked, disabled, validationState, focus, hover]
    );

    return (
      <label
        data-testid="radio-label"
        className={cx(
          'tau-radio',
          'tau-input',
          { [`has-${validationState}`]: validationState },
          { inline, checked, disabled }
        )}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onMouseOut={onMouseOutHandler}
        onMouseOver={onMouseOverHandler}
      >
        <input
          type="radio"
          data-testid="radio"
          checked={checked}
          disabled={disabled}
          id={id}
          {...radioProps}
        />
        <span
          className="input"
          style={style.borderColor ? { borderColor: style.borderColor } : {}}
        >
          <span className="icon" style={style} />
        </span>
        <span className="text">{children}</span>
      </label>
    );
  }
);

export default Radio;

Radio.defaultProps = {
  __hasRef: true,
};
