import superagent from 'superagent';

const METHODS = ['GET', 'PUT', 'POST', 'DELETE'];

const createRequest = csrfConfig => (method, url) => {
  if (METHODS.indexOf(method) === -1) {
    throw new Error(
      `Unknown request method ${method}. Please use one of ${METHODS}`
    );
  }
  const requestObj = superagent[method.toLowerCase()](url);
  if (csrfConfig) {
    return requestObj.set(csrfConfig.csrfHeader, csrfConfig.csrfToken);
  }
  return requestObj;
};

export { createRequest };
