function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Branding } from '../Branding';
import { colors } from '../theme';
var POSITION_LEFT = 'left';
var POSITION_RIGHT = 'right';
var POSITION_TOP = 'top';
var POSITION_BOTTOM = 'bottom';
var POSITION_BOTTOM_LEFT = 'bottom left';
var POSITION_BOTTOM_RIGHT = 'bottom right';
var POSITION_TOP_LEFT = 'top left';
var POSITION_TOP_RIGHT = 'top right';
var THEME_PRIMARY = 'primary';
var THEME_BLANK = 'blank';
var THEME_DARK = 'dark';
var THEME_LIGHT = 'light';
var THEME_DANGER = 'danger';
var THEME_WARN = 'warn';
var THEME_SUCCESS = 'success';
var THEME_INFO = 'info';
var THEME_SELECT = 'select';
export var getPositionOrientation = function getPositionOrientation(tooltipPosition) {
  switch (tooltipPosition) {
    case POSITION_LEFT:
    case POSITION_RIGHT:
      return 'vertical';

    case POSITION_TOP:
    case POSITION_BOTTOM:
    case POSITION_BOTTOM_LEFT:
    case POSITION_BOTTOM_RIGHT:
    case POSITION_TOP_LEFT:
    case POSITION_TOP_RIGHT:
      return 'horizontal';

    default:
      return 'invalid';
  }
};
export var getPosition = function getPosition(tooltipPosition) {
  var _currentPosition;

  var currentPosition = (_currentPosition = {}, _defineProperty(_currentPosition, POSITION_TOP, {
    attachment: "bottom center"
  }), _defineProperty(_currentPosition, POSITION_BOTTOM, {
    attachment: "top center"
  }), _defineProperty(_currentPosition, POSITION_LEFT, {
    attachment: "middle right"
  }), _defineProperty(_currentPosition, POSITION_RIGHT, {
    attachment: "middle left"
  }), _defineProperty(_currentPosition, POSITION_BOTTOM_LEFT, {
    attachment: "top right",
    targetAttachment: 'bottom right'
  }), _defineProperty(_currentPosition, POSITION_BOTTOM_LEFT, {
    attachment: "top left",
    targetAttachment: 'bottom left'
  }), _defineProperty(_currentPosition, POSITION_BOTTOM_RIGHT, {
    attachment: "top right",
    targetAttachment: 'bottom right'
  }), _defineProperty(_currentPosition, POSITION_TOP_LEFT, {
    attachment: "bottom left",
    targetAttachment: 'top left'
  }), _defineProperty(_currentPosition, POSITION_TOP_RIGHT, {
    attachment: "bottom right",
    targetAttachment: 'top right'
  }), _defineProperty(_currentPosition, "default", {
    attachment: "middle right"
  }), _currentPosition);
  return currentPosition[tooltipPosition] || currentPosition.default;
};
export var getStyles = function getStyles(theme, align) {
  var _themesStyling;

  if (theme === 'none') {
    return {
      contentStyle: {
        backgroundColor: "".concat(colors.white),
        boxShadow: "2px 2px 5px 1px ".concat(colors['taulia-grey']),
        color: "".concat(colors.text),
        maxWidth: '100%',
        padding: '0',
        margin: '0'
      },
      arrowBehindStyle: {
        display: 'none'
      },
      arrowStyle: {
        display: 'none'
      }
    };
  }

  var themesStyling = (_themesStyling = {}, _defineProperty(_themesStyling, THEME_PRIMARY, {
    backgroundColor: Branding.fetchColor('primary').hex(),
    color: "".concat(colors.white),
    border: "1px ".concat(Branding.fetchColor('primary').hex(), " solid")
  }), _defineProperty(_themesStyling, THEME_DANGER, {
    backgroundColor: "".concat(colors['danger-background']),
    color: "".concat(colors['danger-text']),
    border: "".concat(colors['danger-border'])
  }), _defineProperty(_themesStyling, THEME_SUCCESS, {
    backgroundColor: "".concat(colors['success-background']),
    color: "".concat(colors['success-text']),
    border: "".concat(colors['success-border'])
  }), _defineProperty(_themesStyling, THEME_WARN, {
    backgroundColor: "".concat(colors['warn-background']),
    color: "".concat(colors['warn-text']),
    border: "".concat(colors['warn-border'])
  }), _defineProperty(_themesStyling, THEME_INFO, {
    backgroundColor: "".concat(colors['info-background']),
    color: "".concat(colors['info-text']),
    border: "".concat(colors['info-border'])
  }), _defineProperty(_themesStyling, THEME_LIGHT, {
    backgroundColor: "".concat(colors['taulia-grey']),
    color: "".concat(colors.text),
    border: "".concat(colors['taulia-grey'])
  }), _defineProperty(_themesStyling, THEME_SELECT, {
    backgroundColor: "".concat(colors.white),
    color: "".concat(colors.text),
    border: "1px ".concat(Branding.fetchColor('primary').hex(), " solid"),
    borderColor: "".concat(Branding.fetchColor('primary').hex())
  }), _defineProperty(_themesStyling, THEME_BLANK, {
    backgroundColor: "".concat(colors.white),
    color: "".concat(colors.text),
    padding: '10px',
    filter: "drop-shadow(2px 2px 5px ".concat(colors['taulia-grey'], ")"),
    border: "1px ".concat(colors['grey-border'], " solid"),
    borderColor: "".concat(colors['grey-border'])
  }), _defineProperty(_themesStyling, THEME_DARK, {
    backgroundColor: "".concat(colors['taulia-dark-grey']),
    color: "".concat(colors.white),
    border: "".concat(colors['taulia-dark-grey'])
  }), _defineProperty(_themesStyling, "default", {
    backgroundColor: "".concat(colors['taulia-dark-grey']),
    color: "".concat(colors.white),
    border: "".concat(colors['taulia-dark-grey'])
  }), _themesStyling);
  var currentThemeStyles = themesStyling[theme] || themesStyling.default;
  return {
    contentStyle: currentThemeStyles,
    arrowBehindStyle: _objectSpread(_objectSpread({}, getPositionOrientation(align) === 'horizontal' && {
      borderTopColor: currentThemeStyles.borderColor,
      borderBottomColor: currentThemeStyles.borderColor
    }), getPositionOrientation(align) === 'vertical' && {
      borderLeftColor: currentThemeStyles.borderColor,
      borderRightColor: currentThemeStyles.borderColor
    }),
    arrowStyle: _objectSpread(_objectSpread({}, getPositionOrientation(align) === 'horizontal' && {
      borderTopColor: currentThemeStyles.backgroundColor,
      borderBottomColor: currentThemeStyles.backgroundColor
    }), getPositionOrientation(align) === 'vertical' && {
      borderLeftColor: currentThemeStyles.backgroundColor,
      borderRightColor: currentThemeStyles.backgroundColor
    })
  };
};