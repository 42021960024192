/* >> String Type << */
export function isTextOnly(value: string) {
  const exp = /^[a-zA-Z\s]*$/;
  return exp.test(value);
}

export function isInteger(value: string) {
  const exp = /^-?[0-9]+$/;
  return exp.test(value);
}

export function isEmail(value: string) {
  // eslint-disable-next-line no-control-regex
  const exp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return exp.test(value);
}

/* Expects country code to be included */
export function isMobile(value: string) {
  const exp = /^\+[0-9\s]+$/;
  return exp.test(value);
}

/* >> String Structure << */
export function maxLength(value: string, maxLength: number) {
  return value.length >= maxLength;
}

export function maxLengthExc(value: string, maxLength: number) {
  return value.length > maxLength;
}

export function minLength(value: string, minLength: number) {
  return value.length <= minLength;
}

export function minLengthExc(value: string, minLength: number) {
  return value.length < minLength;
}

/* -----> Export Object <----- */
const stringValidations = {
  isTextOnly,
  isInteger,
  isEmail,
  isMobile,
  maxLength,
  maxLengthExc,
  minLength,
  minLengthExc,
};

export default stringValidations;
