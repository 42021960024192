import React, { useEffect, useState } from 'react';
import {
  Button,
  Field,
  FieldLabel,
  Link,
  Text,
  toast,
  useSendContext,
} from 'taulia-ui';
import { Trans, useTranslation } from 'react-i18next';
import { removeWhitespace } from '../../utils';

function RecoveryCodeLogin() {
  const [recoveryCode, setRecoveryCode] = useState('');
  const { t } = useTranslation();

  const { data, error, loading, doSend: doLogin } = useSendContext(
    'POST',
    '/api/login/recovery-code'
  );

  const login = () => {
    doLogin({ recoveryCode: removeWhitespace(recoveryCode) });
  };

  useEffect(() => {
    if (data && data.success && data.redirectUrl) {
      window.location = data.redirectUrl;
    } else if (data || error) {
      if (data && data.reason && data.reason === 'ACCOUNT_NOT_ACCESSIBLE') {
        toast.error(
          <Trans
            i18nKey="loginPage.error.accountNotAccessible.message"
            components={[
              <a
                href="/reset-password"
                target="_blank"
                rel="noopener noreferrer"
              />,
              <a
                href="http://support.taulia.com"
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
            values={{
              recoveryLink: t(
                'loginPage.error.accountNotAccessible.recoveryLink'
              ),
            }}
          />,
          { autoClose: 15000 }
        );
      } else {
        toast.error(t('authentication.recoveryLogin.error'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <div className="login-container">
      <h2 className="auth-header">
        {t('authentication.recoveryLogin.header')}
      </h2>
      <p className="centered">{t('authentication.recoveryLogin.infoText')}</p>
      <form
        onSubmit={event => {
          event.preventDefault();
          login();
        }}
      >
        <Field>
          <FieldLabel htmlFor="recovery-code">
            {t('authentication.recoveryLogin.recoveryCode')}
          </FieldLabel>
          <Link
            id="recovery-code-link"
            href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
            rel="noopener noreferrer"
            style={{ float: 'right' }}
            target="_blank"
          >
            {t('footer.help')}
          </Link>
          <Text
            aria-label="recovery-code-input"
            id="recovery-code"
            value={recoveryCode}
            onChange={e => setRecoveryCode(e.target.value)}
          />
        </Field>
        <Button
          disabled={!recoveryCode}
          type="submit"
          theme="primary"
          block
          spinning={loading || (data && data.redirectUrl && data.success)}
        >
          {t('loginPage.loginAction')}
        </Button>
      </form>
    </div>
  );
}

export default RecoveryCodeLogin;
