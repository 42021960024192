import React from 'react';
import { useDropzone } from 'react-dropzone';
import { MultiFileUploadProps } from '../types';
import { Button } from '../Button';
import { XIcon } from '../Icons';

function MultiFileUpload({
  buttonText = 'select a file',
  files = [],
  onAdd = () => null,
  onClick = () => null,
  onDelete = () => null,
  pText = 'Drag and drop attachments here or ',
}: MultiFileUploadProps) {
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior so file opener only opens on button click
    noClick: true,
    noKeyboard: true,
    onDrop: selectedFiles => {
      if (onAdd) {
        onAdd(selectedFiles);
      }
    },
  });

  const clipName = (name: string) => {
    const maxLength = 20;
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  };

  return (
    <div className="multi-file-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>
          {pText}
          <Button theme="text" onClick={open}>
            {buttonText}
          </Button>
        </p>
      </div>
      <ul>
        {files.map(file => {
          const name = file.name || file.fileName;
          const key = `${name} ${file.lastModified || file.attachmentId}`;

          return (
            <li key={key}>
              <Button onClick={() => onClick && onClick(file)} theme="none">
                {clipName(name!)}
              </Button>
              <Button onClick={() => onDelete && onDelete(file)} theme="none">
                <XIcon data-testid="X-button" />
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MultiFileUpload;
