export var defaultDateFormatLocales = ['cs-CZ', 'de', 'el-GR', 'en-US', 'nl', 'no-NO', 'pl-PL', 'sl-SI', 'zh-CN'];
export var determineDateRangePillWidth = function determineDateRangePillWidth(dateRangePillWidth, locale) {
  var disabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (locale === 'ko-KO') {
    if (disabled) {
      return dateRangePillWidth * 217 + 59;
    }

    return dateRangePillWidth * 240 + 59;
  }

  if (disabled) {
    return dateRangePillWidth * 207 + 59;
  }

  if (!defaultDateFormatLocales.includes(locale)) {
    return dateRangePillWidth * 227 + 59;
  }

  return dateRangePillWidth * 219 + 59;
};
export var localizeDateFormatString = function localizeDateFormatString(locale) {
  var formats = {
    'cs-CZ': 'd.M.yyyy',
    de: 'dd.MM.yyyy',
    'el-GR': 'd/M/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'en-US': 'M/d/yyyy',
    es: 'dd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    fr: 'dd/MM/yyyy',
    it: 'dd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ko-KO': 'yyyy. MM. dd',
    nl: 'd-M-yyyy',
    'no-NO': 'dd.MM.yyyy',
    'pl-PL': 'dd.MM.yyyy',
    pt: 'dd-MM-yyyy',
    'sl-SI': 'd.M.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'zh-CN': 'yyyy/M/d'
  };
  return formats[locale] || 'dd/MM/yyyy';
};