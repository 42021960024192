function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useEffect, useRef, useState } from 'react';
/**
 * A TimerObject is a stateful object represnting a timer.
 *
 * @typedef {Object} TimerObject
 * @property {boolean} complete - True of the timer duration has completed
 * @property {function} doStart - Function to start the timer. This is only useful for lazy timers.
 * @property {function} doReset - Restarts the timer. This can be used regardless of the complete state
 */

/**
 * The useTimer can be used to track time in a component or set timers in a component.
 *
 * The {@link TimerObject} that is returned is stored in state by the hook, and therefore it is not required for it or
 * its properties to be stored in state by the parent component. Any updates to the object and its properties will trigger a re-render.
 *
 * Timers can be initiated eagerly or lazily. Eager timers start counting down as soon as the hook is iniated. Lazy timers
 * will not start until {@link TimerObject.doStart} is called.
 *
 * @example
 * // Eager Timer
 * const { complete, doReset } = useTimer(10000)
 *
 * return (
 *  <>
 *    {complete && <div>Time's up!</div>}
 *    <Button onClick={() => doReset() }>Start Over</Button>
 *  </>
 *  )
 *
 * // Lazy Timer
 * const lazy = true;
 * const { complete, doStart, doReset } useTimer(10000, lazy);
 *
 * useEffect(() => { doStart(); }, [])
 *
 * @param {number} duration - The duration of the timer in milliseconds before it is marked as complete
 * @param {boolean} [lazy=false] - Assign true if the timer should not start eagerly
 * @returns {TimerObject} timerObject
 */

var useTimer = function useTimer(duration) {
  var lazy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var intervalId = useRef();
  var startTime = useRef();
  var isComplete = useRef();

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      incr = _useState2[0],
      setIncr = _useState2[1];

  var startIncr = lazy ? 1 : 0;

  var createTimerObj = function createTimerObj(complete) {
    return {
      complete: complete,
      doReset: function doReset() {
        startTime.current = Date.now();
      },
      doStart: function doStart() {
        setIncr(incr + 1);
        startTime.current = Date.now();
      }
    };
  };

  var _useState3 = useState(createTimerObj(false)),
      _useState4 = _slicedToArray(_useState3, 2),
      timerObj = _useState4[0],
      setTimerObj = _useState4[1];

  useEffect(function () {
    if (incr === startIncr) {
      startTime.current = Date.now();
      intervalId.current = setInterval(function () {
        var currentTime = Date.now();
        var isElapsed = currentTime - startTime.current > duration;

        if (isElapsed !== isComplete.current) {
          isComplete.current = isElapsed;
          setTimerObj(createTimerObj(isElapsed));
        }
      }, 500);
    }

    return function () {
      clearInterval(intervalId.current);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [incr]);
  return timerObj;
};

export { useTimer };