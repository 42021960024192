export const localeMap = {
  'el-GR': 'el',
  'en-GB': 'enGB',
  'en-US': 'enUS',
  'fr-CA': 'fr',
  'ja-JP': 'ja',
  'ko-KO': 'ko',
  'no-NO': 'nb',
  'pl-PL': 'pl',
  'sl-SI': 'sl',
  'sv-SE': 'sv',
  'zh-CN': 'zhCN',
};
