import qs from 'qs';
import { merge, isEqual } from 'lodash';
import { getI18n } from 'react-i18next';
import { libNSTranslate as t } from '../../utils/i18nUtils';
import { ActionsProps, ListViewProps } from '../../types/EnhancedSearch';
import { MANUAL_SEARCH_ACTION_TYPE } from '../constants';

/* Applies currently selected columns to the ListView */
export function configureListViewFieldColumns(
  { query, fields },
  excludedFieldsColumnsOnly
) {
  return fields
    .sort((a, b) => query.select.indexOf(a.name) - query.select.indexOf(b.name)) // sorts the configuration fields based on the current query
    .filter(field => !excludedFieldsColumnsOnly.includes(field.name)) // filters out the excluded fields
    .map(field =>
      merge(
        {
          key: field.name,
          type: field.type,
          sortable: field.sortable,
          visible: !!query.select.find(it => it === field.name),
          label: field.label,
          trueLabel: field.trueLabel,
          falseLabel: field.falseLabel,
          enumValues: field.enumValues,
          enumOptions: field.enumOptions,
          fixedOrder: field.fixedOrder,
          width: field.width,
        },
        field.column
      )
    );
}

function mergeFieldOverrides(fields, overrides) {
  const result = fields.map(currentField => {
    const field = { ...currentField };
    const fieldOverride = overrides[field.name];

    if (fieldOverride) {
      Object.keys(fieldOverride).forEach(key => {
        const override = fieldOverride[key];

        if (
          field.type === 'ENUM' &&
          fieldOverride.type !== 'ENUM' &&
          key === 'enumOptions'
        ) {
          /* Automatic mapping for ENUMs (List) -> Cross-match the list from the config and pass only matching values */
          if (Array.isArray(override)) {
            field[key] = field.enumValues.map(enumValue => {
              const matchingCustomValue = override.find(
                customEnum => customEnum.value === enumValue
              );

              return (
                matchingCustomValue || { value: enumValue, label: enumValue }
              );
            });
          }

          /* Custom mapping (Function) -> The passed callback does the mapping from outside */
          if (typeof override === 'function') {
            field[key] = override(field.enumValues);
          }
        } else if (typeof override === 'function') {
          /* Custom mapping (Function) -> The passed callback does the mapping from outside */
          field[key] = override(field[key]);
        } else {
          /* Primitive replacement */
          field[key] = override;
        }
      });
    }

    return field;
  });

  return result;
}

/* Map the current field configuration data to component usable data */
export function mapConfigurationFields(props, config) {
  const { excludedFields, fieldOverrides } = props;
  const configuration = { ...config };

  function tryToTranslate(key) {
    const i18next = getI18n();
    const translation = i18next.t(key);
    if (translation && translation.includes('fields.')) {
      return null;
    }
    return translation;
  }

  const eso = configuration.name || '';

  /* Filter out any excluded fields */
  configuration.fields = configuration.fields.filter(
    field => !excludedFields.includes(field.name)
  );

  /* Field Overrides */
  configuration.fields = mergeFieldOverrides(
    configuration.fields,
    fieldOverrides
  );

  configuration.fields = configuration.fields.map(currentField => {
    const field = { ...currentField };

    /* Make sure each field has a label */
    field.label =
      (field.label != null && field.label) ||
      tryToTranslate(`fields.${eso.toLowerCase()}.${field.name}.label`) ||
      tryToTranslate(`fields.${eso.toLowerCase()}.${field.name}`) ||
      tryToTranslate(`fields.${field.name}.label`) ||
      tryToTranslate(`fields.${field.name}`) ||
      field.name;

    /* For boolean fields, make sure we have a trueLabel and falseLabel */
    if (field.type === 'BOOLEAN') {
      if (field.trueLabel == null)
        field.trueLabel = tryToTranslate('filters.trueLabel');
      if (field.falseLabel == null)
        field.falseLabel = tryToTranslate('filters.falseLabel');
    }

    /* hide IS_NULL and NOT_NULL operators for NUMBER fields */
    if (field.type === 'NUMBER' || field.type === 'MONEY') {
      const hiddenOperators = ['IS_NULL', 'NOT_NULL'];
      field.supportedOperators = field.supportedOperators.filter(
        it => !hiddenOperators.includes(it)
      );
    }

    /* For enum fields, make sure we have a list of options, each with a value and label */
    if (field.enumValues && !field.enumOptions) {
      field.enumOptions = field.enumValues.map(value => ({
        value,
        label:
          tryToTranslate(
            `fields.${eso.toLowerCase()}.${field.name}.enumValues.${value}`
          ) ||
          tryToTranslate(`fields.${field.name}.enumValues.${value}`) ||
          value,
      }));
    }

    return field;
  });

  configuration.searchableFields = configuration.fields.filter(
    field => field.searchable
  );

  return configuration;
}

/* -----> Manual Search <----- */
export const applyManualSearchAction = (
  actions: ListViewProps['actions'],
  searchTrigger: () => void
): ActionsProps[] => {
  const manualSearchAction = {
    label: t('enhancedSearch.actions.search') as string,
    onClick: searchTrigger,
    allowEmptySelection: true,
  };

  const hasManualSearchOverride = actions.some(
    (act: ActionsProps) => act.type === MANUAL_SEARCH_ACTION_TYPE
  );

  if (hasManualSearchOverride) {
    const actionsWithOverriddenManualSearch = actions.map((act: ActionsProps) =>
      act.type === MANUAL_SEARCH_ACTION_TYPE
        ? { ...manualSearchAction, ...act }
        : act
    );

    return actionsWithOverriddenManualSearch;
  }

  const actionsWithDefaultManualSearch = [...actions, manualSearchAction];
  return actionsWithDefaultManualSearch;
};

/* -----> URI Parsing <----- */
/* Parses Objects as URL encoded params */
export function parseParams(params) {
  // qs returns booleans as strings (true vs 'true'); add custom decoder to get around this
  // https://github.com/ljharb/qs/issues/91#issuecomment-437926409
  // @ts-ignore
  const decoder = (str, decoderFunction, charset) => {
    const strWithoutPlus = str.replace(/\+/g, ' ');
    if (charset === 'iso-8859-1') {
      // unescape never throws, no try...catch needed:
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };
    if (str in keywords) {
      return keywords[str];
    }

    // utf-8
    try {
      const decoded: unknown = decodeURIComponent(strWithoutPlus);
      // Everything is parsed as strings, hence the check to use numbers when needed.
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(<number>decoded) ? Number(decoded) : decoded;
    } catch (e) {
      return strWithoutPlus;
    }
  };
  // arrayLimit tells qs what is the max amount in the array (eg. filters array)
  // default is 21 https://github.com/ljharb/qs#parsing-arrays
  const result = qs.parse(params.slice(1), { decoder, arrayLimit: 1000 });

  return result;
}
/* This is needed because qs parses empty strings to 0 and breaks the filtering */
const removeEmptyFilters = data =>
  data.filters?.map(filter => {
    if (filter.values?.length && filter.values[0] === '') {
      return { field: filter.field };
    }

    return filter;
  });

export function historyUpdate(newQuery, defaultQuery, location, navigate) {
  const filteredNewQuery = { ...newQuery };

  if (!isEqual(filteredNewQuery, defaultQuery)) {
    filteredNewQuery.filters = removeEmptyFilters(filteredNewQuery);

    const urlParams = qs.stringify(filteredNewQuery, {
      encode: true,
    });
    navigate(
      { pathname: location.pathname, search: `?${urlParams}` },
      { replace: true }
    );
  }
}
