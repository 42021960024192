function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { useCallback, useContext, useEffect, useReducer } from 'react';
import { debounce } from 'lodash';
import { RequestContext } from '../Contexts';
import useCachedRequest from './useCachedRequest';
import { removeFromCache } from '../utils/cacheUtils';
var HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

var getUrlWithParams = function getUrlWithParams(url, query) {
  if (query) {
    return "".concat(url, "?").concat(new URLSearchParams(query));
  }

  return url;
};

var purgeReducer = function purgeReducer(state, urlKey) {
  if (urlKey) {
    removeFromCache(urlKey);
    return false;
  }

  return true;
};

var requestReducer = function requestReducer(state) {
  var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [].concat(_toConsumableArray(state), [request]);
};

var responseReducer = function responseReducer(state, _ref) {
  var type = _ref.type,
      _ref$payload = _ref.payload,
      payload = _ref$payload === void 0 ? {} : _ref$payload;

  var _state = _slicedToArray(state, 2),
      currentIndex = _state[0],
      prevResponse = _state[1];

  var data = payload.data,
      error = payload.error,
      requestInfo = payload.requestInfo,
      _payload$requestIndex = payload.requestIndex,
      requestIndex = _payload$requestIndex === void 0 ? currentIndex : _payload$requestIndex;

  var resp = _objectSpread(_objectSpread({}, prevResponse), {}, {
    loading: false,
    success: true,
    complete: true,
    error: null
  });

  switch (type) {
    case 'loading':
      return [requestIndex, _objectSpread(_objectSpread({}, resp), {}, {
        loading: true,
        complete: false,
        success: false,
        error: null,
        requestInfo: _objectSpread(_objectSpread({}, requestInfo), {}, {
          success: false,
          complete: false
        })
      })];

    case 'successWithData':
      return [requestIndex + 1, _objectSpread(_objectSpread({}, resp), {}, {
        data: data,
        requestInfo: _objectSpread(_objectSpread({}, requestInfo), {}, {
          success: true,
          complete: true
        })
      })];

    case 'success':
      return [requestIndex + 1, _objectSpread(_objectSpread({}, resp), {}, {
        requestInfo: _objectSpread(_objectSpread({}, requestInfo), {}, {
          success: true,
          complete: true
        })
      })];

    case 'error':
      return [requestIndex + 1, _objectSpread(_objectSpread({}, resp), {}, {
        success: false,
        error: error,
        requestInfo: _objectSpread(_objectSpread({}, requestInfo), {}, {
          success: false,
          complete: true
        })
      })];

    default:
      return state;
  }
};
/**
 *
 * Options for enabling client caching of requests
 *
 * @category hooks/useFetch
 * @typedef {object} CacheOptions
 * @property {String} key a unique key to append to the url-key in localstorage. This is usually a user id to prevent cached data from multiple logins from colliding.
 * @property {Number} maxAge The maximum age a cached value is valid. After it expires, a new value will be fetched from the server.
 * @property {String} [appVersion = undefined] Allows cached values to be invalidated by a new version of the application.
 * @property {String} [sessionToken = undefined] Allows cached values to be invalidated by a new user session.
 */

/**
 *
 *  UseFetchOptions provide default options for {@link useFetch} requests. Default options are used for eager fetches and if no
 * options are provided in the doFetch() imperative function.
 *
 * @category hooks/useFetch
 * @typedef {object} UseFetchOptions
 * @property {string} [defaultMethod = GET] The default http method to use, defaults to GET.
 * @property {boolean} [lazy = false for GET, true for all others] Whether or not to perform an eager fetch (immediately after initial render) or wait for the doFetch() imperative function to be called.
 * Defaults to false (eager) when the default method is GET, and true (lazy) for all other methods.
 * @property {object} [defaultData = undefined] The default data object to send in the request body for POST, PUT, and PATCH requests.
 * @property {object} [defaultQuery = undefined] The default url param query to use.
 * @property {function} [request = undefined] The default request function to use. If not provided, useFetch will try to use the request from RequestContext.
 * @property {number} [debounceTime = undefined] An optional time interval limit (in milliseconds) for expensive requests. If set to 10000 after a successful request is executed
 * additional requests will be ignored for 10 seconds.
 * @property {CacheOptions} [cacheOptions = undefined] options for client caching of request responses. This is only used for GET requests.
 * @property {function} [defaultOnSuccess = undefined] a default function to be called when a fetch has successfully completed, with the response data as an argument
 * @property {function} [defaultOnError = undefined] a default function to be called on error, with the error as the argument
 * @property {boolean} [shouldSetDataFromDefaultMethodOnly = true] If true, the {@link FetchResult} data will only be
 * updated by requests using the default method. This can prevent empty POST or PUT responses overwriting contentful GET responses.
 * @property {boolean} [skipToLastRequest = false] When advancing the request queue, skip to the most recent request. This is useful for expensive requests result from type-ahead interactions
 * and will prevent a long queue of requests from being executed and instead jump to the last request.
 *
 */

/**
 * DoFetchOptions can be provided to the doFetch() imperative function to override the default options when initiating a new fetch
 *
 * @category hooks/useFetch
 * @typedef {object} DoFetchOptions
 * @property {String} [method = defaultMethod if provided in options or GET] The HTTP method to use for this request
 * @property {object} [query = defaultQuery if provided in  UseFetchOptions or undefined] A key value pair of query parameters to be appended to the URL for this request
 * @property {object} [data = defaultData if provided in  UseFetchOptions or undefined] Data to be sent to the server in the request body
 * @property {function} [onSuccess = defaultOnSuccess if provided in  UseFetchOptions or undefined] a function to be called when a fetch has successfully completed, with the response data as an argument
 * @property {function} [onError = defaultOnError if provided in  UseFetchOptions or undefined] a function to be called on error, with the error as the argument
 * @property {*} [* = undefined] Any other non-reserved properties can be included, and they will be returned in the {@link RequestInfo} object. This is
 * useful for updating a render with the results of a specific fetch.
 */

/**
 * Data about the current or last fetch request.
 *
 * @category hooks/useFetch
 * @typedef {object} RequestInfo
 * @property {String} url The url used for the request
 * @property {boolean} success If the request was successful
 * @property {boolean} complete If the request is complete
 * @property {object} options The {@link DoFetchOptions} provided to the doFetch() method when request was made
 */

/**
 * Returned from the hook with stateful values representing the current status or result of a fetch request.
 *
 * @category hooks/useFetch
 * @typedef {object} FetchResult
 * @property {boolean} loading Whether useFetch is waiting for request to finish
 * @property {boolean} error The error returned from the server
 * @property {boolean} complete Whether the current request is complete, either successfully or with error
 * @property {boolean} success Whether the current request was completed successfully
 * @property {object} data The data returned from the server. Defaults to undefined until a successful fetch has been made, so be
 * sure to use nullsafe operators when access properties and rendering in your components.
 * @property {RequestInfo} requestInfo The {@link RequestInfo} for the current or last request.
 * @property {function} doFetch The imperative function for initiating fetch requests. {@link DoFetchOptions} may be
 * provided, otherwise the defaults provided in {@link UseFetchOptions} will be used. doFetch is will never change, so it
 * is safe to include in a dependency array.
 * @property {function} doPurge An imperative function for removing cached data from local storage. The item will be removed
 * using the current URL and the key provided {@link CacheOptions} configuration to generate the local storage key.
 *
 */

/**
 * The useFetch hook fetches data from or sends data to a server and provides of stateful representations of those operations
 * for a component to use in the rendering cycle.
 *
 * @example
 * // ----------------------------------------------------------------------------------- //
 * // making an eager get request with defaults, this will initiate loading as soon as the component is rendered
 * const {loading, data, error, doFetch} = useFetch('/api/user')
 *
 * if (error) {
 *   return <ErrorMessage />
 * }
 *
 * if (loading) {
 *   return <Spinner />
 * }
 * return (<div>User Name: {data?.userName}</div>)
 *
 * // ----------------------------------------------------------------------------------- //
 * // sending data to the server and showing an error toast
 * const {loading, data, error, doFetch} = useFetch('/path/to/resource', 'POST')
 * ...
 * <Button loading={loading} onClick={(someData) => { doFetch({data: someData}) }}>Update Me!</Button>
 *
 * useEffect(() => {
 *   if (error) {
 *      toast.error('something happened')
 *   }
 * }, [error]}
 *
 * // ----------------------------------------------------------------------------------- //
 * // using useFetch as a C.R.U.D. service with a dynamic url
 *
 * const [selectedUserId, setSelectedUserI] = useState('123')
 *
 * const {data:userData, loading, doFetch, requestInfo} = useFetch('/api/users/${selectedUserId}')
 *
 * useEffect(() => {
 *   if(requestInfo?.complete && requestInfo?.success && requestInfo?.successMessage) {
 *     toast.success(requestInfo?.successMessage)
 *     doFetch()
 *   }
 * }, [requestInfo, doFetch])
 *
 * return (
 *  <Select onSelect={(selectedId) => setSelectedUserId(selectedId)}>Choose User</Select>
 *
 *  <h1>{userData?.fullName}</h1>
 *
 *  <Form> <(update form)> </Form>
 *
 *  <Button onclick={
 *      () => doFetch({method: 'PUT', data:{formdata}, successMessage: 'userData?.fullName was updated!'})
 *    }>
 *      Update
 *    </Button>
 *  <Button onclick={() => doFetch({method: 'DELETE'})} >Update</Button>
 * )
 *
 * // ----------------------------------------------------------------------------------- //
 * // using lazy useFetch with caching
 *
 * const {
 *  data,
 *  error,
 *  doFetch
 *  } = useFetch('/api/expensive/resource', { lazy:true, cacheOptions: { maxAge: 30, key:'123' } })
 *
 * useEffect(() => {
 *   doFetch();
 * }, [])
 *
 * @category hooks/useFetch
 * @param {string} url The url of the resource being fetched. This value can be dynamic and useFetch will respond to changes. If the default method
 * is GET and lazy = false, a new fetch will be initiated when the URL changes
 * @param {string|UseFetchOptions} [methodOrOptions = GET] A default HTTP method, or an {@link  UseFetchOptions} object
 * @returns {FetchResult} fetch result
 */


var useFetch = function useFetch(url) {
  var methodOrOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var requestContext = useContext(RequestContext);
  var options = typeof methodOrOptions === 'string' ? {
    defaultMethod: methodOrOptions
  } : methodOrOptions;
  var _options$defaultMetho = options.defaultMethod,
      defaultMethod = _options$defaultMetho === void 0 ? HTTP_METHODS.GET : _options$defaultMetho,
      defaultData = options.defaultData,
      defaultQuery = options.defaultQuery,
      _options$request = options.request,
      request = _options$request === void 0 ? requestContext : _options$request,
      _options$debounceTime = options.debounceTime,
      debounceTime = _options$debounceTime === void 0 ? 0 : _options$debounceTime,
      _options$cacheOptions = options.cacheOptions,
      cacheOptions = _options$cacheOptions === void 0 ? {} : _options$cacheOptions,
      defaultOnSuccess = options.defaultOnSuccess,
      defaultOnError = options.defaultOnError,
      _options$shouldSetDat = options.shouldSetDataFromDefaultMethodOnly,
      shouldSetDataFromDefaultMethodOnly = _options$shouldSetDat === void 0 ? true : _options$shouldSetDat,
      _options$skipToLastRe = options.skipToLastRequest,
      skipToLastRequest = _options$skipToLastRe === void 0 ? false : _options$skipToLastRe;
  var _options$lazy = options.lazy,
      lazy = _options$lazy === void 0 ? defaultMethod !== HTTP_METHODS.GET : _options$lazy;
  var cacheKey = cacheOptions.key,
      cacheMaxAge = cacheOptions.maxAge,
      appVersion = cacheOptions.appVersion,
      sessionToken = cacheOptions.sessionToken;

  var _useReducer = useReducer(purgeReducer, false),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      purgeRequested = _useReducer2[0],
      doPurge = _useReducer2[1];

  var _useReducer3 = useReducer(requestReducer, lazy ? [] : [{}]),
      _useReducer4 = _slicedToArray(_useReducer3, 2),
      requests = _useReducer4[0],
      doFetch = _useReducer4[1];

  var _useReducer5 = useReducer(responseReducer, [0, {
    doFetch: debounceTime > 0 ? debounce(doFetch, debounceTime) : doFetch,
    doPurge: doPurge,
    loading: false,
    complete: false,
    success: false,
    data: undefined,
    error: null,
    requestInfo: undefined
  }]),
      _useReducer6 = _slicedToArray(_useReducer5, 2),
      _useReducer6$ = _slicedToArray(_useReducer6[0], 2),
      currentRequestIndex = _useReducer6$[0],
      response = _useReducer6$[1],
      setResponse = _useReducer6[1];

  var cachedRequest = useCachedRequest(request, cacheKey, cacheMaxAge, {
    appVersion: appVersion,
    sessionToken: sessionToken
  });
  var doRequest = useCallback(function (requestUrl, requestOptions) {
    if (!request) {
      throw new Error('Request function not found in options or RequestContext');
    }

    var method = requestOptions.method,
        query = requestOptions.query,
        data = requestOptions.data;
    var urlWithParams = getUrlWithParams(requestUrl, query);

    if (method === HTTP_METHODS.GET && cacheKey && cacheMaxAge) {
      return cachedRequest(urlWithParams);
    }

    if ([HTTP_METHODS.GET, HTTP_METHODS.DELETE].includes(method)) {
      return request(method, urlWithParams);
    }

    return request(method, urlWithParams).send(data);
  }, [request, cachedRequest, cacheKey, cacheMaxAge]);
  useEffect(function () {
    if (purgeRequested && response !== null && response !== void 0 && response.requestInfo) {
      var _response$requestInfo;

      doPurge("".concat(getUrlWithParams(response.requestInfo.url, (_response$requestInfo = response.requestInfo) === null || _response$requestInfo === void 0 ? void 0 : _response$requestInfo.query), ":").concat(cacheKey));
    }
  }, [purgeRequested, response, cacheKey]);
  useEffect(function () {
    var _response$requestInfo2;

    if (!lazy && response !== null && response !== void 0 && response.requestInfo && (response === null || response === void 0 ? void 0 : (_response$requestInfo2 = response.requestInfo) === null || _response$requestInfo2 === void 0 ? void 0 : _response$requestInfo2.url) !== url) {
      doFetch();
    }
  }, [url, lazy, response]);
  useEffect(function () {
    /*
    This effect is dangerous because it modifies its own dependency.
    Ensure all logic stays below the following check.
     requests       currentRequestIndex  response
    [{}]           [0]                  [!loading]      load
    [{}]           [0]                  [loading]       skip
    [{}]           [1]                  [!loading]      skip
    [{},{}]        [1]                  [!loading]      load
     */
    if (response.loading || currentRequestIndex >= requests.length) {
      return;
    }

    var requestIndex = skipToLastRequest ? requests.length - 1 : currentRequestIndex;
    var thisRequest = requests[requestIndex];
    var _thisRequest$onSucces = thisRequest.onSuccess,
        onSuccess = _thisRequest$onSucces === void 0 ? defaultOnSuccess : _thisRequest$onSucces,
        _thisRequest$onError = thisRequest.onError,
        onError = _thisRequest$onError === void 0 ? defaultOnError : _thisRequest$onError,
        _thisRequest$method = thisRequest.method,
        method = _thisRequest$method === void 0 ? defaultMethod : _thisRequest$method,
        _thisRequest$query = thisRequest.query,
        query = _thisRequest$query === void 0 ? defaultQuery : _thisRequest$query,
        _thisRequest$data = thisRequest.data,
        data = _thisRequest$data === void 0 ? defaultData : _thisRequest$data;
    var requestInfo = {
      options: _objectSpread(_objectSpread({}, thisRequest), {}, {
        method: method,
        query: query,
        data: data
      }),
      url: url
    };
    setResponse({
      type: 'loading',
      payload: {
        requestInfo: requestInfo,
        requestIndex: requestIndex
      }
    });
    doRequest(url, {
      method: method,
      query: query,
      data: data
    }).then(function (res) {
      var type = !shouldSetDataFromDefaultMethodOnly || shouldSetDataFromDefaultMethodOnly && method === defaultMethod ? 'successWithData' : 'success';
      setResponse({
        type: type,
        payload: {
          data: res.body,
          requestInfo: requestInfo
        }
      });

      if (onSuccess) {
        onSuccess(res.body);
      }
    }).catch(function (error) {
      setResponse({
        type: 'error',
        payload: {
          error: error,
          requestInfo: requestInfo
        }
      });

      if (onError) {
        onError(error);
      }
    });
  }, [currentRequestIndex, defaultData, defaultMethod, defaultOnError, defaultOnSuccess, defaultQuery, debounceTime, doRequest, requests, response, shouldSetDataFromDefaultMethodOnly, skipToLastRequest, url]);
  return response;
};

export { useFetch, HTTP_METHODS };