function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { useCallback, useEffect, useReducer, useRef } from 'react';
var useFetchMockConfig = {
  useFetchActual: null,
  mocks: {}
};

var doFetchMockReducer = function doFetchMockReducer(state, request) {
  return [].concat(_toConsumableArray(state), [request]);
};

var useFetchMock = function useFetchMock(url) {
  var methodOrOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var useFetchActual = useFetchMockConfig.useFetchActual,
      mocks = useFetchMockConfig.mocks;

  if (!useFetchActual) {
    throw new Error('Please assign actual useFetch to useFetchConfig.useFetchActual');
  }

  var lastMockCallIndex = useRef();
  var thisMock = mocks[url];
  var request = useCallback(function (method) {
    var _ref2;

    var _ref = (_ref2 = thisMock && thisMock[method]) !== null && _ref2 !== void 0 ? _ref2 : {},
        _ref$data = _ref.data,
        data = _ref$data === void 0 ? {} : _ref$data,
        error = _ref.error;

    if (method === 'GET' || method === 'DELETE') {
      if (error) {
        return Promise.reject(error);
      }

      return Promise.resolve({
        body: data
      });
    }

    return {
      send: function send() {
        if (error) {
          return Promise.reject(error);
        }

        return Promise.resolve({
          body: data
        });
      }
    };
  }, [thisMock]);
  var mockOptions = typeof methodOrOptions === 'string' ? {
    defaultMethod: methodOrOptions,
    request: request
  } : _objectSpread(_objectSpread({}, methodOrOptions), {}, {
    request: request
  });
  var response = useFetchActual(url, mockOptions);
  var doFetch = response.doFetch;

  var _useReducer = useReducer(doFetchMockReducer, []),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      doFetchRequests = _useReducer2[0],
      doFetchMock = _useReducer2[1];

  useEffect(function () {
    if (doFetchRequests.length && lastMockCallIndex.current !== doFetchRequests.length) {
      var currentRequest = doFetchRequests[doFetchRequests.length - 1];
      doFetch(currentRequest);

      if (thisMock !== null && thisMock !== void 0 && thisMock.doFetchSpy) {
        thisMock === null || thisMock === void 0 ? void 0 : thisMock.doFetchSpy(currentRequest);
      }

      lastMockCallIndex.current = doFetchRequests.length;
    }
  }, [doFetchRequests, doFetch, thisMock]);
  return _objectSpread(_objectSpread({}, response), {}, {
    doFetch: doFetchMock
  });
};

export { useFetchMock, useFetchMockConfig };