import React from 'react';
import { Trans } from 'react-i18next';
import { Alert } from 'taulia-ui';

function LoginError() {
  return (
    <Alert data-testid="login-error" theme="danger">
      <p>
        <Trans
          i18nKey="bundled:loginPage.error.unknownError"
          components={[
            <a
              href="http://support.taulia.com"
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      </p>
    </Alert>
  );
}

export default LoginError;
