export function hasValue(value) {
  if (value) {
    switch (typeof value) {
      case 'object':
        if (value) {
          /* Array */
          if (Array.isArray(value)) {
            return value.length;
          }
          /* Object */
          return Object.keys(value).length;
        }
        return false;
      case 'boolean':
        return value;
      default:
        /* Everything else */
        return String(value);
    }
  }

  return false;
}
