import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, UserSettingsContext } from 'taulia-ui';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { AuthTypeContext } from '../../contexts/authTypeProvider';

function PromotionalPage() {
  const { t } = useTranslation();
  const { redirectUrl } = useContext(AuthTypeContext);
  const { redirectUser } = useContext(UserSettingsContext);
  const navigate = useNavigate();

  const setTwoFAPromoReminder = () => {
    if (store.enabled) {
      const expiry = new Date();
      expiry.setDate(expiry.getDate() + 30);
      store.set('twoFAPromo', { expiry });
    }
  };

  return (
    <div className="promo-page-wrapper login-container">
      <h2>{t('authentication.promotionalPage.headerNew')}</h2>
      <p>{t('authentication.promotionalPage.promoNew1')}</p>
      <div className="button-container">
        <Button
          theme="primary"
          className="set-up-2fa-button"
          onClick={() => navigate('/authentication/choose-method')}
        >
          {t('authentication.promotionalPage.setUp')}
        </Button>
        <Button
          theme="text"
          onClick={() => {
            setTwoFAPromoReminder();
            redirectUser(redirectUrl || '/login');
          }}
        >
          {t('authentication.promotionalPage.reminder')}
        </Button>
      </div>
    </div>
  );
}

export default PromotionalPage;
