import { useMemo } from 'react';
import { ContentObject, LabelContentObject } from '../../types/Label';

export const useLabelObject = (content: string | ContentObject) =>
  useMemo(() => {
    if (typeof content === 'string') return { text: content };

    const contentObject: LabelContentObject = {
      text: content.text,
    };

    if (content.props) {
      contentObject.labelProps = content.props;
    }

    if (content.tooltip) {
      contentObject.hasTooltip = true;

      if (typeof content.tooltip === 'string') {
        contentObject.tooltipText = content.tooltip;
      } else {
        contentObject.tooltipText = content.tooltip.text;
        contentObject.tooltipProps = content.tooltip.props || {};
      }
    }

    return contentObject;
  }, [content]);
