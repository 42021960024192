import { Option } from '../../types/Select';

export const applySelectedOptions = (
  selected: string | string[],
  options: Option[]
) => {
  if (!selected?.length) return [];

  const selectedOptions = (
    Array.isArray(selected) ? selected : [selected]
  ).reduce((result, value) => {
    const match = options?.find(it => it.value === value);

    return match ? [...result, match] : result;
  }, []);

  return selectedOptions;
};

export const isSafari = () => {
  const chromeInAgent = navigator.userAgent.indexOf('Chrome') > -1;
  const safariInAgent = navigator.userAgent.indexOf('Safari') > -1;
  return safariInAgent && !chromeInAgent;
};

export const registerOpenSelectHandlers = ({
  activeIndex,
  setActiveIndex,
  close, // close
  onOptionClickHandler, // select
  Options, // Options
  searchRef,
}) => {
  const keyDownCallback = e => {
    const { key } = e;

    switch (key) {
      case ' ': // Space
        if (searchRef.current) {
          searchRef.current?.focus();
          break;
        }
        if (!searchRef.current && !Options[activeIndex].disabled) {
          e.preventDefault();
          onOptionClickHandler(Options[activeIndex]);
        }
        break;
      case 'Up':
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(idx => (idx <= 0 ? Options.length - 1 : idx - 1));
        break;
      case 'Down':
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(idx => (idx + 1 === Options.length ? 0 : idx + 1));
        break;
      case 'Enter':
        if (!Options[activeIndex].disabled) {
          e.preventDefault();
          onOptionClickHandler(Options[activeIndex]);
        }
        break;
      case 'Esc':
      case 'Escape':
        e.preventDefault();
        close();
        break;
      case 'PageUp':
      case 'Home':
        e.preventDefault();
        setActiveIndex(0);
        break;
      case 'PageDown':
      case 'End':
        e.preventDefault();
        setActiveIndex(Options.length - 1);
        break;
      default:
        if (searchRef.current) {
          searchRef.current?.focus();
          break;
        }
    }
  };

  document.body.addEventListener('keydown', keyDownCallback);

  return () => {
    document.body.removeEventListener('keydown', keyDownCallback);
  };
};

export const registerClosedSelectHandlers = ({
  isOpen,
  listRef,
  searchRef,
  setIsFocused,
  setIsOpen,
}) => {
  const keyDownCallback = e => {
    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
      case 'Enter':
      case ' ': // Space
        e.preventDefault();
        setIsOpen(true);

        if (searchRef.current) {
          searchRef.current?.focus();
          break;
        }

        if (listRef.current && isSafari()) {
          requestAnimationFrame(() => {
            if (isOpen) {
              listRef.current.focus();
            } else {
              listRef.current.previousSibling.focus();
            }
          });
        }

        break;
      case 'Tab':
        setIsFocused(false);
        break;
    }
  };

  document.body.addEventListener('keydown', keyDownCallback);

  return () => {
    document.body.removeEventListener('keydown', keyDownCallback);
  };
};

export const sortFixedOptions = optionsArray =>
  optionsArray?.sort((a, b) => {
    const aF =
      a.fixedOrder !== undefined ? a.fixedOrder : Number.MAX_SAFE_INTEGER;
    const bF =
      b.fixedOrder !== undefined ? b.fixedOrder : Number.MAX_SAFE_INTEGER;

    return aF - bF;
  });

export const buildOptionsObject = selected => {
  const selectedValues = typeof selected === 'string' ? [] : selected;
  return selectedValues.reduce(
    (result, value) => ({ ...result, [value]: true }),
    {}
  );
};
