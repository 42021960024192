import { useContext, useMemo } from 'react';
import { UserSettingsContext } from '../Contexts';

var cleanExpiredCache = function cleanExpiredCache() {
  var keys = Object.keys(localStorage);
  keys.forEach(function (storageKey) {
    if (storageKey.startsWith('/api')) {
      var value = JSON.parse(localStorage[storageKey]);

      if ((value === null || value === void 0 ? void 0 : value.maxAge) < Date.now()) {
        localStorage.removeItem(storageKey);
      }
    }
  });
};

var getFromCache = function getFromCache(key, options) {
  if (localStorage) {
    var cachedValue = localStorage.getItem(key);
    var sessionToken = options.sessionToken,
        appVersion = options.appVersion;

    if (!cachedValue) {
      return null;
    }

    var cachedObj = JSON.parse(cachedValue);

    if (cachedObj.maxAge < Date.now()) {
      return null;
    }

    if (sessionToken && (cachedObj === null || cachedObj === void 0 ? void 0 : cachedObj.sessionToken) !== sessionToken) {
      return null;
    }

    if (appVersion && (cachedObj === null || cachedObj === void 0 ? void 0 : cachedObj.appVersion) !== appVersion) {
      return null;
    }

    return cachedObj.value;
  }

  return null;
};

var setInCache = function setInCache(key, maxAge, data, options) {
  if (localStorage) {
    var maxAgeInMilliseconds = maxAge * 60000;
    var sessionToken = options.sessionToken,
        appVersion = options.appVersion;
    var cacheObj = {
      maxAge: Date.now() + maxAgeInMilliseconds,
      sessionToken: sessionToken,
      appVersion: appVersion,
      value: data
    };

    try {
      localStorage.setItem(key, JSON.stringify(cacheObj));
    } catch (e) {
      if (e.name === 'QuotaExceededError') {
        cleanExpiredCache();
      }
    }
  }
};

var removeFromCache = function removeFromCache(key) {
  if (localStorage) {
    localStorage.removeItem(key);
  }
};

var useCacheOptionsFromContext = function useCacheOptionsFromContext(maxAge) {
  var _useContext = useContext(UserSettingsContext),
      userId = _useContext.userId,
      csrfConfig = _useContext.csrfConfig,
      appVersion = _useContext.appVersion;

  var csrfToken = csrfConfig.csrfToken;
  return useMemo(function () {
    return {
      key: userId,
      sessionToken: csrfToken,
      appVersion: appVersion,
      maxAge: maxAge
    };
  }, [userId, csrfToken, appVersion, maxAge]);
};

export { getFromCache, setInCache, removeFromCache, cleanExpiredCache, useCacheOptionsFromContext };