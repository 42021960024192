import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Grid,
  Spinner,
  toast,
  useFetchContext,
} from 'taulia-ui';
import { Trans, useTranslation } from 'react-i18next';
import { AuthTypeContext } from '../../contexts/authTypeProvider';
import { useQuery } from '../../hooks';
import { authLinks } from '../../utils';
import GLOBALS from '../../globals';

function RecoveryCodes() {
  const [enableContinue, setEnableContinue] = useState(false);
  const { authType } = useContext(AuthTypeContext);
  const { t } = useTranslation();
  const { data, error, loading } = useFetchContext('/api/recovery-codes');

  const query = useQuery();
  const navigate = useNavigate();

  const unusedCodesList = data?.user2FARecoveryCodeList
    ?.filter(code => !code.used)
    .map(code => code.recoveryCode)
    .join(', ');

  const continueUrlParam = query.get('continueUrl');
  const regenerated = query.get('regenerated') === 'true';
  const showContinueLink = !query.get('viewOnly');

  // The clipboard is accessible only in secure contexts: https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts
  // When developing locally, you have to access the page via localhost or 127.0.0.1
  const copyCodesToClipboard = async () => {
    await navigator.clipboard.writeText(unusedCodesList);

    setEnableContinue(true);
    toast.success(t('authentication.recoveryCodes.copyToClipboard'));
  };

  const downloadCodes = () => {
    setEnableContinue(true);
    const element = document.createElement('a');
    const file = new Blob([unusedCodesList], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'taulia-recovery-codes.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  };

  const printCodes = () => {
    setEnableContinue(true);
    window.print();
  };

  const continueLink =
    authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS
      ? '/authentication/setup-sms'
      : '/authentication/setup-application';

  if (error) {
    // TODO: render some kind of error/retry
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="recovery-codes">
      <h2 className="auth-header">{t('authentication.header')}</h2>
      <h4>{t('authentication.recoveryCodes.header')}</h4>
      <p>{t('authentication.recoveryCodes.infoText')}</p>
      <h2 className="print-only">
        {t('authentication.recoveryCodes.printHeader')}
      </h2>
      {regenerated && (
        <Alert theme="danger">
          {t('authentication.recoveryCodes.regenerated')}
        </Alert>
      )}
      <Alert theme="warn">
        <Grid>
          {data?.user2FARecoveryCodeList?.map(code => (
            <Col
              key={code}
              className={`auth-code${code.used ? ' used' : ''}`}
              sm={33}
              md={33}
              lg={33}
            >
              {code.recoveryCode}
            </Col>
          ))}
        </Grid>
        <p>
          <Trans
            i18nKey="authentication.recoveryCodes.recoveryCodeText"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...authLinks('passwordManagers')}
          />
        </p>
      </Alert>
      <p>{t('authentication.recoveryCodes.mustSave')}</p>
      <div className="button-primary-container">
        <Button onClick={downloadCodes} id="download-button">
          {t('standard.download')}
        </Button>
        <Button onClick={printCodes} id="print-button">
          {t('standard.print')}
        </Button>
        <Button onClick={copyCodesToClipboard} id="copy-button">
          {t('standard.copy')}
        </Button>
      </div>
      <div className="button-navigation-container">
        {!continueUrlParam && (
          <Button theme="text" onClick={() => navigate(-1)} id="back-link">
            {t('standard.back')}
          </Button>
        )}
        {showContinueLink && (
          <Button
            disabled={!enableContinue}
            onClick={() => {
              if (continueUrlParam) {
                const continueUrl = new URL(continueUrlParam);
                if (continueUrl.host.endsWith('taulia.com')) {
                  window.location.assign(continueUrlParam);
                } else {
                  window.location.assign(`/login`);
                }
              } else {
                navigate(continueLink);
              }
            }}
            theme="primary"
            title={t('authentication.recoveryCodes.mustSave')}
            id="continue-button"
          >
            {t('standard.continue')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default RecoveryCodes;
