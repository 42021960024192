function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* eslint-disable no-param-reassign */
import moment from 'moment';
export var createCalendarData = function createCalendarData(tabYears) {
  return tabYears.map(function (year) {
    var monthArr = [];

    for (var i = 0; i < 12; i += 1) {
      monthArr.push(new Date("".concat(i + 1, "/01/").concat(year)));
    }

    return monthArr.map(function (month) {
      return month;
    });
  });
};
export var createTabYears = function createTabYears(startDate, endDate) {
  var startDateYear = moment(startDate).year();
  var endDateYear = moment(endDate).year();
  var arr = [startDateYear];

  if (startDateYear === endDateYear) {
    return arr;
  }

  for (var i = startDateYear; i < endDateYear; i += 1) {
    arr.push(i + 1);
  }

  return arr;
};
export var createYearRange = function createYearRange() {
  var currentYearLess100 = moment().subtract(100, 'years').year();
  var currentYearPlus100 = moment().add(100, 'years').year();
  var startDateFormatted = new Date("01/01/".concat(currentYearLess100));
  var endDateFormatted = new Date("01/01/".concat(currentYearPlus100));
  return [startDateFormatted, endDateFormatted];
};
export var transformISOStrToDate = function transformISOStrToDate(isoStr) {
  var dateObj = moment(isoStr, 'YYYY-MM-DD').utc().toDate();
  return dateObj;
};
export var formatDate = function formatDate(date) {
  return moment(transformISOStrToDate(date)).format('YYYY/MM/DD');
};
export var formatDateTime = function formatDateTime(dateObj) {
  return moment(dateObj).utc().toISOString();
};
export var createCurrencyModalData = function createCurrencyModalData(currencyData) {
  var currencyKeys = currencyData ? Object.keys(currencyData === null || currencyData === void 0 ? void 0 : currencyData.currencies).sort() : [];
  return currencyKeys.map(function (key) {
    var obj = {};
    obj.id = key;
    obj.value = key;
    obj.label = "".concat(key, " (").concat(currencyData.currencies["".concat(key)], ")");
    return obj;
  });
};
export var createFunderModalData = function createFunderModalData(funderData) {
  var _funderData$results, _funderData$results2;

  if (!funderData || (funderData === null || funderData === void 0 ? void 0 : (_funderData$results = funderData.results) === null || _funderData$results === void 0 ? void 0 : _funderData$results.length) === 0) {
    return [];
  }

  var arr = [];
  funderData === null || funderData === void 0 ? void 0 : (_funderData$results2 = funderData.results) === null || _funderData$results2 === void 0 ? void 0 : _funderData$results2.forEach(function (item) {
    var obj = {};
    obj.id = item.companyId;
    obj.value = item.configName;
    obj.label = item.name;
    arr.push(obj);
  });
  return arr;
};
export var createBusinessUnitModalData = function createBusinessUnitModalData(businessUnitData) {
  var _businessUnitData$res, _businessUnitData$res2;

  if (!businessUnitData || (businessUnitData === null || businessUnitData === void 0 ? void 0 : (_businessUnitData$res = businessUnitData.results) === null || _businessUnitData$res === void 0 ? void 0 : _businessUnitData$res.length) === 0) {
    return [];
  }

  var arr = [];
  businessUnitData === null || businessUnitData === void 0 ? void 0 : (_businessUnitData$res2 = businessUnitData.results) === null || _businessUnitData$res2 === void 0 ? void 0 : _businessUnitData$res2.forEach(function (item) {
    var obj = {};
    obj.id = item.id;
    obj.value = item.name;
    obj.label = item.nameAndNumber;
    arr.push(obj);
  });
  return arr;
};
export var createModalDataWithSelected = function createModalDataWithSelected(selectedList, formattedData) {
  var selectedListCopy = _toConsumableArray(selectedList);

  var selectFormattedDataCopy = _toConsumableArray(formattedData);

  if (selectedListCopy.length === 0) {
    return formattedData;
  }

  selectFormattedDataCopy.forEach(function (item) {
    selectedListCopy.forEach(function (selected) {
      if (selected.id === item.id) {
        item.checked = true;
      }
    });
  });
  return selectFormattedDataCopy;
};
export var sortedFormattedCompleteList = function sortedFormattedCompleteList(arr) {
  return arr.sort(function (a, b) {
    var labelA = a.label.toLowerCase();
    var labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    }

    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });
};

var sortFunc = function sortFunc(a, b) {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export var setDropdownOptions = function setDropdownOptions(results, setBuyerOptions, setBuyerMap) {
  if (Array.isArray(results)) {
    results.sort(sortFunc);
    var options = results === null || results === void 0 ? void 0 : results.map(function (option) {
      return {
        value: option.id,
        label: "".concat(option.name, " (").concat(option.id, ")")
      };
    });
    var map = {};
    Object.values(options).forEach(function (it) {
      map[it.value] = it.label;
    });
    setBuyerOptions(options);
    setBuyerMap(map);
  }
}; // this will remove the duplicate id per dropdown entry

export var formatBuyerName = function formatBuyerName(name) {
  var formattedBuyerName = name === null || name === void 0 ? void 0 : name.split(' ');
  formattedBuyerName === null || formattedBuyerName === void 0 ? void 0 : formattedBuyerName.pop();
  return formattedBuyerName === null || formattedBuyerName === void 0 ? void 0 : formattedBuyerName.join(' ');
};
export var createDetailsInitialData = function createDetailsInitialData(completeList, calendarData, type) {
  var _calendarData$busines;

  var arr = [];
  calendarData === null || calendarData === void 0 ? void 0 : (_calendarData$busines = calendarData.businessObjects) === null || _calendarData$busines === void 0 ? void 0 : _calendarData$busines.forEach(function (preSelItem) {
    if (preSelItem.businessType === type) {
      var findIndexOfPreSelectedItem = completeList === null || completeList === void 0 ? void 0 : completeList.findIndex(function (item) {
        return (preSelItem === null || preSelItem === void 0 ? void 0 : preSelItem.businessId) === (item === null || item === void 0 ? void 0 : item.id);
      });
      var obj = {};

      if (findIndexOfPreSelectedItem > -1) {
        var currencyListItem = completeList[findIndexOfPreSelectedItem];
        obj.id = currencyListItem.id;
        obj.value = currencyListItem.value;
        obj.label = currencyListItem.label;
      }

      arr.push(obj);
    }
  });
  return sortedFormattedCompleteList(arr);
};