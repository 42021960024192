import { getI18n } from 'react-i18next';

/* Sorts Search Fields */
export const sortSearchFields = (fields, filters) => {
  const searchFields = fields.slice();
  const checkedFields = filters?.map(filter => filter.field);

  searchFields.sort((a, b) => {
    if (checkedFields?.includes(a.name) && !checkedFields?.includes(b.name))
      return -1;

    if (!checkedFields?.includes(a.name) && checkedFields?.includes(b.name))
      return 1;

    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;

    return 0;
  });

  return searchFields;
};

/* Creates an empty filter object */
export const createEmptyFilter = fieldName => ({
  field: fieldName,
  values: [],
});

/* Checks if the tooltip is present and translates it */
export const getTooltipText = (tooltips, name) => {
  const i18next = getI18n();
  if (tooltips && tooltips.includes(name)) {
    return i18next.t(`tooltips.${name}`);
  }

  return '';
};
