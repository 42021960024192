function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestContext } from '../Contexts';
import useCachedRequest from './useCachedRequest';
import translations from '../translations/translations';
var fallBackLocale = 'en-US';
var libAppUiNameSpace = 'lib-app-ui';
export var useTranslationLoader = function useTranslationLoader(locale, appVersion, sessionToken, translationServiceUrl, appName, requestObj) {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      translationLoadingError = _useState2[0],
      setTranslationLoadingError = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      localeLoaded = _useState4[0],
      setLocaleLoaded = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      fallbackLocalLoaded = _useState6[0],
      setFallbackLocaleLoaded = _useState6[1];

  var requestFromContext = useContext(RequestContext);
  var request = requestObj || requestFromContext;
  var cacheOptions = useMemo(function () {
    return {
      appVersion: appVersion,
      sessionToken: sessionToken
    };
  }, [appVersion, sessionToken]);
  var cachedRequest = useCachedRequest(request, '', 20160, cacheOptions);
  var addResourceBundle = useCallback(function (nameSpace, localeToAdd, data) {
    if (!i18n.hasResourceBundle(localeToAdd, nameSpace)) {
      i18n.addResourceBundle(localeToAdd, nameSpace, data);
    }
  }, [i18n]);
  var loadLocale = useCallback(function (localeToLoad) {
    return new Promise(function (resolve, reject) {
      var libLoaded = false;
      var appLoaded = false;
      cachedRequest("".concat(translationServiceUrl, "/").concat(appName, "/").concat(localeToLoad)).then(function (_ref) {
        var body = _ref.body;
        addResourceBundle('app', localeToLoad, body);
        appLoaded = true;

        if (libLoaded && appLoaded) {
          resolve();
        }
      }).catch(function (err) {
        reject(err);
      });
      cachedRequest("".concat(translationServiceUrl, "/").concat(libAppUiNameSpace, "/").concat(localeToLoad)).then(function (_ref2) {
        var body = _ref2.body;

        if (Object.keys(body).length) {
          addResourceBundle(libAppUiNameSpace, localeToLoad, body);
        } else {
          addResourceBundle(libAppUiNameSpace, localeToLoad, translations[localeToLoad][libAppUiNameSpace]);
        }

        libLoaded = true;

        if (libLoaded && appLoaded) {
          resolve();
        }
      }).catch(function () {
        addResourceBundle(libAppUiNameSpace, localeToLoad, translations[localeToLoad][libAppUiNameSpace]);
        libLoaded = true;

        if (libLoaded && appLoaded) {
          resolve();
        }
      });
    });
  }, [appName, cachedRequest, translationServiceUrl, addResourceBundle]);
  useEffect(function () {
    loadLocale(fallBackLocale).then(function () {
      setFallbackLocaleLoaded(true);
    }).catch(function () {
      setTranslationLoadingError(true);
    });
  }, [loadLocale]);
  useEffect(function () {
    if (locale) {
      if (locale !== fallBackLocale) {
        setLocaleLoaded(false);
        loadLocale(locale).then(function () {
          setLocaleLoaded(true);
        }).catch(function () {
          setTranslationLoadingError(true);
        });
      } else {
        setLocaleLoaded(true);
      }
    }
  }, [locale, loadLocale]);
  useEffect(function () {
    if (localeLoaded && fallbackLocalLoaded) {
      i18n.changeLanguage(locale);
    }
  }, [localeLoaded, fallbackLocalLoaded, i18n, locale]);
  return useMemo(function () {
    if (translationLoadingError) {
      return {
        complete: true,
        error: true,
        success: false
      };
    }

    if (fallbackLocalLoaded && localeLoaded) {
      return {
        complete: true,
        error: false,
        success: true
      };
    }

    return {
      complete: false,
      error: false,
      success: false
    };
  }, [translationLoadingError, fallbackLocalLoaded, localeLoaded]);
};