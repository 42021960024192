import React from 'react';
import { cx } from '../utils';
import { Spinner } from '../Spinner';
import { CardProps } from '../types';

export default function Card({
  children = null,
  classNames = [],
  customHeader = null,
  isLoading = false,
  showCard = true,
  showTitle = true,
  title,
}: CardProps) {
  const testId = title?.trim().toLowerCase().split(' ').join('-');

  return showCard ? (
    <div
      className={cx('card', [...classNames])}
      data-testid={
        title?.trim().length > 0 ? `card-${testId}` : 'card-title-undefined'
      }
    >
      {isLoading ? (
        <div className="spinner-container" data-testid="card-loading-spinner">
          <Spinner hasDelay={false} />
        </div>
      ) : (
        <>
          {customHeader}
          {!customHeader && showTitle && title?.trim().length > 0 && (
            <div className="card-title-container">{title}</div>
          )}
          <div className="card-content-container">{children}</div>
        </>
      )}
    </div>
  ) : null;
}
