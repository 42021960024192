function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useLibNSTranslation } from '../../utils/i18nUtils';
import { countriesIsoData as countryList, mergeObjects } from '../../utils';
var NS = 'countryCodeWithNames';
var UnsupportedCountries = ['CU', 'IR', 'KP', 'SY', 'SD', 'RU', 'BY']; // List of legally unsupported countries

function extractCountryName(name) {
  if (!(name !== null && name !== void 0 && name.includes('('))) return name;
  var result = name.substring(name.indexOf('(') + 1, name.length - 1);
  return result;
}
/**
 * Country object
 *
 * @typedef {object} CountryObject
 * @property {string} name - Translated full name of the country
 * @property {string} alpha2 -alpha-2 code
 * @property {string} alpha3 - alpha-3 code
 */

/**
 * A single country object or a full list of all country objects.
 *
 * @typedef {CountryObject|CountryObject[]} CountryResult
 */

/**
 * Options object
 *
 * @typedef {object} OptionsObject
 * @property {boolean} codeWithName - Returns the country name in format "$Code ($Name)".
 * @property {boolean} nameWithCode - Returns the country name in format "$Name ($Code)".
 * @property {boolean} includeUnsupportedCountries - Includes the currently unsupported countries in the returned list. Does nothing if specifically requesting one of them.
 * @property {boolean | 'name' | 'alpha2' | 'alpha3'} returnAsOptions - Returns the list in { label, value } format. The value can be specified by the current name, alpha2 or alpha3.
 */


var defaultOptions = {
  codeWithName: false,
  nameWithCode: false,
  includeUnsupportedCountries: false,
  returnAsOptions: false
};
/**
 * Returns detailed information about specific country. Full name of the country is also translated.
 * @param {string} countryCode Country code
 * @param {OptionsObject} options Options regarding the returned country object
 * @returns {CountryResult} A single country object or a full list of all country objects.
 */

function useTranslatedCountry(countryCode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useLibNSTranslation = useLibNSTranslation(),
      t = _useLibNSTranslation.t;

  var tNS = function tNS(key) {
    return t("".concat(NS, ".").concat(key));
  };

  var config = mergeObjects(options, defaultOptions);
  var codeWithName = config.codeWithName,
      nameWithCode = config.nameWithCode,
      includeUnsupportedCountries = config.includeUnsupportedCountries,
      returnAsOptions = config.returnAsOptions;

  var generateName = function generateName(country) {
    var tName = tNS(country.alpha2);
    if (codeWithName) return tName;
    var name = extractCountryName(tName);
    if (nameWithCode) return "".concat(name, " (").concat(country.alpha2, ")");
    return name;
  };
  /* If specific country is provided return only the matched country data */


  if (countryCode) {
    var selectedCountry = countryList.find(function (ctr) {
      return ctr.alpha2 === countryCode || ctr.alpha3 === countryCode;
    });
    selectedCountry.name = generateName(selectedCountry);
    return selectedCountry;
  }
  /* If no country is provided, return a list with all countries */


  var translatedCountryList = Object.values(countryList).map(function (country) {
    var name = generateName(country);

    if (returnAsOptions) {
      var value = typeof returnAsOptions === 'string' ? country[returnAsOptions] : country.alpha2;
      return {
        label: name,
        value: value
      };
    }

    return _objectSpread(_objectSpread({}, country), {}, {
      name: name
    });
  });
  if (includeUnsupportedCountries) return translatedCountryList;
  var supportedCountries = translatedCountryList.filter(function (country) {
    return !UnsupportedCountries.includes(country.alpha2 || country.value);
  });
  return supportedCountries;
}

export default useTranslatedCountry;