export function isFutureDate(date: Date) {
  return new Date() < date;
}

export function isInsideRange(date: Date, start: Date, end: Date) {
  return date >= start && date <= end;
}

export function isInsideRangeExc(date: Date, start: Date, end: Date) {
  return date > start && date < end;
}

export function isInsideRangeExcStart(date: Date, start: Date, end: Date) {
  return date > start && date <= end;
}

export function isInsideRangeExcEnd(date: Date, start: Date, end: Date) {
  return date >= start && date < end;
}

/* -----> Export Object <----- */
const dateValidations = {
  isFutureDate,
  isInsideRange,
  isInsideRangeExc,
  isInsideRangeExcEnd,
  isInsideRangeExcStart,
};

export default dateValidations;
