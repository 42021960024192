import React from 'react';
import { ClickableProps } from '../types';

export default function Clickable({
  className = '',
  children = [],
  onClick,
  ...otherProps
}: ClickableProps) {
  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onClick();
    }
  };
  return (
    <div
      className={`clickable ${className}`}
      onClick={() => {
        (document.activeElement as HTMLElement)?.blur();
        onClick();
      }}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
      {...otherProps}
    >
      {children}
    </div>
  );
}
