/* Checks if the supported operators contain the default ones */
export const findDefaultNumberFilterOperator = field => {
  const operators = field.supportedOperators;
  const defaultOperators = ['LESS_THAN', 'MORE_THAN', 'EQUAL'];

  return defaultOperators.find(it => operators.includes(it));
};

/* Checks if the operator is BETWEEN/NOT_BETWEEN */
export const isEndValueEnabled = operator =>
  operator === 'BETWEEN' || operator === 'NOT_BETWEEN';

/* Checks the field for BETWEEN/NOT_BETWEEN */
export const isEndValueAvailable = field =>
  field.supportedOperators?.includes('BETWEEN') ||
  field.supportedOperators?.includes('NOT_BETWEEN');

export const isStartValueEnabled = operator =>
  operator !== 'IS_NULL' && operator !== 'NOT_NULL';
