function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import { getCookie, isCookieEnabled, setCookie } from 'tiny-cookie';

function getTLD() {
  var domain = window.location.hostname;
  var arr = domain.split('.');

  if (arr.length >= 2) {
    return "".concat(arr[1]).concat(arr[0]);
  }

  return domain;
}

export default (function (locale) {
  if (isCookieEnabled) {
    var cookieVal = {};

    try {
      cookieVal = JSON.parse(getCookie('profile'));

      if (!cookieVal || _typeof(cookieVal) !== 'object') {
        cookieVal = {};
      }
    } catch (e) {
      cookieVal = {};
    }

    cookieVal.language = locale.replace('-', '_');
    setCookie('profile', JSON.stringify(cookieVal), {
      domain: getTLD(),
      SameSite: 'Strict'
    });
  }
});