import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Field, UserSettingsContext } from 'taulia-ui';

function Captcha({ onVerify, onExpire }) {
  const { captchaSiteKey } = useContext(UserSettingsContext);

  return (
    <Field className="captcha">
      <HCaptcha
        sitekey={captchaSiteKey}
        onVerify={token => onVerify(token)}
        onExpire={() => onExpire()}
      />
    </Field>
  );
}

export default Captcha;

Captcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func.isRequired,
};
