import PropTypes from 'prop-types';
import { useFeatureFetch, useEnabledSettingsFetch, useRoleCheck, usePermissionCheck, useRoleTypeCheck } from '../Hooks';
/**
 * A component to control visibility of a child component based on commonly used access parameters
 */

function AccessControlledToggle(_ref) {
  var children = _ref.children,
      allowedRoles = _ref.allowedRoles,
      allowedRoleTypes = _ref.allowedRoleTypes,
      deniedRoles = _ref.deniedRoles,
      allowedPermissions = _ref.allowedPermissions,
      requiredCompanySettings = _ref.requiredCompanySettings,
      requiredSystemSettings = _ref.requiredSystemSettings,
      requiredFeature = _ref.requiredFeature,
      requiredCondition = _ref.requiredCondition,
      disabledComponent = _ref.disabledComponent,
      errorComponent = _ref.errorComponent;
  var roleEnabled = useRoleCheck(allowedRoles, deniedRoles);
  var roleTypeEnabled = useRoleTypeCheck(allowedRoleTypes);
  var permissionsEnabled = usePermissionCheck(allowedPermissions);

  var _useFeatureFetch = useFeatureFetch(requiredFeature),
      featureEnabled = _useFeatureFetch.isEnabled,
      featureFetchError = _useFeatureFetch.error,
      featureFetchLoading = _useFeatureFetch.loading;

  var _useEnabledSettingsFe = useEnabledSettingsFetch(requiredCompanySettings, requiredSystemSettings),
      settingsEnabled = _useEnabledSettingsFe.isEnabled,
      settingsFetchError = _useEnabledSettingsFe.error,
      settingsFetchLoading = _useEnabledSettingsFe.loading;

  if (featureFetchError || settingsFetchError) {
    if (errorComponent) {
      return errorComponent;
    }

    return null;
  }

  if (featureFetchLoading || settingsFetchLoading || permissionsEnabled === undefined || roleEnabled === undefined) {
    return null;
  }

  if (settingsEnabled && featureEnabled && roleEnabled && roleTypeEnabled && permissionsEnabled && requiredCondition) {
    return children;
  }

  if (disabledComponent) {
    return disabledComponent;
  }

  return null;
}

AccessControlledToggle.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  allowedRoleTypes: PropTypes.arrayOf(PropTypes.string),
  deniedRoles: PropTypes.arrayOf(PropTypes.string),
  allowedPermissions: PropTypes.arrayOf(PropTypes.string),
  requiredCompanySettings: PropTypes.arrayOf(PropTypes.string),
  requiredCondition: PropTypes.bool,
  requiredFeature: PropTypes.string,
  requiredSystemSettings: PropTypes.arrayOf(PropTypes.string),
  disabledComponent: PropTypes.element,
  errorComponent: PropTypes.element
};
AccessControlledToggle.defaultProps = {
  allowedRoles: [],
  allowedRoleTypes: [],
  deniedRoles: [],
  allowedPermissions: [],
  requiredCompanySettings: [],
  requiredSystemSettings: [],
  requiredCondition: true,
  requiredFeature: null,
  disabledComponent: null,
  errorComponent: null
};
export default AccessControlledToggle;