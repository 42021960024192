import { QueryObject } from '../../types/EnhancedSearch';
import {
  DEFAULT_PAGE_SIZE,
  TERNARY_OPERATORS,
  UNARY_OPERATORS,
} from '../constants';
import useESContext from './useESContext';

/* Compiles the Search Query based on filters, selected records, pagination & sorting */
const usePreparedQuery = (
  queryObject: QueryObject,
  props: {
    extraColumns: any[];
    includedFields: any[];
    requiredDataFields: string[];
  }
): QueryObject => {
  const query = { ...queryObject };
  const { configuration } = useESContext();
  const { extraColumns, includedFields, requiredDataFields } = props;

  /* Normalize the query to make sure it has all expected parts */
  if (!query.filters) query.filters = [];
  if (!query.select) query.select = [];
  if (!query.pagination) query.pagination = { first: 0 };
  if (!query.sorts) query.sorts = [];

  /* Apply the included fields */
  if (includedFields.length) {
    query.filters = [...query.filters, ...includedFields];
  }

  /* Remove any filters from the query that don't have valid arguments */
  const filters = query.filters.filter(
    (filter: { field: string; [key: string]: any }) => {
      const values = filter.values
        ? filter.values.filter(
            it =>
              it ||
              typeof it === 'boolean' ||
              (typeof it === 'number' && it !== null)
          )
        : [];

      const operator = filter.operator || 'EQUAL';

      if (UNARY_OPERATORS.includes(operator)) {
        return true;
      }

      if (TERNARY_OPERATORS.includes(operator)) {
        return values.length === 2;
      }

      return values.length > 0;
    }
  );

  /* Remove any extra columns from the query */
  const extraColumnLabels = extraColumns.map(column => column.key);
  const filteredSelect = query.select.filter(
    field => !extraColumnLabels.includes(field)
  );

  const select = Array.from(
    new Set([
      ...filteredSelect,
      ...requiredDataFields,
      ...(configuration?.idField ? [configuration.idField] : []),
    ])
  );

  /* Create pagination properties */
  const paginationCapabilities = configuration?.capabilities.pagination;
  const pagination = { ...query.pagination };

  if (pagination.limit == null) {
    pagination.limit = DEFAULT_PAGE_SIZE;
  }

  if (pagination.includeTotalCount == null) {
    pagination.includeTotalCount = paginationCapabilities?.supportsTotalCount;
  }

  return { ...query, filters, select, pagination };
};

export default usePreparedQuery;
