import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from '../Icons/Calendar';
import { Clickable } from '../Button';
import { cx } from '../utils';
import MultiDateRangePill from './MultiDateRangePill';
import { determineDateRangePillWidth } from './MultiDateRangePickerUtils';
import { AutomatedValidation, validate } from '../Validation';
import { MDRPProps } from '../types';
import { usePredefinedValidations } from '../Hooks';
import { Label } from '../Label';

const MultiDateRangePicker = forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      allowRangeMerging = false,
      dateRangePillWidth = 2,
      dates = [],
      disabled = false,
      id = '',
      label = '',
      localeOverride = '',
      onChangeDateRanges = () => {},
      required = false,
      validation = false,
      validationState = null,
      weekdayNameHeaderLength = 1,
    }: MDRPProps,
    ref
  ) => {
    const { i18n } = useTranslation();
    const { multiDateValidations } = usePredefinedValidations();

    const [dateRanges, setDateRanges] = useState(dates || []);
    const [isTouched, setIsTouched] = useState(false);
    const [error, setError] = useState<string | false>(false);

    const errorRef = useRef<string | false>(false);
    const touchedRef = useRef(false);

    const locale = localeOverride || i18n.language;
    const dayInMs = 86400000;

    /* -----> Utils <----- */
    const updateError = (error: string | false) => {
      setError(error);
      errorRef.current = error;
    };

    const updateTouched = (touched: boolean) => {
      setIsTouched(touched);
      touchedRef.current = touched;
    };

    const componentHasError = (checkRefs = false) =>
      (required &&
        (checkRefs ? touchedRef.current : isTouched) &&
        dateRanges.length === 0) ||
      ((checkRefs ? errorRef.current : error) && dateRanges?.length > 0);

    const formatDateToIncludeTime = date => {
      if (typeof date !== 'string' || date.includes(':00')) {
        return date;
      }
      return `${date}T12:00:00`;
    };

    /* generates temporary unique IDs that are sorted by its generated
      Date to use as the `key` for each MultiDateRangePill component */
    const generateUniqueId = () =>
      Date.now().toString(36) + Math.random().toString(36).slice(2);

    /* Merges two date ranges into one. Assumes they are overlapping. */
    function mergeDateRanges(fr, sr) {
      const timeArray = [
        fr.startDate,
        fr.endDate,
        sr.startDate,
        sr.endDate,
      ].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      return {
        startDate: new Date(timeArray[0]),
        endDate: new Date(timeArray[3]),
      };
    }

    /* Checks if there are overlapping ranges, sorts and merges them. */
    function recheckDateRanges(dateArray) {
      /* Sorts the dates by "startDate" */
      const updatedArray = dateArray
        // @ts-ignore
        .sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate))
        .map(dt => {
          return {
            startDate: new Date(formatDateToIncludeTime(dt.startDate)),
            endDate: new Date(formatDateToIncludeTime(dt.endDate)),
          };
        });

      for (let i = 0; i < updatedArray.length - 1; i += 1) {
        const currentRange = updatedArray[i];
        const nextRange = updatedArray[i + 1];

        /* Finds overlapping ranges and merges them. */
        /* Uses inclusive logic -> range that ends on 20th day will be merged with range that starts on 21st day  */
        /* Example: { start: 06/15, end: 06/20 } && { start: 06/21, end: 06/25 } -> { start: 06/15 end: 06/25 } */
        if (nextRange.startDate.getTime() - dayInMs <= currentRange.endDate) {
          const mergedRange = mergeDateRanges(currentRange, nextRange);
          updatedArray.splice(i, 2, mergedRange);
          i = -1;
        }
      }

      return updatedArray;
    }

    function triggerDateValidation() {
      const validationError = validate(
        dateRanges,
        validation,
        multiDateValidations
      );

      updateError(validationError);
      __triggerFormValidation(id);
    }

    /* -----> Handlers <----- */
    const handleDateRangeAdd = () => {
      const newDateRanges = [
        ...dateRanges,
        {
          startDate: null,
          endDate: null,
          isNew: true,
          uid: generateUniqueId(),
        },
      ];
      setDateRanges([...newDateRanges]);
    };

    const handleDateRangeChange = (index, startDate, endDate, uid) => {
      updateTouched(true);

      let newDateRanges = [
        ...dateRanges.slice(0, index),
        { startDate, endDate, uid },
        ...dateRanges.slice(index + 1),
      ];

      if (newDateRanges.length > 1 && allowRangeMerging) {
        newDateRanges = recheckDateRanges(newDateRanges);
      }

      setDateRanges([...newDateRanges]);
    };

    const handleDateRangeRemoval = index => {
      updateTouched(true);

      const newDateRanges = [...dateRanges];
      newDateRanges.splice(index, 1);

      setDateRanges([...newDateRanges]);
      triggerDateValidation();
    };

    /* -----> Effects <----- */

    useImperativeHandle(ref, () => ({
      name: id,
      value: dateRanges.map(it => ({
        startDate: it.startDate,
        endDate: it.endDate,
      })),
      clearState: () => {
        updateTouched(false);
        setDateRanges([]);
        updateError(false);
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    useEffect(() => {
      onChangeDateRanges(dateRanges);

      if (
        dateRanges?.length &&
        !dateRanges.some(it => it.startDate === null || it.endDate === null)
      ) {
        triggerDateValidation();
      }
    }, [dateRanges]);

    return (
      <div className="mdrp-wrapper">
        <Label content={label} required={required} />
        <div
          className={cx(
            'date-range-grid',
            validationState ? `${validationState}` : null,
            { error: componentHasError() },
            disabled ? 'disabled-input' : null
          )}
          style={{
            width: determineDateRangePillWidth(
              dateRangePillWidth,
              locale,
              disabled
            ),
          }}
        >
          <Clickable
            data-testid="add-date-range-calendar"
            className="calendar-svg-container"
            onClick={() => (!disabled ? handleDateRangeAdd() : null)}
          >
            <Calendar />
          </Clickable>
          <div className="date-picker-display">
            <div
              className="date-picker-pill-container"
              data-testid="date-picker-pill-container"
            >
              {dateRanges.map((dateRange, index) => {
                const uid = dateRange.uid || generateUniqueId();
                return (
                  <MultiDateRangePill
                    key={uid}
                    disabled={disabled}
                    endingDate={
                      dateRange.endDate
                        ? new Date(formatDateToIncludeTime(dateRange.endDate))
                        : null
                    }
                    index={index}
                    isNew={dateRange.isNew || false}
                    localeOverride={localeOverride}
                    onDateChanges={handleDateRangeChange}
                    onDateRemoval={handleDateRangeRemoval}
                    startingDate={
                      dateRange.startDate
                        ? new Date(formatDateToIncludeTime(dateRange.startDate))
                        : null
                    }
                    uid={uid}
                    weekdayNameHeaderLength={weekdayNameHeaderLength}
                  />
                );
              })}
              <Clickable
                data-testid="secondary-add-date-range-inline"
                className="secondary-add-date-picker"
                onClick={() => (!disabled ? handleDateRangeAdd() : null)}
              />
            </div>
            {dateRanges.length < dateRangePillWidth && (
              <Clickable
                data-testid="secondary-add-date-range-filler"
                className="secondary-add-date-picker"
                onClick={() => (!disabled ? handleDateRangeAdd() : null)}
              />
            )}
          </div>
        </div>
        <AutomatedValidation
          errorMessage={error}
          isTouched={isTouched}
          required={required}
          value={dateRanges}
        />
      </div>
    );
  }
);

MultiDateRangePicker.defaultProps = {
  __hasRef: true,
};

export default MultiDateRangePicker;
