import { useEffect, useMemo, useState } from 'react';
import { RsaEncrypt, useFetch } from 'taulia-ui';

const usePasswordEncryptionFetch = () => {
  const [rsaEncrypt, setRsaEncrypt] = useState();
  const [encryptDataError, setEncryptDataError] = useState(false);
  const [refetchingConfig, setRefetchingConfig] = useState(false);

  const {
    data,
    error,
    loading,
    doFetch: fetchConfig,
  } = useFetch('/api/password-encryption/config');

  /* eslint-disable-next-line consistent-return */
  function rsaEncryptGenerationHandler(responseData) {
    if (responseData.publicKeyValue) {
      const encryption = new RsaEncrypt(
        responseData.publicKeyValue,
        responseData.publicKeyId,
        responseData.implementationVersion
      );

      setRsaEncrypt(encryption);
      return encryption;
    }

    setEncryptDataError(true);
  }

  function refetchHandler(onSuccessCallback) {
    setRefetchingConfig(true);

    fetchConfig({
      onSuccess: dt => {
        setRefetchingConfig(false);
        const renewedRsaEncrypt = rsaEncryptGenerationHandler(dt);

        if (renewedRsaEncrypt) {
          onSuccessCallback(renewedRsaEncrypt);
        }
      },
    });
  }

  useEffect(() => {
    if (data) {
      rsaEncryptGenerationHandler(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return useMemo(() => {
    let encryptionError = error;
    if (encryptDataError) {
      encryptionError = new Error('Error loading encryption key');
    }
    return {
      rsaEncrypt,
      error: encryptionError,
      loading,
      refetchConfig: refetchHandler,
      refetchingConfig,
    };
  }, [
    rsaEncrypt,
    error,
    encryptDataError,
    loading,
    refetchHandler,
    refetchingConfig,
  ]);
};

export default usePasswordEncryptionFetch;
