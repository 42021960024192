import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Field,
  FieldLabel,
  FieldErrorMap,
  PhoneNumber,
  Text,
  toast,
  UserSettingsContext,
  useSendContext,
} from 'taulia-ui';
import { useTranslation } from 'react-i18next';
import { authCodeIsValid, removeHyphensAndWhitespace } from '../../utils';
import CONSTANTS from '../../globals';

function SMSSetup() {
  const { logger } = useContext(UserSettingsContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSendSMSDisabled, setIsSendSMSDisabled] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [isTouched, setIsTouched] = useState({});
  const [isValid, setIsValid] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getPhoneNumberWithoutDashes = () => phoneNumber.replace('-', '');

  const {
    data: confirmData,
    error: confirmError,
    loading: confirmLoading,
    doSend: doConfirmData,
  } = useSendContext('POST', '/api/two-factor-setup/confirm');

  const {
    data: smsData,
    error: smsError,
    doSend: doSmsData,
  } = useSendContext('POST', '/api/send-sms');

  const {
    data: authCodeData,
    error: authCodeError,
    doSend: doAuthCodeData,
  } = useSendContext('PUT', '/api/two-factor-setup/config');

  const getConfirmData = () => {
    doConfirmData({
      authCode: removeHyphensAndWhitespace(authCode),
      phoneNumber: getPhoneNumberWithoutDashes(),
    });
  };

  const getSmsData = () => {
    doSmsData({ phoneNumber: getPhoneNumberWithoutDashes() });
  };

  const getAuthCodeData = () => {
    doAuthCodeData({
      type: CONSTANTS.TWO_FACTOR_AUTH_TYPE.SMS,
      phoneNumber: getPhoneNumberWithoutDashes(),
    });
  };

  useEffect(() => {
    if (confirmData) {
      logger.log('2FA Auth Code successfully confirmed/validated');
      window.location.href = confirmData.redirectUrl;
    } else if (confirmError) {
      toast.error(t('authentication.smsSetup.verificationError'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmData, confirmError]);

  useEffect(() => {
    const validateInput = () => {
      setIsValid({
        authCode: authCodeIsValid(authCode),
        phoneNumber: PhoneNumber.isValid(phoneNumber),
      });
    };
    validateInput();
  }, [authCode, phoneNumber]);

  useEffect(() => {
    if (smsData) {
      setCodeSent(true);
      toast.success(t('authentication.smsSetup.codeSuccess'));
    } else if (smsError) {
      toast.error(t('authentication.smsSetup.codeFailure'));
      setIsSendSMSDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsData, smsError]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (authCodeData) {
      setIsSendSMSDisabled(true);
      const timer = setTimeout(() => setIsSendSMSDisabled(false), 5000);
      getSmsData();
      return () => clearTimeout(timer);
    }
    if (authCodeError) {
      setIsSendSMSDisabled(false);
      // eslint-disable-next-line no-console
      console.log('error'); // TODO Add error handling
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCodeData, authCodeError]);

  const getValidationState = type =>
    isDirty[type] && isTouched[type] && !isValid[type] ? 'error' : null;

  return (
    <div className="sms-setup">
      <h2 className="auth-header">{t('authentication.header')}</h2>
      <h4>{t('authentication.smsSetup.header')}</h4>
      <section>
        <p>{t('authentication.smsSetup.infoText')}</p>
        <form
          onSubmit={event => {
            event.preventDefault();
            getAuthCodeData();
          }}
        >
          <Field validationState={getValidationState('phoneNumber')}>
            <FieldLabel htmlFor="phone">
              {t('authentication.smsSetup.phoneNumber.label')}
            </FieldLabel>
            <PhoneNumber
              id="phone"
              defaultOption="US"
              name="phone"
              value={phoneNumber}
              validationState={getValidationState('phoneNumber')}
              onBlur={() => setIsTouched({ ...isTouched, phoneNumber: true })}
              onChange={value => setPhoneNumber(value)}
              onFocus={() => setIsDirty({ ...isDirty, phoneNumber: true })}
            />
            <FieldErrorMap code={!isValid.phoneNumber ? 'invalid' : null}>
              {t('authentication.smsSetup.phoneNumber.invalid')}
            </FieldErrorMap>
          </Field>
          <Button
            type="submit"
            disabled={!isValid.phoneNumber || isSendSMSDisabled}
            theme="primary"
            id="send-auth-code-button"
          >
            {t('authentication.smsSetup.sendAuthCode')}
          </Button>
        </form>
      </section>
      <section>
        <p>{t('authentication.smsSetup.authCodeInfoText')}</p>
        <form
          onSubmit={event => {
            event.preventDefault();
            getConfirmData();
          }}
        >
          <Field validationState={getValidationState('authCode')}>
            <FieldLabel htmlFor="auth-code">
              {t('authentication.authCode')}
            </FieldLabel>
            <Text
              id="auth-code"
              value={authCode}
              disabled={!codeSent}
              inputMode="numeric"
              pattern="[0-9]*"
              validationState={getValidationState('authCode')}
              onBlur={() => setIsTouched({ ...isTouched, authCode: true })}
              onChange={e => setAuthCode(e.target.value)}
              onFocus={() => setIsDirty({ ...isDirty, authCode: true })}
            />
            <FieldErrorMap code={!isValid.authCode ? 'invalid' : null}>
              {t('authentication.invalidAuthCode')}
            </FieldErrorMap>
          </Field>
          <div className="button-navigation-container">
            <Button id="back-button" theme="text" onClick={() => navigate(-1)}>
              {t('standard.back')}
            </Button>
            <Button
              id="enable-twofa"
              disabled={!isValid.authCode || !codeSent}
              theme="primary"
              type="submit"
              spinning={
                confirmLoading || !!(confirmData && confirmData.redirectUrl)
              }
            >
              {t('authentication.enable')}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default SMSSetup;
