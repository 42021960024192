import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Field,
  FieldErrorMap,
  FieldLabel,
  PasswordValidator,
  Text,
  toast,
  UserSettingsContext,
  useSendContext,
} from 'taulia-ui';
import { useNavigate } from 'react-router-dom';
import { every } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '../../hooks';
import CONSTANTS from '../../globals';

function NewPassword() {
  const { t } = useTranslation();
  const query = useQuery();
  const [resetComplete, setResetComplete] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState({
    value: '',
    error: null,
    validations: {
      length: false,
      upper: false,
      lower: false,
      number: false,
      match: false,
    },
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    error: null,
  });
  const navigate = useNavigate();

  const userId = query.get('userId');
  const token = query.get('token');

  const { rsaEncrypt } = useContext(UserSettingsContext);

  const {
    data: resetPasswordResponse,
    loading: initLoading,
    error: resetError,
    doSend: doSendReset,
  } = useSendContext('POST', '/api/reset-password/reset');

  const sendReset = () => {
    doSendReset({
      userId,
      token,
      newPasswordEncrypted: rsaEncrypt.encrypt(password.value),
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (password.value !== confirmPassword.value) {
      setConfirmPassword({ ...confirmPassword, error: 'mismatch' });
    } else {
      sendReset();
    }
  };

  useEffect(() => {
    if (resetPasswordResponse && resetPasswordResponse.success) {
      setEmail(resetPasswordResponse.emailAddress);
      setResetComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordResponse]);

  useEffect(() => {
    if (resetError) {
      if (
        resetError.response.body.failureReason ===
        CONSTANTS.ERROR_CODES.BLOCK_LIST_PASSWORD
      ) {
        toast.error(t('resetPassword.error.blockListPassword'));

        return;
      }

      toast.error(t('standard.error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetError]);

  const submitDisabled =
    confirmPassword.error ||
    !confirmPassword.value.length ||
    !every(password.validations);

  const completedState = resetComplete ? 'complete' : 'incomplete';

  return (
    <div className="login-container">
      <h2 className="auth-header">
        {t(`resetPassword.${completedState}Header`)}
      </h2>
      {resetComplete ? (
        <>
          <p>
            <Trans
              i18nKey="resetPassword.passwordChangedInfoText"
              components={[<strong />]}
              values={{ email }}
            />
          </p>
          <Button block onClick={() => navigate('/login')} theme="primary">
            {t('resetPassword.returnLogin')}
          </Button>
        </>
      ) : (
        <form onSubmit={onSubmit}>
          <p>{t('resetPassword.newPasswordInfoText')}</p>
          <PasswordValidator
            changePassword
            email={email}
            password={password}
            handleInputChange={setPassword}
          />
          <Field validationState={confirmPassword.error ? 'error' : null}>
            <FieldLabel htmlFor="confirm-password">
              {t('resetPassword.confirmPassword')}
            </FieldLabel>
            <Text
              autoCapitalize="off"
              id="confirm-password"
              onChange={e =>
                setConfirmPassword({ value: e.target.value, error: null })
              }
              type="password"
              validationState={confirmPassword.error ? 'error' : null}
              value={confirmPassword.value}
            />
            <FieldErrorMap>
              {t('resetPassword.confirmPasswordError')}
            </FieldErrorMap>
          </Field>
          <Button
            type="submit"
            theme="primary"
            spinning={initLoading}
            disabled={submitDisabled}
            block
          >
            {t('resetPassword.resetPassword')}
          </Button>
        </form>
      )}
    </div>
  );
}

export default NewPassword;
