function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { parseISO } from 'date-fns';
import CONSTANTS from '../globals';
export function convertToCurrencyString(value, options) {
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'en';
  var formattedValue = CONSTANTS.NO_DATA_DOUBLE_DASH;
  var isNegative = false;

  try {
    var numericValue = Number(value);
    isNegative = numericValue < 0;
    formattedValue = Math.abs(numericValue).toLocaleString(locale, _objectSpread(_objectSpread({}, options), {}, {
      style: 'currency'
    }));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }

  return isNegative ? "(".concat(formattedValue, ")") : formattedValue;
}
export function convertToDateString(dateStr, options) {
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'en';
  return parseISO(dateStr).toLocaleDateString(locale, options);
}
export function convertToDecimalCompactString(val) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'en';
  return val.toLocaleString(locale, {
    style: 'decimal',
    notation: 'compact'
  });
}