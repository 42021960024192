export var currencyIsoData = {
  AED: {
    currency: 'AED',
    name: 'UAE Dirham',
    number: 784,
    minorUnits: 2
  },
  AFN: {
    currency: 'AFN',
    name: 'Afghani',
    number: 971,
    minorUnits: 2
  },
  ALL: {
    currency: 'ALL',
    name: 'Lek',
    number: 8,
    minorUnits: 2
  },
  AMD: {
    currency: 'AMD',
    name: 'Armenian Dram',
    number: 51,
    minorUnits: 2
  },
  ANG: {
    currency: 'ANG',
    name: 'Netherlands Antillean Guilder',
    number: 532,
    minorUnits: 2
  },
  AOA: {
    currency: 'AOA',
    name: 'Kwanza',
    number: 973,
    minorUnits: 2
  },
  AOK: {
    currency: 'AOK',
    name: 'Kwanza'
  },
  AON: {
    currency: 'AON',
    name: 'New Kwanza',
    number: 24,
    minorUnits: 0
  },
  ARS: {
    currency: 'ARS',
    name: 'Argentine Peso',
    number: 32,
    minorUnits: 2
  },
  AUD: {
    currency: 'AUD',
    name: 'Australian Dollar',
    number: 36,
    minorUnits: 2
  },
  AWG: {
    currency: 'AWG',
    name: 'Aruban Florin',
    number: 533,
    minorUnits: 2
  },
  AZN: {
    currency: 'AZN',
    name: 'Azerbaijan Manat',
    number: 944,
    minorUnits: 2
  },
  BAM: {
    currency: 'BAM',
    name: 'Convertible Mark',
    number: 977,
    minorUnits: 2
  },
  BBD: {
    currency: 'BBD',
    name: 'Barbados Dollar',
    number: 52,
    minorUnits: 2
  },
  BDT: {
    currency: 'BDT',
    name: 'Taka',
    number: 50,
    minorUnits: 2
  },
  BEF: {
    currency: 'BEF',
    name: 'Belgian Franc',
    number: 56,
    minorUnits: 1
  },
  BGN: {
    currency: 'BGN',
    name: 'Bulgarian Lev',
    number: 975,
    minorUnits: 2
  },
  BHD: {
    currency: 'BHD',
    name: 'Bahraini Dinar',
    number: 48,
    minorUnits: 3
  },
  BIF: {
    currency: 'BIF',
    name: 'Burundi Franc',
    number: 108,
    minorUnits: 0
  },
  BMD: {
    currency: 'BMD',
    name: 'Bermudian Dollar',
    number: 60,
    minorUnits: 2
  },
  BND: {
    currency: 'BND',
    name: 'Brunei Dollar',
    number: 96,
    minorUnits: 2
  },
  BOB: {
    currency: 'BOB',
    name: 'Boliviano',
    number: 68,
    minorUnits: 2
  },
  BOV: {
    currency: 'BOV',
    name: 'Mvdol',
    number: 984,
    minorUnits: 2
  },
  BRL: {
    currency: 'BRL',
    name: 'Brazilian Real',
    number: 986,
    minorUnits: 2
  },
  BSD: {
    currency: 'BSD',
    name: 'Bahamian Dollar',
    number: 44,
    minorUnits: 2
  },
  BTN: {
    currency: 'BTN',
    name: 'Ngultrum',
    number: 64,
    minorUnits: 2
  },
  BWP: {
    currency: 'BWP',
    name: 'Pula',
    number: 72,
    minorUnits: 2
  },
  BYN: {
    currency: 'BYN',
    name: 'Belarusian Ruble',
    number: 933,
    minorUnits: 2
  },
  BZD: {
    currency: 'BZD',
    name: 'Belize Dollar',
    number: 84,
    minorUnits: 2
  },
  CAD: {
    currency: 'CAD',
    name: 'Canadian Dollar',
    number: 124,
    minorUnits: 2
  },
  CDF: {
    currency: 'CDF',
    name: 'Congolese Franc',
    number: 976,
    minorUnits: 2
  },
  CHE: {
    currency: 'CHE',
    name: 'WIR Euro',
    number: 947,
    minorUnits: 2
  },
  CHF: {
    currency: 'CHF',
    name: 'Swiss Franc',
    number: 756,
    minorUnits: 2
  },
  CHW: {
    currency: 'CHW',
    name: 'WIR Franc',
    number: 948,
    minorUnits: 2
  },
  CLF: {
    currency: 'CLF',
    name: 'Unidad de Fomento',
    number: 990,
    minorUnits: 4
  },
  CLP: {
    currency: 'CLP',
    name: 'Chilean Peso',
    number: 152,
    minorUnits: 0
  },
  CNY: {
    currency: 'CNY',
    name: 'Yuan Renminbi',
    number: 156,
    minorUnits: 2
  },
  COP: {
    currency: 'COP',
    name: 'Colombian Peso',
    number: 170,
    minorUnits: 2
  },
  COU: {
    currency: 'COU',
    name: 'Unidad de Valor Real',
    number: 970,
    minorUnits: 2
  },
  CRC: {
    currency: 'CRC',
    name: 'Costa Rican Colon',
    number: 188,
    minorUnits: 2
  },
  CSD: {
    currency: 'CSD',
    name: 'Serbian Dinar',
    number: 891,
    minorUnits: 2
  },
  CUC: {
    currency: 'CUC',
    name: 'Peso Convertible',
    number: 931,
    minorUnits: 2
  },
  CUP: {
    currency: 'CUP',
    name: 'Cuban Peso',
    number: 192,
    minorUnits: 2
  },
  CVE: {
    currency: 'CVE',
    name: 'Cabo Verde Escudo',
    number: 132,
    minorUnits: 2
  },
  CYP: {
    currency: 'CYP',
    name: 'Cyprus Pound',
    number: 196,
    minorUnits: 2
  },
  CZK: {
    currency: 'CZK',
    name: 'Czech Koruna',
    number: 203,
    minorUnits: 2
  },
  DEM: {
    currency: 'DEM',
    name: 'Deutsche Mark',
    number: 276,
    minorUnits: 2
  },
  DJF: {
    currency: 'DJF',
    name: 'Djibouti Franc',
    number: 262,
    minorUnits: 0
  },
  DKK: {
    currency: 'DKK',
    name: 'Danish Krone',
    number: 208,
    minorUnits: 2
  },
  DOP: {
    currency: 'DOP',
    name: 'Dominican Peso',
    number: 214,
    minorUnits: 2
  },
  DZD: {
    currency: 'DZD',
    name: 'Algerian Dinar',
    number: 12,
    minorUnits: 2
  },
  ECS: {
    currency: 'ECS',
    name: 'Sucre',
    number: 218,
    minorUnits: 0
  },
  EEK: {
    currency: 'EEK',
    name: 'Kroon',
    minorUnits: 2
  },
  EGP: {
    currency: 'EGP',
    name: 'Egyptian Pound',
    number: 818,
    minorUnits: 2
  },
  ERN: {
    currency: 'ERN',
    name: 'Nakfa',
    number: 232,
    minorUnits: 2
  },
  ETB: {
    currency: 'ETB',
    name: 'Ethiopian Birr',
    number: 230,
    minorUnits: 2
  },
  EUR: {
    currency: 'EUR',
    name: 'Euro',
    number: 978,
    minorUnits: 2
  },
  FJD: {
    currency: 'FJD',
    name: 'Fiji Dollar',
    number: 242,
    minorUnits: 2
  },
  FKP: {
    currency: 'FKP',
    name: 'Falkland Islands Pound',
    number: 238,
    minorUnits: 2
  },
  GBP: {
    currency: 'GBP',
    name: 'Pound Sterling',
    number: 826,
    minorUnits: 2
  },
  GEL: {
    currency: 'GEL',
    name: 'Lari',
    number: 981,
    minorUnits: 2
  },
  GHC: {
    currency: 'GHC',
    name: 'Cedi',
    number: 288,
    minorUnits: 0
  },
  GHS: {
    currency: 'GHS',
    name: 'Ghana Cedi',
    number: 936,
    minorUnits: 2
  },
  GIP: {
    currency: 'GIP',
    name: 'Gibraltar Pound',
    number: 292,
    minorUnits: 2
  },
  GMD: {
    currency: 'GMD',
    name: 'Dalasi',
    number: 270,
    minorUnits: 2
  },
  GNF: {
    currency: 'GNF',
    name: 'Guinean Franc',
    number: 324,
    minorUnits: 0
  },
  GTQ: {
    currency: 'GTQ',
    name: 'Quetzal',
    number: 320,
    minorUnits: 2
  },
  GYD: {
    currency: 'GYD',
    name: 'Guyana Dollar',
    number: 328,
    minorUnits: 2
  },
  HKD: {
    currency: 'HKD',
    name: 'Hong Kong Dollar',
    number: 344,
    minorUnits: 2
  },
  HNL: {
    currency: 'HNL',
    name: 'Lempira',
    number: 340,
    minorUnits: 2
  },
  HTG: {
    currency: 'HTG',
    name: 'Gourde',
    number: 332,
    minorUnits: 2
  },
  HUF: {
    currency: 'HUF',
    name: 'Forint',
    number: 348,
    minorUnits: 2
  },
  IDR: {
    currency: 'IDR',
    name: 'Rupiah',
    number: 360,
    minorUnits: 2
  },
  ILS: {
    currency: 'ILS',
    name: 'New Israeli Sheqel',
    number: 376,
    minorUnits: 2
  },
  INR: {
    currency: 'INR',
    name: 'Indian Rupee',
    number: 356,
    minorUnits: 2
  },
  IQD: {
    currency: 'IQD',
    name: 'Iraqi Dinar',
    number: 368,
    minorUnits: 3
  },
  IRR: {
    currency: 'IRR',
    name: 'Iranian Rial',
    number: 364,
    minorUnits: 2
  },
  ISK: {
    currency: 'ISK',
    name: 'Iceland Krona',
    number: 352,
    minorUnits: 0
  },
  JMD: {
    currency: 'JMD',
    name: 'Jamaican Dollar',
    number: 388,
    minorUnits: 2
  },
  JOD: {
    currency: 'JOD',
    name: 'Jordanian Dinar',
    number: 400,
    minorUnits: 3
  },
  JPY: {
    currency: 'JPY',
    name: 'Yen',
    number: 392,
    minorUnits: 0
  },
  KES: {
    currency: 'KES',
    name: 'Kenyan Shilling',
    number: 404,
    minorUnits: 2
  },
  KGS: {
    currency: 'KGS',
    name: 'Som',
    number: 417,
    minorUnits: 2
  },
  KHR: {
    currency: 'KHR',
    name: 'Riel',
    number: 116,
    minorUnits: 2
  },
  KMF: {
    currency: 'KMF',
    name: 'Comorian Franc',
    number: 174,
    minorUnits: 0
  },
  KPW: {
    currency: 'KPW',
    name: 'North Korean Won',
    number: 408,
    minorUnits: 2
  },
  KRW: {
    currency: 'KRW',
    name: 'Won',
    number: 410,
    minorUnits: 0
  },
  KWD: {
    currency: 'KWD',
    name: 'Kuwaiti Dinar',
    number: 414,
    minorUnits: 3
  },
  KYD: {
    currency: 'KYD',
    name: 'Cayman Islands Dollar',
    number: 136,
    minorUnits: 2
  },
  KZT: {
    currency: 'KZT',
    name: 'Tenge',
    number: 398,
    minorUnits: 2
  },
  LAK: {
    currency: 'LAK',
    name: 'Lao Kip',
    number: 418,
    minorUnits: 2
  },
  LBP: {
    currency: 'LBP',
    name: 'Lebanese Pound',
    number: 422,
    minorUnits: 2
  },
  LKR: {
    currency: 'LKR',
    name: 'Sri Lanka Rupee',
    number: 144,
    minorUnits: 2
  },
  LRD: {
    currency: 'LRD',
    name: 'Liberian Dollar',
    number: 430,
    minorUnits: 2
  },
  LSL: {
    currency: 'LSL',
    name: 'Loti',
    number: 426,
    minorUnits: 2
  },
  LYD: {
    currency: 'LYD',
    name: 'Libyan Dinar',
    number: 434,
    minorUnits: 3
  },
  MAD: {
    currency: 'MAD',
    name: 'Moroccan Dirham',
    number: 504,
    minorUnits: 2
  },
  MDL: {
    currency: 'MDL',
    name: 'Moldovan Leu',
    number: 498,
    minorUnits: 2
  },
  MGA: {
    currency: 'MGA',
    name: 'Malagasy Ariary',
    number: 969,
    minorUnits: 2
  },
  MKD: {
    currency: 'MKD',
    name: 'Denar',
    number: 807,
    minorUnits: 2
  },
  MMK: {
    currency: 'MMK',
    name: 'Kyat',
    number: 104,
    minorUnits: 2
  },
  MNT: {
    currency: 'MNT',
    name: 'Tugrik',
    number: 496,
    minorUnits: 2
  },
  MOP: {
    currency: 'MOP',
    name: 'Pataca',
    number: 446,
    minorUnits: 2
  },
  MRU: {
    currency: 'MRU',
    name: 'Ouguiya',
    number: 929,
    minorUnits: 2
  },
  MTL: {
    currency: 'MTL',
    name: 'Maltese Lira',
    number: 470,
    minorUnits: 2
  },
  MUR: {
    currency: 'MUR',
    name: 'Mauritius Rupee',
    number: 480,
    minorUnits: 2
  },
  MVR: {
    currency: 'MVR',
    name: 'Rufiyaa',
    number: 462,
    minorUnits: 2
  },
  MWK: {
    currency: 'MWK',
    name: 'Malawi Kwacha',
    number: 454,
    minorUnits: 2
  },
  MXN: {
    currency: 'MXN',
    name: 'Mexican Peso',
    number: 484,
    minorUnits: 2
  },
  MXP: {
    currency: 'MXP',
    name: 'Mexican Peso'
  },
  MXV: {
    currency: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
    number: 979,
    minorUnits: 2
  },
  MYR: {
    currency: 'MYR',
    name: 'Malaysian Ringgit',
    number: 458,
    minorUnits: 2
  },
  MZM: {
    currency: 'MZM',
    name: 'Mozambique Metical',
    number: 508,
    minorUnits: 0
  },
  MZN: {
    currency: 'MZN',
    name: 'Mozambique Metical',
    number: 943,
    minorUnits: 2
  },
  NAD: {
    currency: 'NAD',
    name: 'Namibia Dollar',
    number: 516,
    minorUnits: 2
  },
  NGN: {
    currency: 'NGN',
    name: 'Naira',
    number: 566,
    minorUnits: 2
  },
  NIO: {
    currency: 'NIO',
    name: 'Cordoba Oro',
    number: 558,
    minorUnits: 2
  },
  NOK: {
    currency: 'NOK',
    name: 'Norwegian Krone',
    number: 578,
    minorUnits: 2
  },
  NPR: {
    currency: 'NPR',
    name: 'Nepalese Rupee',
    number: 524,
    minorUnits: 2
  },
  NZD: {
    currency: 'NZD',
    name: 'New Zealand Dollar',
    number: 554,
    minorUnits: 2
  },
  OMR: {
    currency: 'OMR',
    name: 'Rial Omani',
    number: 512,
    minorUnits: 3
  },
  PAB: {
    currency: 'PAB',
    name: 'Balboa',
    number: 590,
    minorUnits: 2
  },
  PEN: {
    currency: 'PEN',
    name: 'Sol',
    number: 604,
    minorUnits: 2
  },
  PGK: {
    currency: 'PGK',
    name: 'Kina',
    number: 598,
    minorUnits: 2
  },
  PHP: {
    currency: 'PHP',
    name: 'Philippine Peso',
    number: 608,
    minorUnits: 2
  },
  PKR: {
    currency: 'PKR',
    name: 'Pakistan Rupee',
    number: 586,
    minorUnits: 2
  },
  PLN: {
    currency: 'PLN',
    name: 'Zloty',
    number: 985,
    minorUnits: 2
  },
  PYG: {
    currency: 'PYG',
    name: 'Guarani',
    number: 600,
    minorUnits: 0
  },
  QAR: {
    currency: 'QAR',
    name: 'Qatari Rial',
    number: 634,
    minorUnits: 2
  },
  RON: {
    currency: 'RON',
    name: 'Romanian Leu',
    number: 946,
    minorUnits: 2
  },
  RSD: {
    currency: 'RSD',
    name: 'Serbian Dinar',
    number: 941,
    minorUnits: 2
  },
  RUB: {
    currency: 'RUB',
    name: 'Russian Ruble',
    number: 643,
    minorUnits: 2
  },
  RWF: {
    currency: 'RWF',
    name: 'Rwanda Franc',
    number: 646,
    minorUnits: 0
  },
  SAR: {
    currency: 'SAR',
    name: 'Saudi Riyal',
    number: 682,
    minorUnits: 2
  },
  SBD: {
    currency: 'SBD',
    name: 'Solomon Islands Dollar',
    number: 90,
    minorUnits: 2
  },
  SCR: {
    currency: 'SCR',
    name: 'Seychelles Rupee',
    number: 690,
    minorUnits: 2
  },
  SDG: {
    currency: 'SDG',
    name: 'Sudanese Pound',
    number: 938,
    minorUnits: 2
  },
  SEK: {
    currency: 'SEK',
    name: 'Swedish Krona',
    number: 752,
    minorUnits: 2
  },
  SGD: {
    currency: 'SGD',
    name: 'Singapore Dollar',
    number: 702,
    minorUnits: 2
  },
  SHP: {
    currency: 'SHP',
    name: 'Saint Helena Pound',
    number: 654,
    minorUnits: 2
  },
  SKK: {
    currency: 'SKK',
    name: 'Slovak Koruna',
    number: 703,
    minorUnits: 1
  },
  SLE: {
    currency: 'SLE',
    name: 'Leone',
    number: 925,
    minorUnits: 2
  },
  SLL: {
    currency: 'SLL',
    name: 'Leone',
    number: 694,
    minorUnits: 2
  },
  SOS: {
    currency: 'SOS',
    name: 'Somali Shilling',
    number: 706,
    minorUnits: 2
  },
  SRD: {
    currency: 'SRD',
    name: 'Surinam Dollar',
    number: 968,
    minorUnits: 2
  },
  SSP: {
    currency: 'SSP',
    name: 'South Sudanese Pound',
    number: 728,
    minorUnits: 2
  },
  STN: {
    currency: 'STN',
    name: 'Dobra',
    number: 930,
    minorUnits: 2
  },
  SVC: {
    currency: 'SVC',
    name: 'El Salvador Colon',
    number: 222,
    minorUnits: 2
  },
  SYP: {
    currency: 'SYP',
    name: 'Syrian Pound',
    number: 760,
    minorUnits: 2
  },
  SZL: {
    currency: 'SZL',
    name: 'Lilangeni',
    number: 748,
    minorUnits: 2
  },
  THB: {
    currency: 'THB',
    name: 'Baht',
    number: 764,
    minorUnits: 2
  },
  TJS: {
    currency: 'TJS',
    name: 'Somoni',
    number: 972,
    minorUnits: 2
  },
  TMT: {
    currency: 'TMT',
    name: 'Turkmenistan New Manat',
    number: 934,
    minorUnits: 2
  },
  TND: {
    currency: 'TND',
    name: 'Tunisian Dinar',
    number: 788,
    minorUnits: 3
  },
  TOP: {
    currency: 'TOP',
    name: 'Pa’anga',
    number: 776,
    minorUnits: 2
  },
  TRL: {
    currency: 'TRL',
    name: 'Old Turkish Lira',
    number: 792,
    minorUnits: 0
  },
  TRY: {
    currency: 'TRY',
    name: 'Turkish Lira',
    number: 949,
    minorUnits: 2
  },
  TTD: {
    currency: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    number: 780,
    minorUnits: 2
  },
  TWD: {
    currency: 'TWD',
    name: 'New Taiwan Dollar',
    number: 901,
    minorUnits: 2
  },
  TZS: {
    currency: 'TZS',
    name: 'Tanzanian Shilling',
    number: 834,
    minorUnits: 2
  },
  UAH: {
    currency: 'UAH',
    name: 'Hryvnia',
    number: 980,
    minorUnits: 2
  },
  UGX: {
    currency: 'UGX',
    name: 'Uganda Shilling',
    number: 800,
    minorUnits: 0
  },
  USD: {
    currency: 'USD',
    name: 'US Dollar',
    number: 840,
    minorUnits: 2
  },
  USN: {
    currency: 'USN',
    name: 'US Dollar (Next day)',
    number: 997,
    minorUnits: 2
  },
  USS: {
    currency: 'USS',
    name: 'US Dollar (Same day)',
    number: 998,
    minorUnits: 2
  },
  UYI: {
    currency: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (UI)',
    number: 940,
    minorUnits: 0
  },
  UYP: {
    currency: 'UYP',
    name: 'Uruguayan Peso'
  },
  UYU: {
    currency: 'UYU',
    name: 'Peso Uruguayo',
    number: 858,
    minorUnits: 2
  },
  UYW: {
    currency: 'UYW',
    name: 'Unidad Previsional',
    number: 927,
    minorUnits: 4
  },
  UZS: {
    currency: 'UZS',
    name: 'Uzbekistan Sum',
    number: 860,
    minorUnits: 2
  },
  VED: {
    currency: 'VED',
    name: 'Bolívar Soberano',
    number: 926,
    minorUnits: 2
  },
  VEF: {
    currency: 'VEF',
    name: 'Bolivar Fuerte',
    number: 937,
    minorUnits: 2
  },
  VES: {
    currency: 'VES',
    name: 'Bolívar Soberano',
    number: 928,
    minorUnits: 2
  },
  VND: {
    currency: 'VND',
    name: 'Dong',
    number: 704,
    minorUnits: 0
  },
  VUV: {
    currency: 'VUV',
    name: 'Vatu',
    number: 548,
    minorUnits: 0
  },
  WST: {
    currency: 'WST',
    name: 'Tala',
    number: 882,
    minorUnits: 2
  },
  XAF: {
    currency: 'XAF',
    name: 'CFA Franc BEAC',
    number: 950,
    minorUnits: 0
  },
  XAG: {
    currency: 'XAG',
    name: 'Silver',
    number: 961,
    minorUnits: 'N.A.'
  },
  XAU: {
    currency: 'XAU',
    name: 'Gold',
    number: 959,
    minorUnits: 'N.A.'
  },
  XBA: {
    currency: 'XBA',
    name: 'Bond Markets Unit European Composite Unit (EURCO)',
    number: 955,
    minorUnits: 'N.A.'
  },
  XBB: {
    currency: 'XBB',
    name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    number: 956,
    minorUnits: 'N.A.'
  },
  XBC: {
    currency: 'XBC',
    name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    number: 957,
    minorUnits: 'N.A.'
  },
  XBD: {
    currency: 'XBD',
    name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    number: 958,
    minorUnits: 'N.A.'
  },
  XCD: {
    currency: 'XCD',
    name: 'East Caribbean Dollar',
    number: 951,
    minorUnits: 2
  },
  XDR: {
    currency: 'XDR',
    name: 'SDR (Special Drawing Right)',
    number: 960,
    minorUnits: 'N.A.'
  },
  XOF: {
    currency: 'XOF',
    name: 'CFA Franc BCEAO',
    number: 952,
    minorUnits: 0
  },
  XPD: {
    currency: 'XPD',
    name: 'Palladium',
    number: 964,
    minorUnits: 'N.A.'
  },
  XPF: {
    currency: 'XPF',
    name: 'CFP Franc',
    number: 953,
    minorUnits: 0
  },
  XPT: {
    currency: 'XPT',
    name: 'Platinum',
    number: 962,
    minorUnits: 'N.A.'
  },
  XSU: {
    currency: 'XSU',
    name: 'Sucre',
    number: 994,
    minorUnits: 'N.A.'
  },
  XTS: {
    currency: 'XTS',
    name: 'Codes specifically reserved for testing purposes',
    number: 963,
    minorUnits: 'N.A.'
  },
  XUA: {
    currency: 'XUA',
    name: 'ADB Unit of Account',
    number: 965,
    minorUnits: 'N.A.'
  },
  XXX: {
    currency: 'XXX',
    name: 'The codes assigned for transactions where no currency is involved',
    number: 999,
    minorUnits: 'N.A.'
  },
  YER: {
    currency: 'YER',
    name: 'Yemeni Rial',
    number: 886,
    minorUnits: 2
  },
  ZAR: {
    currency: 'ZAR',
    name: 'Rand',
    number: 710,
    minorUnits: 2
  },
  ZMK: {
    currency: 'ZMK',
    name: 'Zambian',
    number: 894,
    minorUnits: 0
  },
  ZMW: {
    currency: 'ZMW',
    name: 'Zambian Kwacha',
    number: 967,
    minorUnits: 2
  },
  ZWL: {
    currency: 'ZWL',
    name: 'Zimbabwe Dollar',
    number: 932,
    minorUnits: 2
  }
};
export var oldCurrencyIsoData = {
  BYR: {
    currency: 'BYR',
    name: 'Belarusian Ruble',
    number: 974,
    minorUnits: 0
  },
  HRK: {
    currency: 'HRK',
    name: 'Croatian kuna',
    number: 191,
    minorUnits: 2
  },
  LTL: {
    currency: 'LTL',
    name: 'Lithuanian litas',
    number: 440,
    minorUnits: 2
  },
  LVL: {
    currency: 'LVL',
    name: 'Latvian lats',
    number: 428,
    minorUnits: 2
  },
  MRO: {
    currency: 'MRO',
    name: 'Mauritanian ouguiya',
    number: 478,
    minorUnits: 2
  },
  STD: {
    currency: 'STD',
    name: 'São Tomé and Príncipe dobra',
    number: 678,
    minorUnits: 2
  }
};