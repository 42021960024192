import React, { forwardRef } from 'react';
import { EnhancedSearchProps } from '../types';
import { ESContextProvider } from './context/EnhancedSearchContext';
import { EnhancedSearchComponent } from './EnhancedSearch';

const EnhancedSearchV2 = forwardRef((props: EnhancedSearchProps, ref) => (
  <ESContextProvider>
    <EnhancedSearchComponent {...props} ref={ref} />
  </ESContextProvider>
));

export default EnhancedSearchV2;
