import React, { useState, useRef, useEffect } from 'react';
import { colors } from '../../theme';
import { Clickable } from '../../Button';
import { InfoTooltip } from '../../Tooltip';
import { cx } from '../../utils';
import { Caret } from '../../Icons';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { getInputStyles } from '../../Text';
import translationData from '../../translations/en-US.json';
import { SelectInputProps } from '../../types/EnhancedSearch';

export default function SelectInput({
  arrowStyle = '',
  className = '',
  content,
  contentClassName = '',
  fieldName = '',
  label = '',
  onClose = () => {},
  onShow = () => {},
  selectedContent = [],
  target = null,
  tooltipText = '',
  tooltipWidth = '',
  width = null,
}: SelectInputProps) {
  const { t } = useLibNSTranslation();

  const [hover, setHover] = useState(false);
  const [show, setShow] = useState(false);
  const [closeTrigger, setCloseTrigger] = useState<boolean | number>(false);

  const globalHideRef = useRef({ show: false });
  const contentRef = useRef<HTMLDivElement>(null);
  const clickableRef = useRef<HTMLDivElement>(null);

  /* -----> Handlers <----- */
  const onCloseHandler = () => {
    setShow(false);
    globalHideRef.current = { show: false };
    onClose();
  };

  const onShowHandler = () => {
    setShow(true);
    globalHideRef.current = { show: true };
    onShow();
  };

  const onToggleHandler = () => {
    show ? onCloseHandler() : onShowHandler();
  };

  /* Called when clicking outside of the component. */
  const onMouseDownHandler = event => {
    if (
      !contentRef.current?.contains(event.target) &&
      !clickableRef.current?.contains(event.target) &&
      globalHideRef.current.show
    ) {
      setCloseTrigger(Math.random());
    }
  };

  /* -----> Effects <----- */
  useEffect(() => {
    document.body.addEventListener('mousedown', onMouseDownHandler);

    return () => {
      document.body.removeEventListener('mousedown', onMouseDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // An effect to close the content was implemented because attaching global(document) event listeners
  // isn't affected by re-renders, since it follows the vanilla JS scope logic.
  useEffect(() => {
    if (closeTrigger) {
      onCloseHandler();
    }
  }, [closeTrigger]);

  /* -----> View <----- */
  /* Isolates the current operator and highlights it */
  const renderSelectedContent = () => {
    /* Get a list of the current operators from the translations */
    const o = operatorString => t(`enhancedSearch.operators.${operatorString}`);
    const operatorKeys = Object.keys(translationData.enhancedSearch.operators);

    /* Get the current translations based on locale */
    const localizedOperators = operatorKeys?.map(key => o(key));
    const lowerCaseOperators = localizedOperators.map(key =>
      key?.toLowerCase()
    );

    const operatorArray = [...localizedOperators, ...lowerCaseOperators]?.sort(
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      (a, b) => b?.length - a?.length
    );

    if (selectedContent.length === 0) {
      return <div>{t('enhancedSearch.filters.all')}</div>;
    }

    return selectedContent.map((item, idx) => {
      /* Separate the operator in a different element */
      if (idx === 0) {
        let currentOperator;

        for (let i = 0; i < operatorArray.length; i += 1) {
          if (item?.includes(operatorArray[i])) {
            currentOperator = operatorArray[i];
            i = operatorArray.length;
          }
        }

        if (currentOperator) {
          return (
            <span title={item} key={item}>
              <span className="eh-filter-operator">{currentOperator}</span>
              {t(item.replace(currentOperator, ''))}
            </span>
          );
        }
      }

      return (
        <span title={item} key={item}>
          {idx < selectedContent.length && idx !== 0 ? ', ' : ''}
          {t(item)}
        </span>
      );
    });
  };

  return (
    <div
      aria-expanded={show}
      data-testid="select-input-container"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="selectInput" ref={clickableRef}>
        {target ? (
          <Clickable className="selectInput-target" onClick={onToggleHandler}>
            {target}
          </Clickable>
        ) : (
          <div>
            <p title={label}>
              <strong>{label}</strong>
            </p>
            {tooltipText ? (
              <InfoTooltip
                // @ts-ignore Remove after <InfoTooltip /> is transitioned to TS
                align="top left"
                theme="blank"
                width={tooltipWidth || '300px'}
              >
                {tooltipText}
              </InfoTooltip>
            ) : null}
            <Clickable
              data-testid={`select-input-toggle-${fieldName}`}
              className={cx('tau-select', className)}
              onClick={onToggleHandler}
            >
              <span
                style={{
                  ...getInputStyles({ hover, focus: show }),
                  boxShadow: 'none',
                }}
                className="tau-select-trigger selectedContent"
              >
                <div>
                  <strong>{renderSelectedContent()}</strong>
                </div>
              </span>
              <span className="icon">
                <Caret />
              </span>
            </Clickable>
          </div>
        )}
      </div>
      {show ? (
        <div className={cx(`${arrowStyle}`, contentClassName)} ref={contentRef}>
          <div
            className={cx('selectInput-content')}
            style={{
              ...getInputStyles({ hover, focus: show }),
              boxShadow: `2px 2px 5px 1px ${colors['taulia-grey']}`,
              width: `${width}px`,
            }}
          >
            <div className="selectInput-body">{content}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
