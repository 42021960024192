/* Remove any options that don't have a label, and add an `order` field to each option so it maintains enum */
/* order (e.g. 'New', 'In Progress', 'Complete') */
export const createOptions = enumOptions =>
  enumOptions
    .filter(it => it.label)
    .sort((a, b) => a.label?.localeCompare(b.label))
    .map(({ value, label }, order) => ({ value, label, order }));

/* Checks if the options label matches the search */
export const defaultOptionMatcher = (option, search = '') =>
  option.label.toLowerCase().includes(search.trim().toLowerCase());
