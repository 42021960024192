export const DEFAULT_PAGE_SIZE = 10; // Number of records displayed in the LV table
export const DEFAULT_EXPORT_SIZE = 100000; // Max number of records to be exported

export const UNARY_OPERATORS = ['IS_NULL', 'NOT_NULL'];
export const TERNARY_OPERATORS = ['BETWEEN', 'NOT_BETWEEN'];
export const CUSTOM_OPERATORS = {
  COMMA_SEPARATED_LIST: 'COMMA_SEPARATED_LIST',
};
export const MANUAL_SEARCH_ACTION_TYPE = 'MANUAL_SEARCH_ACTION';

export const defaultMainState = {
  error: null,
  nextButtonDisabled: false,
  pageLoading: true,
  searchLoading: false,
};

export const defaultModalState = {
  showModal: false,
  listViewSelection: {
    all: false,
    data: [],
    ids: [],
    selectedCount: NaN,
  },
};

export const defaultQueryState = {
  filters: [],
  pagination: {},
  select: [],
  sorts: [],
};
