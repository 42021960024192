import React from 'react';
import { ListView } from '../../ListView';
import { configureListViewFieldColumns } from '../utils/EnhancedSearchUtils';
import { ESListViewProps, ModalSelection } from '../../types/EnhancedSearch';
import { getDefaultExportToCSVAction } from '../utils/ESListViewUtils';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { useESContext } from '../hooks';
import { useLibNSTranslation } from '../../utils/i18nUtils';

export default function ESListView({
  actions,
  disableDefaultExportAction,
  excludedFieldsColumnsOnly,
  extraColumns,
  isResizable,
  listViewProps,
  noResultsText,
  onActionComplete,
  onQueryChange,
  searchResult,
  showColumnConfig,
}: ESListViewProps) {
  const { t } = useLibNSTranslation();
  const context = useESContext();
  const { modalState, setModalState, configuration, query, setQuery } = context;
  const { nextButtonDisabled, searchLoading } = context.mainState;

  /* -----> Utils <----- */
  /* Default Export to CSV Action */
  const showExportModal = (selection: ModalSelection) => {
    setModalState({
      ...modalState,
      showModal: true,
      listViewSelection: selection,
    });
  };

  /* Fired when changing the column configuration */
  const onColumnConfigChange = columns => {
    const select = columns
      .filter(column => column.visible)
      .map(column => column.key);

    setQuery({ ...query, select }, 'COLUMN_CONFIG_CHANGE');
    onQueryChange({ ...query, select });
  };

  /* Fired when changing the page from the ListView Pagination */
  const onPageChange = (page, pageSize) => {
    const pagination = {
      ...query.pagination,
      first: page * pageSize,
      limit: pageSize,
    };

    setQuery({ ...query, pagination }, 'PAGINATION_CHANGE');
    onQueryChange({ ...query, pagination });
  };

  /* Fired when a sorting is applied */
  const onSortChange = sorts => {
    const pagination = { ...query.pagination, first: 0 };

    setQuery({ ...query, pagination, sorts }, 'SORT_CHANGE');
    onQueryChange({ ...query, pagination, sorts });
  };

  /* -----> ListView Props <----- */
  /* >> Columns << */
  const fieldColumns = configureListViewFieldColumns(
    { query, fields: configuration?.fields },
    excludedFieldsColumnsOnly
  );
  const columns = [...fieldColumns, ...extraColumns];

  /* >> Actions << */
  const ListViewActions = disableDefaultExportAction
    ? actions
    : getDefaultExportToCSVAction(showExportModal).concat(actions);

  /* >> Pagination << */
  const paginationEnabled =
    configuration?.capabilities.pagination.supportsPagination;
  const paginationProps: { [key: string]: any } = {
    paginationEnabled,
    showCount: true,
  };

  if (paginationEnabled) {
    const pagination = searchResult?.pagination;

    paginationProps.pageSize = pagination?.limit || DEFAULT_PAGE_SIZE;
    paginationProps.page = Math.floor(
      (pagination?.first || 0) / paginationProps.pageSize
    );
    paginationProps.totalCount = pagination?.totalCount;
    paginationProps.onPageChange = onPageChange;
    paginationProps.first = pagination?.first;
    paginationProps.nextButtonDisabled = nextButtonDisabled;
  }

  /* >> Sort << */
  const { sorts } = query;
  const sortProps = {
    sortEnabled: true,
    sorts,
    onSortChange,
  };

  return (
    <ListView
      columns={columns}
      data={searchResult?.results || []}
      idField={configuration?.idField}
      isResizable={isResizable}
      loading={searchLoading}
      noResultsText={noResultsText}
      selectionCount={{
        enabled: true,
        singleUnit: t('standard.singleRecord'),
        multipleUnits: t('standard.multipleRecords'),
      }}
      selectionEnabled
      {...paginationProps}
      {...sortProps}
      {...listViewProps}
      actions={ListViewActions}
      actionsEnabled
      onActionComplete={onActionComplete}
      onColumnConfigChange={onColumnConfigChange}
      showColumnConfig={showColumnConfig}
    />
  );
}
