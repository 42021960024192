import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import { EventValidation, InputProps } from '../types/Input';
import { CustomValidation, RequiredValidation } from '../Validation';
import validate from '../Validation/utils/validate';
import { usePredefinedValidations } from '../Hooks';
import { Label } from '../Label';

const Input = React.forwardRef(
  (
    {
      __triggerFormValidation = () => {},
      disabled = false,
      id,
      inputProps = {},
      label = '',
      onBlurValidation = false,
      onInputBlur = () => {},
      onInputChange = () => {},
      required = false,
      type = 'text',
      validation: onChangeValidation = false,
      validationDelay = 0,
      value = '',
    }: InputProps,
    ref
  ) => {
    const { stringValidations } = usePredefinedValidations();
    const [inputValue, setInputValue] = useState(value);
    const [isTouched, setIsTouched] = useState(false);
    const [error, setError] = useState(false);

    /* When accessed from outside, the state would not be updated when an event listener fires. */
    /* This is used to retrieve the current error value between renders. */
    const errorRef = useRef(false);
    const touchedRef = useRef(false);

    /* -----> Utils <----- */
    const updateError = errorValue => {
      setError(errorValue);
      errorRef.current = errorValue;
    };

    const updateTouched = (touched: boolean) => {
      setIsTouched(touched);
      touchedRef.current = touched;
    };

    const componentHasError = (checkRefs = false) =>
      (required &&
        String(inputValue).length === 0 &&
        (checkRefs ? touchedRef.current : isTouched)) ||
      ((checkRefs ? errorRef.current : error) && String(inputValue).length > 0);

    /* Validates the current input value against the provided validations */
    const triggerValidation = (validation: EventValidation) => {
      const validationError = validate(
        inputValue,
        validation,
        stringValidations
      );
      updateError(validationError);
    };

    /* -----> Handlers <----- */
    function onChangeHandler(
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      const currentValue = e.target.value;
      setInputValue(currentValue);
      updateTouched(true);
      onInputChange(e);
      __triggerFormValidation(id);
    }

    function onBlurHandler(
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      updateTouched(true);
      onInputBlur(e);
      __triggerFormValidation(id);

      if (onBlurValidation) {
        triggerValidation(onBlurValidation);
      }
    }

    /* -----> Effects <----- */
    /* Check the input against applied validations */
    /* eslint-disable-next-line consistent-return */
    /* @ts-ignore-line */
    useEffect(() => {
      if (validationDelay) {
        const delayTimer = validationDelay
          ? setTimeout(() => {
              triggerValidation(onChangeValidation);
            }, validationDelay)
          : undefined;

        return () => {
          clearTimeout(delayTimer);
        };
      }

      if (onChangeValidation) {
        triggerValidation(onChangeValidation);
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [inputValue]);

    /* The component can be referenced and access the following values from outside. */
    useImperativeHandle(ref, () => ({
      name: id,
      value: inputValue,
      clearState: () => {
        setInputValue('');
        updateTouched(false);
        updateError(false);
      },
      hasValidationError: () => componentHasError(true),
      onSubmitValidation: () => {
        updateTouched(true);
      },
    }));

    const inputAttributes = {
      className: `tau-input${componentHasError() ? ' has-error' : ''}`,
      'data-testid': `test-${id}`,
      disabled,
      id,
      name: id,
      onBlur: onBlurHandler,
      onChange: onChangeHandler,
      required,
      type,
      value: inputValue,
    };

    /* -----> View <----- */

    return (
      <div className={`tau-input-container ${id}`}>
        <div className="tau-input-wrapper">
          <Label content={label} htmlFor={id} required={required} />
          {type !== 'textarea' ? (
            <input
              {...inputAttributes}
              {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
            />
          ) : (
            <textarea
              {...inputAttributes}
              {...(inputProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            />
          )}
        </div>
        <div className="validation-error-wrapper">
          <CustomValidation
            condition={error && String(inputValue).length > 0}
            errorMessage={error}
          />
          <RequiredValidation
            value={inputValue}
            required={required}
            isTouched={isTouched}
          />
        </div>
      </div>
    );
  }
);

Input.defaultProps = {
  __hasRef: true,
};

export default Input;
