function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* ----> Constants <----- */
var boxShadow = '4px 8px 4px rgba(0, 0, 0, 0.05)';
/* -----> Utils <----- */

var applyDefaultValue = function applyDefaultValue(value, multiple) {
  if (multiple) {
    return value !== null && value !== void 0 && value.length ? value : [];
  }

  return value !== null && value !== void 0 && value.length ? value : '';
};

var getParentMaxHeight = function getParentMaxHeight(num) {
  if (num) {
    return num >= 120 ? num : 120;
  }

  return undefined;
};

var getChildMaxHeight = function getChildMaxHeight(num) {
  if (num) {
    return num - 65 > 0 ? num - 65 : 55;
  }

  return undefined;
};

var getNumberRows = function getNumberRows(rows) {
  return rows * 38;
};

export var isSafari = function isSafari() {
  var chromeInAgent = navigator.userAgent.indexOf('Chrome') > -1;
  var safariInAgent = navigator.userAgent.indexOf('Safari') > -1;
  return safariInAgent && !chromeInAgent;
};

var registerOpenSelectHandlers = function registerOpenSelectHandlers(_ref) {
  var activeIndex = _ref.activeIndex,
      onClose = _ref.onClose,
      onSelect = _ref.onSelect,
      options = _ref.options,
      optionsLength = _ref.optionsLength,
      searchRef = _ref.searchRef,
      setActiveIndex = _ref.setActiveIndex;

  var keyDownCallback = function keyDownCallback(e) {
    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? optionsLength - 1 : activeIndex - 1);
        break;

      case 'Down':
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(activeIndex + 1 === optionsLength ? 0 : activeIndex + 1);
        break;

      case 'Enter':
        e.preventDefault();

        if (!options[activeIndex].disabled) {
          onSelect(options[activeIndex].value);
        }

        break;

      case ' ':
        // Space
        if (searchRef.current) {
          var _searchRef$current;

          (_searchRef$current = searchRef.current) === null || _searchRef$current === void 0 ? void 0 : _searchRef$current.focus();
          break;
        }

        if (!searchRef.current && !options[activeIndex].disabled) {
          e.preventDefault();
          onSelect(options[activeIndex].value);
        }

        break;

      case 'Esc':
      case 'Escape':
        e.preventDefault();
        onClose();
        break;

      case 'PageUp':
      case 'Home':
        e.preventDefault();
        setActiveIndex(0);
        break;

      case 'PageDown':
      case 'End':
        e.preventDefault();
        setActiveIndex(options.length - 1);
        break;

      default:
        break;
    }
  };

  document.body.addEventListener('keydown', keyDownCallback);
  return function () {
    document.body.removeEventListener('keydown', keyDownCallback);
  };
};

var registerClosedSelectHandlers = function registerClosedSelectHandlers(_ref2) {
  var isOpen = _ref2.isOpen,
      listRef = _ref2.listRef,
      searchRef = _ref2.searchRef,
      setIsFocused = _ref2.setIsFocused,
      setState = _ref2.setState;

  var keyDownCallback = function keyDownCallback(e) {
    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
      case 'Down':
      case 'ArrowDown':
      case ' ': // Space

      case 'Enter':
        e.preventDefault();

        if (isOpen) {
          if (listRef.current && isSafari()) {
            requestAnimationFrame(function () {
              listRef.current.focus();
            });
          }
        } else if (listRef.current && isSafari()) {
          requestAnimationFrame(function () {
            listRef.current.previousSibling.focus();
          });
        }

        if (searchRef.current) {
          var _searchRef$current2;

          (_searchRef$current2 = searchRef.current) === null || _searchRef$current2 === void 0 ? void 0 : _searchRef$current2.focus();
        }

        setState(function (prevState) {
          return _objectSpread(_objectSpread({}, prevState), {}, {
            open: true
          });
        });
        break;

      case 'Tab':
        setIsFocused(false);
        break;

      default:
        break;
    }
  };

  document.body.addEventListener('keydown', keyDownCallback);
  return function () {
    document.body.removeEventListener('keydown', keyDownCallback);
  };
};

export var mergeAndDedupeData = function mergeAndDedupeData(existingOptions, optionsSearchData) {
  var mergedAndDedupedArray = [].concat(_toConsumableArray(existingOptions), _toConsumableArray(optionsSearchData)).reduce(function (acc, curr) {
    if (!acc.has(curr.id)) {
      acc.set(curr.id, curr);
    }

    return acc;
  }, new Map()).values();
  return _toConsumableArray(mergedAndDedupedArray);
};
/**
 * *-----> Export Container <-----*
 */

var SelectUtils = {
  boxShadow: boxShadow,
  applyDefaultValue: applyDefaultValue,
  getParentMaxHeight: getParentMaxHeight,
  getChildMaxHeight: getChildMaxHeight,
  getNumberRows: getNumberRows,
  registerClosedSelectHandlers: registerClosedSelectHandlers,
  registerOpenSelectHandlers: registerOpenSelectHandlers
};
export default SelectUtils;